import React, { useContext, useEffect } from "react";
import { IntlProvider, useIntl } from "react-intl";
import { Context } from "../index";
import locales from "src/assets/translation";
import { observer } from "mobx-react";

import { Buffer } from "buffer";
import { AppRoutes } from "src/app/routes/AppRoutes";
import { ModalConnector } from "src/modules/common/general/ui/Modal";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import { toast } from "react-toastify";

import queryString from "query-string";
import { IAuth, objectInLocalStorage } from "src/modules/common/general/types/common";
import { checkPayment } from "src/modules/payment/utils/paymentMarket";
import { handlerAuthNear } from "../modules/payment/utils/auth/handlerAuthNear";
import { removeAllQueryParams } from "../modules/payment/utils/removeAllQueryParams";
import { openPaymentModal } from "../modules/payment/utils/openPaymentModal";

window.Buffer = Buffer;

function ConfirmModal(props: {
	walletId: any,
	walletType: IAuth.AuthTypes.near,
	isOk: () => any,
	text: any,
	title: any
}) {
	return null;
}

function App() {
	const { languages, selector } = useContext(Context);
	const messages = {
		...locales.en,
		...locales[languages.language],
	};
	const { storeUser } = useContext(Context);

	const intl = useIntl();

	useEffect(() => {
		const checkAuth = async () => {
			if (localStorage.getItem(objectInLocalStorage.accessToken)) {
				await storeUser.checkAuth();
			}
		};
		checkAuth();
	}, []);

	// Checking the payment on the backend after the redirect from NEAR
	useEffect(() => {
		const linkParam = queryString.parse(window.location.search);
		const history_id = Number(
			localStorage.getItem(objectInLocalStorage.history_id),
		);
		console.log("history_id", history_id, linkParam.transactionHashes);
		if (linkParam.transactionHashes && history_id && !linkParam.errorCode) {
			checkPayment(history_id, 0, intl);
			localStorage.removeItem(objectInLocalStorage.history_id);
			removeAllQueryParams();
		}
	}, []);


	useEffect(() => {
		const res = queryString.parse(window.location.search);
		if (res.par1) {

			if (!localStorage.getItem(objectInLocalStorage.authSelectorInEndStep)) {
				setTimeout(() => {
					handlerAuthNear(selector, storeUser).then((r) =>
						removeAllQueryParams(),
					);
				}, 100);
			} else {
				setTimeout(() => {
					openPaymentModal(storeUser).then((r) => {
						setTimeout(() => {
							removeAllQueryParams();
						}, 1000);
					});
				}, 100);
			}
		}
	}, []);

	useEffect(() => {
		const linkParam = queryString.parse(window.location.search);
		if (linkParam.errorCode) {
			toast.error("error payment");
			openPaymentModal(storeUser);
			removeAllQueryParams();
		}
	}, []);


	return (
		<IntlProvider locale={languages.language} messages={messages}>
			<LazyLoadComponent>
				<ModalConnector />
			</LazyLoadComponent>
			<AppRoutes />
		</IntlProvider>
	);
}

export default observer(App);
