import iconVelas from "src/modules/common/general/assets/img/crypto/blockchains/png/iconVelas.png";
import iconNEAR from "src/modules/common/general/assets/img/crypto/blockchains/png/iconNear.png";
import iconOasis from "src/modules/common/general/assets/img/crypto/blockchains/svg/iconOasis.svg";
import iconSkale from "src/modules/common/general/assets/img/crypto/blockchains/svg/iconSkale.svg";
import iconNeo from "src/modules/common/general/assets/img/crypto/blockchains/svg/iconNeo.svg";
import iconBNB from "src/modules/common/general/assets/img/crypto/blockchains/png/iconBNB.png";
import iconBUSD from "src/modules/common/general/assets/img/crypto/blockchains/png/iconBUSD.png";
import iconETH from "src/modules/common/general/assets/img/crypto/blockchains/png/iconETH.png";
import iconGAS from "src/modules/common/general/assets/img/crypto/blockchains/png/iconGAS.png";
import iconUSDCTRON from "src/modules/common/general/assets/img/crypto/blockchains/png/iconUSDCTRON.png";
import iconUSDT from "src/modules/common/general/assets/img/crypto/blockchains/png/iconUSDT.png";
import iconUSDTsvg from "src/modules/common/general/assets/img/crypto/blockchains/svg/iconUSDT.svg";
import iconUSDCETH from "src/modules/common/general/assets/img/crypto/blockchains/png/iconUSDCETH.png";
import iconUSDCNEAR from "src/modules/common/general/assets/img/crypto/blockchains/png/iconUSDCNEAR.png";
import iconUSDTNEAR from "src/modules/common/general/assets/img/crypto/blockchains/png/iconUSDTNEAR.png";
import iconUSDTTRON from "src/modules/common/general/assets/img/crypto/blockchains/png/iconUSDTTRON.png";
import iconKeplr from "src/modules/common/general/assets/img/crypto/wallets/png/iconKeplr.png";
import iconRonin from "src/modules/common/general/assets/img/crypto/wallets/png/iconRonin.png";
import iconSolana from "src/modules/common/general/assets/img/crypto/blockchains/png/iconSolana.png";
import iconBSC from "src/modules/common/general/assets/img/crypto/blockchains/png/iconBsc.png";
import iconMoonbeam from "src/modules/common/general/assets/img/crypto/blockchains/png/iconMoonbeam.png";
import iconDoge from "src/modules/common/general/assets/img/crypto/blockchains/svg/iconDoge.svg";
import iconAvax from "src/modules/common/general/assets/img/crypto/blockchains/svg/iconAvax.svg";
import iconTezos from "src/modules/common/general/assets/img/crypto/blockchains/svg/iconTezos.svg";
import iconUSDTETH from "src/modules/common/general/assets/img/crypto/blockchains/png/iconUSDTETH.png";
import Metamask from "src/modules/common/general/assets/img/crypto/wallets/png/iconMetaMask.png";
import iconTronLink from "src/modules/common/general/assets/img/crypto/wallets/png/iconTronLink.png";
import iconWalletConnect from "src/modules/common/general/assets/img/crypto/wallets/png/iconWalletConnect.png";
import iconTempleWallet from "src/modules/common/general/assets/img/crypto/wallets/png/iconTempleWallet.jpg";
import iconNeoLine from "src/modules/common/general/assets/img/crypto/wallets/png/iconNeoLine.png";
import iconMyNearWallet from "src/modules/common/general/assets/img/crypto/wallets/svg/iconMyNearWallet.png";

export const cryptoIcons = {
	blockchains: {
		png: {
			Velas: iconVelas,
			NEAR: iconNEAR,
			BSC: iconBSC,
			ETH: iconETH,
			Solana: iconSolana,
			Moonbeam: iconMoonbeam,
		},
		svg: {
			Oasis: iconOasis,
			SKALE: iconSkale,
			Neo: iconNeo,
			Tezos: iconTezos,
			Doge: iconDoge,
			Avax: iconAvax,
		},
	},
	cryptocurrencies: {
		png: {
			Velas: iconVelas,
			NEAR: iconNEAR,
			BNB: iconBNB,
			ETH: iconETH,
			Solana: iconSolana,
			Moonbeam: iconMoonbeam,
			BUSD: iconBUSD,
			USDT: iconUSDT,
			USDCTRON: iconUSDCTRON,
			USDCETH: iconUSDCETH,
			USDCNEAR: iconUSDCNEAR,
			USDTNEAR: iconUSDTNEAR,
			USDTTRON: iconUSDTTRON,
			GAS: iconGAS,
			USDTETH: iconUSDTETH,
		},
		svg: {
			Oasis: iconOasis,
			SKALE: iconSkale,
			Neo: iconNeo,
			Tezos: iconTezos,
			Doge: iconDoge,
			Avax: iconAvax,
			USDT: iconUSDTsvg
		},
	},
	wallets: {
		png: {
			Keplr: iconKeplr,
			Ronin: iconRonin,
			Metamask: Metamask,
			TronLink: iconTronLink,
			WalletConnect: iconWalletConnect,
			TempleWallet: iconTempleWallet,
			NeoLine: iconNeoLine,
		},
		svg: {
			Neon: iconNeo,
			MyNearWallet:iconMyNearWallet
		},
	},
};
