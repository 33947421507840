import React, { lazy } from "react";
import styles from "src/modules/mainPage/ui/sections/Machine/Machine.module.scss";
import machine from "src/modules/mainPage/assets/img/sections/machine/png/Machine.png";
import spin from "src/modules/mainPage/assets/img/sections/machine/Spin.gif";
import { useIntl } from "react-intl";
import { ButtonJoin } from "src/modules/common/general/ui/buttons/ButtonJoin/ButtonJoin";

const WrapForButtonBuy = lazy(
	() =>
		import(
			/* webpackChunkName: "WrapForButtonBuy" */ "src/modules/payment/ui/WrapForButtonBuy/WrapForButtonBuy"
		)
);

export const Machine: React.FC = () => {
	// const navigate = useNavigate();
	const intl = useIntl();
	return (
		<div className={styles.content}>
			<div className={styles.machine}>
				<div className={styles.spins}>
					<img loading="lazy" src={spin} alt="" className={styles.spin1} />
					<img loading="lazy" src={spin} alt="" className={styles.spin2} />
					<img loading="lazy" src={spin} alt="" className={styles.spin3} />
				</div>
				<img
					className={styles.machineImg}
					src={machine}
					alt=""
					width={1752}
					height={1188}
				/>
			</div>
			<div className={styles.rightBlock}>
				<div className={styles.title}>
					<div className={styles.titleSection}>
						{intl.formatMessage({
							id: "explore the maps and find hidden locations",
						})}
					</div>
				</div>
				<div className={styles.text}>
					{intl.formatMessage({
						id: "Some locations are easy to discover, but some are harder to reach. Maps contain hidden treasure chests, mechanisms with unpredictable effects, mobs who will either help you or throw you back. Just one random encounter can be a game-changer - quite literally.",
					})}
				</div>
				<div className={styles.text}>
					{intl.formatMessage({
						id: "Every season, we release a new collection. It features new boards, items, pets, characters and other surprises.",
					})}
				</div>
				<WrapForButtonBuy product={2}>
					<ButtonJoin>
						{intl.formatMessage({ id: "Buy Packs" })}
					</ButtonJoin>
				</WrapForButtonBuy>
			</div>
		</div>
	);
};
