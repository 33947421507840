import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import styles from "src/modules/payment/ui/modals/ChooseAuthorizationMethod/ChooseAuthorizationMethod.module.scss";
import cross from "src/modules/payment/assets/img/svg/cross.svg";
import backgroundForAuthMethods from "src/modules/payment/assets/img/backgrounds/backgroundForAuthMethods.png";
import { Context } from "src/index";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import cl from "classnames";
import { ConfirmWalletForMint } from "src/modules/payment/ui/modals/ConfirmWalletForMint/ConfirmWalletForMint";
import "./wallet-selector-modal.css";
import {
	AuthMethods,
	blockchains,
	IPackPrices,
	IProduct,
	products,
	TBlockchainMint,
} from "src/modules/payment/types/payment";
import { useNearWallet } from "src/modules/common/general/utils/crypto/near/near.hook";
import { getEthereumAccount } from "src/modules/common/general/utils/crypto/metamask/metamask";
import { getInfoPack } from "src/modules/payment/utils/paymentMarket";
import { roundingUpPrice } from "src/modules/common/general/utils/common";
import { modal } from "src/modules/common/general/ui/Modal";
import lineForPayment from "src/modules/payment/assets/img/png/lineForPayment.png";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useWallet as useWalletNeo } from "@rentfuse-labs/neo-wallet-adapter-react";
import { getNeoLineWallet } from "@rentfuse-labs/neo-wallet-adapter-wallets";
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import {
	IAuth,
	objectInLocalStorage,
} from "src/modules/common/general/types/common";
import { getChainIdFromAuthType } from "src/modules/common/general/utils/getChainIdFromAuthType";
import { CHAIN_NEO } from "../../../config/configPayment";
import { login } from "../../../utils/auth/login";
import { authSelector } from "../../../utils/auth/authSelector";
import AuthTypes = IAuth.AuthTypes;
import { connectWalletInPayment } from "../../../utils/connectWalletInPayment";

const PurchaseMysteryPackModal = lazy(
	() =>
		import(
			/* webpackChunkName: "PurchaseMysteryPackModal" */ "src/modules/payment/ui/modals/PurchaseMysteryPackModal/PurchaseMysteryPackModal"
		)
);

interface IPurchaseStep2 {
	product: IProduct;
	numberOfProducts: number;
	chain: TBlockchainMint;
	convert: IPackPrices[];
}

const ChooseAuthorizationMethod: React.FC<IPurchaseStep2> = ({
	product,
	numberOfProducts,
	chain,
	convert,
}) => {
	const [authMethod, setAuthMethod] = useState(AuthMethods[chain][0]);
	const [isProcessMetamask, setIsProcessMetamask] = useState(false);
	const wallet = useNearWallet();
	const { storeUser, selector } = useContext(Context);
	const intl = useIntl();

	const logoutSelector = async () => {
		try {
			wallet.signOut();
		} catch (er) {}
		const selectorSignOut = async () => {
			if ((await selector).isSignedIn()) {
				const wallet = await (await selector).wallet();
				const subscription = (await selector).on("signedOut", ({}) => {
					subscription.remove();
				});
				await wallet.signOut();
			} else {
			}
		};
		await selectorSignOut();
	};

	useEffect(() => {
		localStorage.removeItem(objectInLocalStorage.addWalletSelector);
	}, []);

	const neoLine = useWalletNeo();
	const authNeoLine = async () => {
		const neoline = new window.NEOLineN3.Init();
		neoLine.wallet = getNeoLineWallet();
		neoLine.adapter = neoLine.wallet.adapter();
		await neoLine.adapter.connect();
		let account = await neoline.getAccount();
		if (account.address !== "") {
			try {
				await storeUser.login(IAuth.AuthTypes.neo, account.address);
			} catch (e) {
				console.error(e);
			}
		} else {
			toast.warning("Log in to the extension!");
		}
	};

	const addNeoLine = async () => {
		const neoline = new window.NEOLineN3.Init();
		neoLine.wallet = getNeoLineWallet();
		neoLine.adapter = neoLine.wallet.adapter();
		await neoLine.adapter.connect();
		let account = await neoline.getAccount();
		if (account.address !== "") {
			try {
				await storeUser.joiningWalletOrLogin(
					IAuth.AuthTypes.neo,
					account.address
				);
			} catch (e) {
				console.error(e);
			}
		} else {
			toast.warning("Log in to the extension!");
		}
	};

	const wcSdk = useWalletConnect();
	const authNeo = async () => {
		try {
			console.log(
				wcSdk.isConnected() ? "Connected successfully" : "Connection refused"
			);
			if (!wcSdk.isConnected()) {
				if (CHAIN_NEO === "mainnet") await wcSdk.connect(`neo3:mainnet`, []);
				else await wcSdk.connect(`neo3:testnet`, []);
				const account = wcSdk.getAccountAddress();
				if (account) {
					await storeUser.login(IAuth.AuthTypes.neo, account);
				}
			} else {
				const account = wcSdk.getAccountAddress();
				if (account) {
					await storeUser.login(IAuth.AuthTypes.neo, account);
				}
			}
		} catch (e) {
			toast.error("Something went wrong.");
			console.log(e);
		}
	};

	const addNeo = async () => {
		try {
			console.log(
				wcSdk.isConnected() ? "Connected successfully" : "Connection refused"
			);
			if (!wcSdk.isConnected()) {
				if (CHAIN_NEO === "mainnet") await wcSdk.connect(`neo3:mainnet`, []);
				else await wcSdk.connect(`neo3:testnet`, []);
				const account = wcSdk.getAccountAddress();
				if (account) {
					await storeUser.joiningWalletOrLogin(IAuth.AuthTypes.neo, account);
				}
			} else {
				const account = wcSdk.getAccountAddress();
				if (account) {
					await storeUser.joiningWalletOrLogin(IAuth.AuthTypes.neo, account);
				}
			}
		} catch (e) {
			toast.error("Something went wrong.");
			console.log(e);
		}
	};

	const loginSelector = async (accountId: string) => {
		let res:any;
		console.log('hello', localStorage.getItem("addOrAuthNear"))
		if (localStorage.getItem("addOrAuthNear") === "add") {
			res = await storeUser.joiningWalletOrLogin(
				IAuth.AuthTypes.near,
				accountId!
			);
		} else {
			res = await storeUser.login(IAuth.AuthTypes.near, accountId!);
		}

		if (res?.errorMessage) {
			toast.error(res?.errorMessage);
		}

		console.log("fff", storeUser.currentUser.near, storeUser.isAuth);
		if (
			storeUser.isAuth &&
			storeUser.currentUser.near &&
			localStorage.getItem("paymentInfo") &&
			!res?.errorMessage
		)
			modal.open(
				<ConfirmWalletForMint
					product={product}
					numberOfProducts={numberOfProducts}
					chain={chain}
					convert={convert}
				/>,
				{ abilityHide: true, className: "none" }
			);
	};

	const createSelector = async () => {
		const subscription = (await selector)!.on(
			"signedIn",
			async ({ accounts }) => {
				subscription.remove();
				if(localStorage.getItem(objectInLocalStorage.addOrAuthNear))
				await loginSelector(accounts[0].accountId);
			}
		);
	};

	useEffect(() => {
		createSelector();
	}, []);

	const addWalletUsingMetamask = async (type: AuthTypes) => {
		setIsProcessMetamask(true);
		const account = await getEthereumAccount(getChainIdFromAuthType(type));
		if (account) {
			const res = await connectWalletInPayment(type, account.walletAddress, storeUser, intl);
			console.log('joiningWalletOrLogin res', res)
		}
		setIsProcessMetamask(false);
	};

	const authMetamask = async (walletType: IAuth.AuthTypes) => {
		const account = await getEthereumAccount(
			getChainIdFromAuthType(walletType)
		);
		if (account) {
			console.log(account.provider, account.signer, account.walletAddress);
			await login(walletType, account.walletAddress.toLowerCase(), storeUser);
		}
	};

	const onClickAuthorizationOrAddWallet = async () => {
		console.log("modalAuthType", authMethod?.name);
		if (storeUser.isAuth) {
			switch (authMethod?.name) {
				case "MetaMask Velas":
					if (storeUser.currentUser.velas) {
						await authMetamask(AuthTypes.velas);
					} else {
						await addWalletUsingMetamask(AuthTypes.velas);
					}
					if (storeUser.isAuth && storeUser.currentUser.velas)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "MetaMask Oasis":
					if (storeUser.currentUser.oasis) {
						await authMetamask(AuthTypes.oasis);
					} else {
						await addWalletUsingMetamask(AuthTypes.oasis);
					}
					if (storeUser.isAuth && storeUser.currentUser.oasis)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "MetaMask SKALE":
					if (storeUser.currentUser.skale && storeUser.isAuth) {
						await authMetamask(AuthTypes.skale);
					} else {
						await addWalletUsingMetamask(AuthTypes.skale);
					}
					if (storeUser.isAuth && storeUser.currentUser.skale)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "MetaMask Avalanche":
					if (storeUser.currentUser.avax && storeUser.isAuth) {
						await authMetamask(AuthTypes.avax);
					} else {
						await addWalletUsingMetamask(AuthTypes.avax);
					}
					if (storeUser.isAuth && storeUser.currentUser.avax)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "NEAR wallet":
					localStorage.modalAuthType = "NEAR wallet";
					if (storeUser.currentUser.near && storeUser.isAuth) {
						localStorage.setItem("addOrAuthNear", "auth");
					} else {
						console.log("add");
						localStorage.setItem("addOrAuthNear", "add");
					}
					await logoutSelector();

					await authSelector(selector);
					break;
				case "Neon Wallet":
					if (storeUser.currentUser.neo) {
						await authNeo();
					} else {
						await addNeo();
					}
					if (storeUser.isAuth && storeUser.wallet)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "NeoLine Wallet":
					if (storeUser.currentUser.neo) {
						await authNeoLine();
					} else {
						await addNeoLine();
					}
					if (storeUser.isAuth && storeUser.wallet)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
			}
		} else {
			switch (authMethod.name) {
				case "MetaMask Velas":
					await authMetamask(IAuth.AuthTypes.velas);
					if (
						storeUser.isAuth &&
						storeUser.typeAuthorization === IAuth.AuthTypes.velas &&
						storeUser.wallet
					)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "MetaMask Oasis":
					await authMetamask(AuthTypes.oasis);
					if (
						storeUser.isAuth &&
						storeUser.typeAuthorization === IAuth.AuthTypes.oasis &&
						storeUser.wallet
					)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "MetaMask SKALE":
					await authMetamask(AuthTypes.skale);
					if (
						storeUser.isAuth &&
						storeUser.typeAuthorization === IAuth.AuthTypes.skale &&
						storeUser.wallet
					)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "MetaMask Avalanche":
					await authMetamask(AuthTypes.avax);
					if (
						storeUser.isAuth &&
						storeUser.typeAuthorization === IAuth.AuthTypes.avax &&
						storeUser.wallet
					)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "NEAR wallet":
					localStorage.modalAuthType = "NEAR wallet";
					localStorage.setItem("addOrAuthNear", "auth");
					await logoutSelector();
					await authSelector(selector);
					break;
				case "Neon Wallet":
					await authNeo();
					if (storeUser.isAuth && storeUser.wallet)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
				case "NeoLine Wallet":
					await authNeoLine();
					if (storeUser.isAuth && storeUser.wallet)
						modal.open(
							<ConfirmWalletForMint
								product={product}
								numberOfProducts={numberOfProducts}
								chain={chain}
								convert={convert}
							/>,
							{ abilityHide: true, className: "none" }
						);
					break;
			}
		}
	};

	return (
		<div className={styles.modal}>
			<LazyLoadComponent>
				<div
					className={styles.cross}
					onClick={() => {
						modal.close();
					}}
				>
					<img src={cross} alt="" />
				</div>
				<div className={styles.title}>
					{intl.formatMessage({ id: "Checkout" })}
				</div>
				<div className={styles.leftSide}>
					<div className={styles.titleItem}>
						{intl.formatMessage({ id: "Item" })}
					</div>
					<div className={styles.areaForIcon}>
						<img
							src={getInfoPack(product.type).smallIcon}
							alt={""}
							className={styles.iconMysteryPack}
						/>
					</div>
					<div className={styles.namePack}>
						{intl.formatMessage({ id: String(product.name) })}
						<span style={{ fontWeight: 700, marginLeft: "8px" }}>
							x{numberOfProducts}
						</span>
					</div>
					<div className={styles.wTypePack}></div>
					<div className={styles.priceOnePack}>
						$
						{
							// eslint-disable-next-line array-callback-return
							convert?.map((item) => {
								if (item.type === product.type) {
									return <>{item.price}</>;
								}
							})
						}
					</div>
					<div className={styles.packDescription}>
						{intl.formatMessage({
							id: getInfoPack(product.type).descriptionOnNextWindows,
						})}
					</div>
					<div
						className={styles.subTitleIconPack}
						style={chain === "Avalanche" ? { left: "30px" } : {}}
					>
						<img
							src={blockchains[chain]}
							alt={""}
							className={styles.iconBlockchainPack}
						/>{" "}
						{intl.formatMessage({ id: "Minted on" })} {chain}{" "}
						{intl.formatMessage({ id: "Blockchain" })}
					</div>
					<div className={styles.totalAmount}>
						$
						{
							// eslint-disable-next-line array-callback-return
							convert?.map((item) => {
								if (item.type === product.type) {
									return (
										<>
											{roundingUpPrice(
												Number(item.price) * numberOfProducts,
												2
											)}
										</>
									);
								}
							})
						}{" "}
						{intl.formatMessage({ id: "Total" })}
					</div>
					<div
						className={styles.buttonBack}
						onClick={() => {
							modal.open(
								<Suspense>
									<PurchaseMysteryPackModal
										product={products.findIndex(
											(el) => el.type === product.type
										)}
									/>
								</Suspense>,
								{
									abilityHide: true,
									className: "none",
								}
							);
						}}
					>
						{intl.formatMessage({ id: "Back" })}
					</div>
				</div>
				<div className={styles.rightSide}>
					<div className={styles.titleRightSide}>
						{intl.formatMessage({ id: "Choose Authorization Method" })}
					</div>
					<div className={styles.areaForSelect}>
						{AuthMethods[chain].map((method) => {
							if (method === authMethod) {
								return (
									<div
										className={styles.selectCryptocurrency}
										style={{
											background: `url(${backgroundForAuthMethods}) no-repeat`,
										}}
										onClick={() => {
											setAuthMethod(method);
										}}
									>
										<img src={method.icon} alt={""} />
										<div>{method.typePayment}</div>
									</div>
								);
							} else {
								return (
									<div
										className={styles.selectCryptocurrency}
										onClick={() => {
											setAuthMethod(method);
										}}
									>
										<img src={method.icon} alt={""} />
										<div>{method.typePayment}</div>
									</div>
								);
							}
						})}
					</div>
					<div className={styles.wFooter}>
						<div className={styles.addressUser}></div>
						<div className={styles.PriceInCryptoAndButtonPurchase}>
							<div className={styles.PriceInCrypto1}></div>
							<div className={styles.PriceInCrypto2}></div>
							<img src={lineForPayment} alt={""} className={styles.line} />
							<div
								className={cl(styles.buttonPurchase, {
									[styles.disabled]: isProcessMetamask,
								})}
								onClick={onClickAuthorizationOrAddWallet}
							>
								{intl.formatMessage({ id: "Purchase" })}
							</div>
						</div>
					</div>
				</div>
			</LazyLoadComponent>
		</div>
	);
};

export default ChooseAuthorizationMethod;
