import { IAuth } from "src/modules/common/general/types/common";
import { CHAIN_SETTINGS_METAMASK_AUTH } from "src/config/config";

export const getChainIdFromAuthType = (type: IAuth.AuthTypes) => {
	switch (type) {
		case IAuth.AuthTypes.avax: {
			return CHAIN_SETTINGS_METAMASK_AUTH.AVAX.id;
		}
		case IAuth.AuthTypes.velas: {
			return CHAIN_SETTINGS_METAMASK_AUTH.VELAS.id;
		}
		case IAuth.AuthTypes.doge: {
			return CHAIN_SETTINGS_METAMASK_AUTH.DOGE.id;
		}
		case IAuth.AuthTypes.oasis: {
			return CHAIN_SETTINGS_METAMASK_AUTH.OASIS.id;
		}
		case IAuth.AuthTypes.moonbeam: {
			return CHAIN_SETTINGS_METAMASK_AUTH.GLMR.id;
		}
		case IAuth.AuthTypes.skale: {
			return CHAIN_SETTINGS_METAMASK_AUTH.SKALE.id;
		}
	}
	return 0;
};
