import {
	Account,
	Contract,
	keyStores,
	Near,
	WalletConnection,
} from "near-api-js";
import { useContext } from "react";

import { NearContext } from "src/modules/common/general/utils/crypto/near/near.provider";

export const useNear = (): Near => {
	const { near } = useContext(NearContext);
	return near as Near;
};

export const useNearWallet = (): WalletConnection => {
	const { wallet } = useContext(NearContext);
	return wallet as WalletConnection;
};

export const useNearAccount = () => {
	const { near } = useContext(NearContext);
	const wallet = useNearWallet();
	const myKeyStore = new keyStores.BrowserLocalStorageKeyStore();
	// const wallet = new WalletAccount(near!, null);
	// const contractAccount = new Account(near!.connection, contractName);
	return new Account(near!.connection, wallet.getAccountId());
};

/**
 * Define a smart contract on **NEAR**
 * ```js
 * const contract = useNearContract('dev-345324325324', {
 *   viewMethods: ['getCount'],
 *   changeMethods: ['increment', 'decrement']
 * });
 * ```
 *
 * @param contractId  The id of the smart contract
 * @param contractMethods The methods defined on the smart contract
 */
export const useNearContract = (
	contractId: string,
	contractMethods: { viewMethods: string[]; changeMethods: string[] }
): Contract => {
	const wallet = useNearWallet();
	return new Contract(wallet.account(), contractId, contractMethods);
};

export const useNearContractAccount = (contractName: string) => {
	const { near } = useContext(NearContext);
	// const wallet = new WalletAccount(near!, null);
	// const contractName = 'dev-1624406486386-79437689012031';
	// walletAccount instance gets access key for contractId

	// const contractAccount = new Account(near!.connection, contractName);
	return new Account(near!.connection, contractName);
};
