import React from "react";
import styles from "src/modules/payment/ui/modals/BlockPayment/BlockPayment.module.scss";
import dragonHeadWarning from "src/modules/payment/assets/img/png/dragonHeadWarning.png";

export const BlockPayment: React.FC<any> = () => {
	return (
		<div className={styles.blockArea}>
			<img src={dragonHeadWarning} alt={""} />
			<div className={styles.areaWarning}>
				<div className={styles.textWarning}>
					Please do not close this window! Otherwise the transaction might fail.
				</div>
			</div>
		</div>
	);
};
