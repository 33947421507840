import { IAuth } from "../../../common/general/types/common";
import { IPaymentInfo, products } from "../../types/payment";
import { modal } from "../../../common/general/ui/Modal";
import { ConfirmWalletForMint } from "../../ui/modals/ConfirmWalletForMint/ConfirmWalletForMint";
import React from "react";
import StoreUser from "../../../common/user/store/StoreUser";
import { WalletSelector } from "@near-wallet-selector/core";

export const addNear = async (
	selector: Promise<WalletSelector>,
	storeUser: StoreUser
) => {
	if ((await selector).store.getState().accounts[0].accountId) {
		await storeUser.joiningWalletOrLogin(
			IAuth.AuthTypes.near,
			(await selector).store.getState().accounts[0].accountId
		);

		if (localStorage.getItem("paymentInfo")) {
			const paymentInfo = JSON.parse(
				localStorage.getItem("paymentInfo") || ""
			) as IPaymentInfo;
			if (storeUser.isAuth && storeUser.currentUser.near) {
				modal.open(
					<ConfirmWalletForMint
						numberOfProducts={paymentInfo.numberOfProducts}
						chain={paymentInfo.chain}
						convert={paymentInfo.convert}
						product={
							products.find((el: any) => paymentInfo.packType === el.type)!
						}
					/>,
					{
						abilityHide: true,
						className: "none",
					}
				);
			}
			console.log("res", 1);
		}
	}
};
