import React from "react";
import styles from "./MPackBodySuccess.module.scss";
import { useIntl } from "react-intl";

const MPackBodySuccess = () => {
	const intl = useIntl();

	return (
		<div className={styles.section}>
			<p className={styles.paragraph}>
				{/* {intl.formatMessage({ id: 'You can currently find it in the Inventory in you Profile.' })} */}
				{intl.formatMessage({
					id: "You can currently find it in the Inventory in you Profile.",
				})}
				{/*	<HashLink to={'/check-product'}*/}
				{/*	className={styles.checkNft} onClick={() => {*/}
				{/*	window.scrollTo(0, -200);*/}
				{/*	modal.close();*/}
				{/*}}>{intl.formatMessage({ id: 'My NFTs page' })}</HashLink>*/}
			</p>
			<p className={styles.paragraph}></p>
			<p className={styles.paragraph}>
				{intl.formatMessage({
					id: "Please keep in mind that you will be able to sell your Mystery Pack on the Flea Market once it’s available. If you open your pack by that date, you will be able to sell each item you found inside separately.",
				})}
			</p>
			<p className={styles.paragraph}>
				{intl.formatMessage({ id: "Stay in touch and follow our news!" })}
			</p>
		</div>
	);
};

export default MPackBodySuccess;
