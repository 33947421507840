import React from "react";
import styles from "src/modules/mainPage/ui/sections/Plate/Plate.module.scss";
import plate from "src/modules/mainPage/assets/img/sections/plate/png/Plate.png";
import plateMobile from "src/modules/mainPage/assets/img/png/PlateMobile.png";
import aroundName from "src/modules/mainPage/assets/img/sections/plate/png/AroundName.png";
import { useIntl } from "react-intl";
import cl from "classnames";

// AroundName
export const Plate: React.FC = () => {
	const intl = useIntl();
	return (
		<div className={styles.plate}>
			<div className={styles.plateImg}>
				<img className={styles.plateImgDesktop} src={plate} alt="" />
				<img className={styles.plateImgMobile} src={plateMobile} alt="" />
			</div>
			<div className={styles.plateContent}>
				<div className={styles.titleBlock}>
					<div className={cl(styles.title, styles.titleSection)}>
						Pixudi World
					</div>
					<img src={aroundName} alt="" />
					{/* <img src={aroundNameMobile} alt="" /> */}
				</div>
				<div className={styles.text}>
					{intl.formatMessage({
						id: "Pixudi is a traditional race board game built on the blockchain technology. Players compete with each other to collect more valuable objects and coins than others by overcoming obstacles and completing missions. Each board has its unique scenario, mechanics, treasures and adventures. In Pixudi World, you can compete with other players, play mini games, breed your own pets, collect items and characters, improve your stats and trade!",
					})}
				</div>
			</div>
		</div>
	);
};
