import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import "src/index.css";
import App from "src/app/App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Language from "./assets/translation/language";
import { createBrowserHistory } from "history";
import { HashRouter } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { WalletConnectProvider } from "@cityofzion/wallet-connect-sdk-react";
import { IntlProvider } from "react-intl";
import locales from "./assets/translation";
import ForNearSelector from "./modules/payment/ui/ForNearSelector/ForNearSelector";
import { setupWalletSelector, Wallet, WalletModuleFactory, WalletSelector } from "@near-wallet-selector/core";
import { SELECTOR_NETWORK } from "./config/config";
import StoreUser from "./modules/common/user/store/StoreUser";
import { setupNearWallet } from "@near-wallet-selector/near-wallet";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";


const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);


export const historyB = createBrowserHistory();

const languages = new Language();
const storeUser = new StoreUser();
const selector = setupWalletSelector({
	network: SELECTOR_NETWORK,
	modules: [
		setupNearWallet(),
		setupMyNearWallet(),
		setupMeteorWallet() as WalletModuleFactory<Wallet>,
		setupHereWallet() as WalletModuleFactory<Wallet>,
	],
});


const lang = navigator.language.slice(0, 2);

switch (lang) {
	case "en":
		languages.language = "en";
		break;
	case "ko":
		languages.language = "ko";
		break;
	case "es":
		languages.language = "es";
		break;
	case "fr":
		languages.language = "fr";
		break;
	case "id":
		languages.language = "id";
		break;
	case "it":
		languages.language = "it";
		break;
	case "ja":
		languages.language = "ja";
		break;
	case "vi":
		languages.language = "vi";
		break;
	case "zh":
		languages.language = "ch";
		break;
	case "de":
		languages.language = "de";
		break;
	default:
		languages.language = "en";
		break;
}

interface State {
	languages: Language;
	storeUser: StoreUser;
	selector: Promise<WalletSelector>;
}

const wcOptions = {
	projectId: "1217b9bf5f9ede61785ac5809ed8432b", // the ID of your project on Wallet Connect website
	relayUrl: "wss://relay.walletconnect.com", // we are using walletconnect's official relay server
	metadata: {
		name: "Pixudi", // your application name to be displayed on the wallet
		description:
			"Pixudi is a traditional race board game built on the blockchain technology. Players compete with each other to collect more valuable objects and coins than others by overcoming obstacles and completing missions. Each board has its unique scenario, mechanics, treasures and adventures. In Pixudi World, you can compete with other players, play mini games, breed your own pets, collect items and characters, improve your stats and FleaMarket!", // description to be shown on the wallet
		url: "pixudi.com", // url to be linked on the wallet
		icons: [`${window.location.host}/favicon.ico`], // icon to be shown on the wallet
	},
};

const messages = {
	...locales.en,
	...locales[languages.language],
};

export const Context = createContext<State>({ storeUser, languages, selector });

export const history = createBrowserHistory();

root.render(
	<>
		<WalletConnectProvider autoManageSession={true} options={wcOptions}>
			{/*<NearProvider environment={ENVIRONMENT_NEAR}>*/}
			<Context.Provider value={{ languages, storeUser, selector }}>
				<HashRouter>
					<ForNearSelector params={window.location.search} />
					<LazyLoadComponent>
						<ToastContainer />
					</LazyLoadComponent>
					<IntlProvider locale={languages.language} messages={messages}>
						<App />
					</IntlProvider>
				</HashRouter>
			</Context.Provider>
			{/*</NearProvider>*/}
		</WalletConnectProvider>
	</>,
);
