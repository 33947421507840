import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Main from "src/pages/Main/Main";
import { ENVIRONMENT_NEAR } from "../../modules/payment/config/configPayment";
import { NearProvider } from "../../modules/common/general/utils/crypto/near/near.provider";

const CheckNft = lazy(
	() => import(/* webpackChunkName: "CheckNft" */ "src/pages/CheckNft/CheckNft")
);

export function AppRoutes() {
	return (
		<Suspense>
			<Routes>
				<Route path="/" element={<Main />} />
				<Route path="/check-nft" element={
					<NearProvider environment={ENVIRONMENT_NEAR}>
					<CheckNft />
					</NearProvider>
				} />
			</Routes>
		</Suspense>
	);
}
