import { IAuth } from "../../../common/general/types/common";
import { toast } from "react-toastify";
import { addNear } from "./addNear";
import { IPaymentInfo, products } from "../../types/payment";
import { modal } from "../../../common/general/ui/Modal";
import { ConfirmWalletForMint } from "../../ui/modals/ConfirmWalletForMint/ConfirmWalletForMint";
import React from "react";
import { WalletSelector } from "@near-wallet-selector/core";
import StoreUser from "../../../common/user/store/StoreUser";

export const handlerAuthNear = async (
	selector: Promise<WalletSelector>,
	storeUser: StoreUser
) => {
	if ((await selector).isSignedIn()) {
		let res:any;
		if (localStorage.getItem("addOrAuthNear") === "auth") {
			res = await storeUser.login(
				IAuth.AuthTypes.near,
				(await selector).store.getState().accounts[0].accountId
			);
			if (res?.errorMessage) {
				toast.error(res?.errorMessage);
			}
		} else {
			await addNear(selector, storeUser);
		}

		if (localStorage.getItem("paymentInfo") && !res?.errorMessage) {
			const paymentInfo = JSON.parse(
				localStorage.getItem("paymentInfo") || ""
			) as IPaymentInfo;
			modal.open(
				<ConfirmWalletForMint
					product={
						products.find((el: any) => paymentInfo.packType === el.type)!
					}
					numberOfProducts={paymentInfo.numberOfProducts}
					chain={paymentInfo.chain}
					convert={paymentInfo.convert}
				/>,
				{ abilityHide: true, className: "none" }
			);
		}
	}
};
