import { IAuth } from "src/modules/common/general/types/common";
import { CHAIN_SETTINGS_METAMASK_AUTH } from "src/modules/common/general/config/config";

export enum CHAIN_IDs {
	velasTestnet = 111,
	velas = 106,
	bnb = 56,
	bnbTestnet = 97,
	avax = 43114,
	avaxTestnet = 43113,
	eth = 1,
	// skale = 1351057110,
	//skale = 344106930,
	skale = 1564830818,
	skaleTestnet = 344106930,
	moonbeam = 1284,
	moonbeamTest = 1287,
	oasis = 23294,
	oasisTestnet = 23295,
	doge = 2000,
	dogeTestnet = 568,
}

export const AVALANCHE_TESTNET_PARAMS = {
	chainId: "0xA869",
	chainName: "Avalanche Testnet C-Chain",
	nativeCurrency: {
		name: "Avalanche",
		symbol: "AVAX",
		decimals: 18,
	},
	rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
	blockExplorerUrls: ["https://testnet.snowtrace.io/"],
};

export const AVALANCHE_MAINNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.avax).toString(16),
	rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
	chainName: "Avalanche",
	nativeCurrency: {
		name: "Avalanche",
		symbol: "AVAX",
		decimals: 18,
	},
	blockExplorerUrls: ["https://snowtrace.io/"],
};

export const AVALANCHE_MAINNET_TESTNET = {
	chainId: "0x" + Number(CHAIN_IDs.avaxTestnet).toString(16),
	rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
	chainName: "Avalanche Fuji Testnet",
	nativeCurrency: {
		name: "Avalanche Fuji Testnet",
		symbol: "AVAX",
		decimals: 18,
	},
	blockExplorerUrls: ["https://testnet.snowtrace.io"],
};

export const VELAS_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.velas).toString(16),
	rpcUrls: ["https://evmexplorer.velas.com/rpc"],
	chainName: "Velas Network",
	nativeCurrency: {
		name: "Velas",
		symbol: "VLX",
		decimals: 18,
	},
	blockExplorerUrls: ["https://evmexplorer.velas.com/rpc"],
};

export const VELAS_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.velasTestnet).toString(16),
	rpcUrls: ["https://testnet.velas.com/rpc"],
	chainName: "Velas Testnet",
	nativeCurrency: {
		name: "Velas",
		symbol: "VLX",
		decimals: 18,
	},
	blockExplorerUrls: ["https://evmexplorer.testnet.velas.com"],
};

export const MOONBEAM_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.moonbeam).toString(16),
	rpcUrls: ["https://rpc.api.moonbeam.network"],
	chainName: "Moonbeam Network",
	nativeCurrency: {
		name: "Moonbeam",
		symbol: "GLMR",
		decimals: 18,
	},
	blockExplorerUrls: ["https://moonscan.io/"],
};

export const MOONBEAM_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.moonbeamTest).toString(16),
	rpcUrls: ["https://rpc.api.moonbase.moonbeam.network"],
	chainName: "Moonbeam Testnet",
	nativeCurrency: {
		name: "Moonbeam",
		symbol: "GLMR",
		decimals: 18,
	},
	blockExplorerUrls: [
		"https://moonbase.moonscan.io",
		"https://moonbase-blockscout.testnet.moonbeam.network",
		"https://moonbeam-explorer.netlify.app/?network=MoonbaseAlpha",
		"https://moonbase.subscan.io/",
		"https://polkadot.js.org/apps/?rpc=wss://wss.api.moonbase.moonbeam.network#/explorer",
	],
};

export const OASIS_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.oasis).toString(16),
	rpcUrls: ["https://sapphire.oasis.io"],
	chainName: "Oasis Network",
	nativeCurrency: {
		name: "Oasis",
		symbol: "OASIS",
		decimals: 18,
	},
	blockExplorerUrls: ["https://explorer.sapphire.oasis.io"],
};

export const OASIS_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.oasisTestnet).toString(16),
	rpcUrls: ["https://testnet.sapphire.oasis.dev"],
	chainName: "Oasis Testnet",
	nativeCurrency: {
		name: "Oasis",
		symbol: "OASIS",
		decimals: 18,
	},
	blockExplorerUrls: ["https://testnet.explorer.sapphire.oasis.dev"],
};

export const DOGE_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.doge).toString(16),
	rpcUrls: ["https://rpc-sg.dogechain.dog"],
	chainName: "Dogechain Mainnet",
	nativeCurrency: {
		name: "Doge",
		symbol: "wDOGE",
		decimals: 18,
	},
	blockExplorerUrls: ["https://explorer.dogechain.dog"],
};

export const DOGE_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.dogeTestnet).toString(16),
	rpcUrls: ["https://rpc-testnet.dogechain.dog"],
	chainName: "Dogechain Testnet",
	nativeCurrency: {
		name: "Doge",
		symbol: "wDOGE",
		decimals: 18,
	},
	blockExplorerUrls: ["https://explorer-testnet.dogechain.dog"],
};

export const BNB_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.bnb).toString(16),
	rpcUrls: ["https://bsc-dataseed.binance.org/"],
	chainName: "Smart Chain",
	nativeCurrency: {
		symbol: "BNB",
		decimals: 18,
	},
	blockExplorerUrls: ["https://bscscan.com"],
};

export const BNB_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.bnbTestnet).toString(16),
	rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
	chainName: "Smart Chain - Testnet",
	nativeCurrency: {
		symbol: "BNB",
		decimals: 18,
	},
	blockExplorerUrls: ["https://testnet.bscscan.com"],
};

export const SKALE_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.skale).toString(16),
	chainName: "Calypso NFT Hub (SKALE)",
	rpcUrls: ["https://mainnet.skalenodes.com/v1/honorable-steel-rasalhague"],
	nativeCurrency: {
		name: "SKALE FUEL",
		symbol: "sFUEL",
		decimals: 18,
	},
	blockExplorerUrls: [
		"https://expedition.dev/?network=SKALE&rpcUrl=" +
			"https://eth-online.skalenodes.com/v1/hackathon-complex-easy-naos",
	],
};

export const SKALE_TESTNET_PARAMS = {
	chainId: "0x" + Number(CHAIN_IDs.skaleTestnet).toString(16),
	chainName: "Calypso NFT Hub (SKALE Testnet)",
	rpcUrls: ["https://staging-v3.skalenodes.com/v1/staging-utter-unripe-menkar"],
	nativeCurrency: {
		name: "SKALE FUEL",
		symbol: "sFUEL",
		decimals: 18,
	},
	blockExplorerUrls: [
		"https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com",
	],
};

export const CHAIN_SETTINGS_MAINNET_AND_TESTNET = {
	VELAS_MAINNET: {
		id: 106,
		params: VELAS_PARAMS,
	},
	VELAS_TESTNET: {
		id: 111,
		params: VELAS_TESTNET_PARAMS,
	},
	ETH_MAINNET: {
		id: 1,
		params: {},
	},
	ETH_TESTNET: {
		id: 5,
		params: {},
	},
	BNB_MAINNET: {
		id: 56,
		params: BNB_PARAMS,
	},
	BNB_TESTNET: {
		id: 97,
		params: BNB_TESTNET_PARAMS,
	},
	AVAX_MAINNET: {
		id: 43114,
		params: AVALANCHE_MAINNET_PARAMS,
	},
	AVAX_TESTNET: {
		id: 43113,
		params: AVALANCHE_MAINNET_TESTNET,
	},
	GLMR_MAINNET: {
		id: 1284,
		params: MOONBEAM_PARAMS,
	},
	GLMR_TESTNET: {
		id: 1287,
		params: MOONBEAM_TESTNET_PARAMS,
	},
	OASIS_MAINNET: {
		id: 23294,
		params: OASIS_PARAMS,
	},
	OASIS_TESTNET: {
		id: 23295,
		params: OASIS_TESTNET_PARAMS,
	},
	DOGE_MAINNET: {
		id: 2000,
		params: DOGE_PARAMS,
	},
	DOGE_TESTNET: {
		id: 568,
		params: DOGE_TESTNET_PARAMS,
	},
	SKALE_MAINNET: {
		// id: 1351057110,
		id: 1564830818,
		params: SKALE_PARAMS,
	},
	SKALE_TESTNET: {
		id: 344106930,
		params: SKALE_TESTNET_PARAMS,
	},
};
