import React, { useContext, useEffect, useState } from "react";
import styles from "src/modules/payment/ui/PaymentButtonWithPaymentLogic/PaymentButtonWithPaymentLogic.module.scss";
import { BigNumber, ethers } from "ethers";
import { toast } from "react-toastify";
import { utils } from "near-api-js";
import { LoadingPayment } from "src/modules/payment/ui/modals/LoadingPayment/LoadingPayment";
import abiBUSD from "src/modules/payment/assets/contractAbi/abiBUSD.json";
import abiUSDTETH from "src/modules/payment/assets/contractAbi/abiUSDTETH.json";
import bigInt from "big-integer";
import {
	ADDRESS_BNB_BUSD,
	ADDRESS_ETH_SKALE,
	ADDRESS_ETH_USDC,
	ADDRESS_ETH_USDT,
	ADDRESS_NEAR_USDC,
	ADDRESS_NEAR_USDT,
	ADDRESS_TRON_USDT,
	CHAIN_NEO,
} from "src/modules/payment/config/configPayment";
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import { Context } from "src/index";
import {
	TBlockchainPayment,
	TCoinPayment,
} from "src/modules/payment/types/payment";
import { checkPayment } from "src/modules/payment/utils/paymentMarket";
import { modal } from "src/modules/common/general/ui/Modal";
import { getEthereumAccount } from "src/modules/common/general/utils/crypto/metamask/metamask";
import { getNeoLineWallet } from "@rentfuse-labs/neo-wallet-adapter-wallets";
import { useWallet as useWalletNeo } from "@rentfuse-labs/neo-wallet-adapter-react/lib/hooks/use-wallet";
import { cryptoIcons } from "src/modules/common/general/utils/crypto/cryptoIcons";
import { METAMASK_NETWORK_IDS } from "src/config/config";
import { useIntl } from "react-intl";
import { checkAuthInSelector } from "../../utils/auth/checkAuthInSelector";

export const PaymentButtonWithPaymentLogic: React.FC<{
	blockchain: TBlockchainPayment;
	coin: TCoinPayment;
	typePaymentSystem?: string;
	TimeOut: any;
	walletPay: string;
	amountPrice: string;
	history_id: number;
}> = ({
	blockchain,
	coin,
	typePaymentSystem,
	TimeOut,
	walletPay,
	amountPrice,
	history_id,
}) => {
	const [stateButton, setStateButton] = useState(true);
	const [walletAddress, setWalletAddress] = useState(null);
	const intl = useIntl();
	const { selector } = useContext(Context);

	useEffect(() => {
		localStorage.removeItem("authSelectorInEndStep");
	}, []);

	const hexToDecimal = (hex: string) => parseInt(hex, 16);

	const pay = async (
		blockchain: TBlockchainPayment,
		coin: TCoinPayment,
		type?: string
	) => {
		switch (blockchain) {
			case "ETH":
				if (coin === "ETH") {
					await payMetamask(
						walletPay,
						amountPrice,
						history_id,
						METAMASK_NETWORK_IDS.ETH
					);
				} else if (coin === "USDT") {
					await payUSDTETH(walletPay, amountPrice, history_id);
				} else {
					await payUSDCETH(walletPay, amountPrice, history_id);
				}
				break;
			case "BSC":
				if (coin === "BNB") {
					await payMetamask(
						walletPay,
						amountPrice,
						history_id,
						METAMASK_NETWORK_IDS.BNB
					);
				} else {
					await payBUSD(walletPay, amountPrice, history_id);
				}
				break;
			case "Velas":
				if (type === "metaMask") {
					await payVlx(walletPay, amountPrice, history_id);
				} else {
					// await payVelasAccount(walletPay, amountPrice, history_id);
				}
				break;
			case "NEAR":
				if (type === "NEAR Wallet") {
					if (coin === "USDC") {
						await payNearUSDC(walletPay, amountPrice, history_id);
					} else {
						if (coin === "USDT") {
							await payNearUSDT(walletPay, amountPrice, history_id);
						} else {
							await payNear(walletPay, amountPrice, history_id);
						}
					}
				} else {
					if (coin === "USDC") {
						await payNearUSDCMyNearWallet(walletPay, amountPrice, history_id);
					} else {
						if (coin === "USDT") {
							await payNearUSDTMyNearWallet(walletPay, amountPrice, history_id);
						} else {
							await payNearMyNearWallet(walletPay, amountPrice, history_id);
						}
					}
				}

				break;
			case "Tron":
				await payUSDTTRX(walletPay, amountPrice, history_id);
				break;
			case "Oasis (Sapphire)" || "Oasis":
				await payMetamask(
					walletPay,
					amountPrice,
					history_id,
					METAMASK_NETWORK_IDS.OASIS
				);
				break;
			case "SKALE":
				await paySKALEETH(walletPay, amountPrice, history_id);
				break;
			case "Neo":
				if (type === "neoLine") {
					payNeoLine(walletPay, amountPrice, coin, history_id);
				}
				if (type === "neoWallet") {
					payNeoWallet(walletPay, amountPrice, coin, history_id);
				}
				break;
			case "Avalanche":
				console.log("hello");
				//@ts-ignore
				await payMetamask(
					walletPay,
					amountPrice,
					history_id,
					METAMASK_NETWORK_IDS.AVAX
				);
				break;
		}
	};

	const wcSdk = useWalletConnect();
	const neoLine = useWalletNeo();

	const payNeoWallet = async (
		address: string,
		amount: string,
		coin: string,
		history_id: number
	) => {
		try {
			if (CHAIN_NEO === "mainnet") await wcSdk.connect(`neo3:mainnet`, []);
			else await wcSdk.connect(`neo3:testnet`, []);
			setStateButton(false);
			const account = await wcSdk.invokeFunction({
				invocations: [
					{
						scriptHash:
							coin === "NEO"
								? "0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5"
								: "0xd2a4cff31913016155e38e474a2c06d08be276cf",
						operation: "transfer",
						args: [
							// @ts-ignore
							{ type: "Address", value: wcSdk.getAccountAddress() ?? "" },
							// @ts-ignore
							{ type: "Address", value: address },
							//@ts-ignore
							{
								type: "Integer",
								value:
									coin === "GAS"
										? Number(amount) * 100000000
										: Math.ceil(Number(amount)),
							},
							{ type: "Array", value: [] },
						],
					},
				],
				signers: [
					{
						scopes: "Global",
					},
				],
			});
			if (account) {
				toast.loading("Waiting for payment confirmation...");
				modal.open(<LoadingPayment />, { abilityHide: true });
				await checkPayment(history_id, 0, intl);
				clearTimeout(TimeOut);
				setStateButton(true);
			}
		} catch (e) {
			setStateButton(true);
		}
	};

	const payNeoLine = async (
		address: string,
		amount: string,
		coin: string,
		history_id: number
	) => {
		setStateButton(false);
		if (!window.NEOLineN3) {
			toast.warning(intl.formatMessage({ id: "NeoLine not installed" }));
			setStateButton(true);
			return;
		}
		const neoline = new window.NEOLineN3.Init();
		neoLine.wallet = getNeoLineWallet();
		neoLine.adapter = neoLine.wallet.adapter();
		await neoLine.adapter.connect();
		let account = await neoline.getAccount();
		console.log(neoLine);
		neoline
			.send({
				fromAddress: account.address,
				toAddress: address,
				asset: coin,
				amount: coin === "NEO" ? Math.ceil(Number(amount)) : amount,
				fee: "0.0001",
				broadcastOverride: false,
			})
			.then(async (result: any) => {
				console.log("Send transaction  success!");
				console.log("Transaction ID: " + result.txid);
				console.log("RPC node URL: " + result.nodeURL);
				toast.loading("Waiting for payment confirmation...");
				modal.open(<LoadingPayment />, { abilityHide: true });
				await checkPayment(history_id, 0, intl);
				clearTimeout(TimeOut);
				setStateButton(true);
			})
			.catch((error: any) => {
				setStateButton(true);
				const { type, description, data } = error;
				switch (type) {
					case "NO_PROVIDER":
						console.log("No provider available.");
						break;
					case "RPC_ERROR":
						console.log(
							"There was an error when broadcasting this transaction to the network."
						);
						break;
					case "MALFORMED_INPUT":
						console.log("The receiver address provided is not valid.");
						break;
					case "CANCELED":
						console.log("The user has canceled this transaction.");
						break;
					case "INSUFFICIENT_FUNDS":
						toast.error("insufficient funds");
						console.log(
							"The user has insufficient funds to execute this transaction."
						);
						break;
					default:
						// Not an expected error object.  Just write the error to the console.
						console.error(error);
						break;
				}
			});
	};

	const payMetamask = async (
		address: string,
		amount: string,
		history_id: number,
		network: number
	) => {
		try {
			setStateButton(false);
			const account = await getEthereumAccount(network);
			if (!account) {
				setStateButton(true);
				return;
			}
			const params = [
				{
					from: account.walletAddress,
					to: address,
					value: ethers.utils.parseUnits(amount, "ether").toHexString(),
				},
			];
			const transactionHash = await account.provider
				.send("eth_sendTransaction", params)
				.catch((er) => {
					setStateButton(true);
					console.error(er);
					throw new Error("payment interrupted");
				});
			toast.loading("Waiting for payment confirmation...");
			modal.open(<LoadingPayment />, { abilityHide: true });
			account.provider
				.waitForTransaction(transactionHash)
				.then(async function () {
					console.log("transactionHash is " + transactionHash);
					await checkPayment(history_id, 0, intl);
					clearTimeout(TimeOut);
					setStateButton(true);
				})
				.catch(() => {
					toast.error("Something went wrong");
					setStateButton(true);
				});
		} catch (er) {
			console.error("error", er);
		}
	};

	const payUSDTETH = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		setStateButton(false);
		const account = await getEthereumAccount(METAMASK_NETWORK_IDS.ETH);
		if (!account) {
			setStateButton(true);
			return;
		}
		const contractUSDTETH = new ethers.Contract(
			ADDRESS_ETH_USDT,
			abiUSDTETH,
			account.signer
		);
		const amountInTheSmallestUnit = ethers.utils
			.parseUnits(amount, "ether")
			.toHexString();
		const res_balance: any = await contractUSDTETH.balanceOf(
			account.walletAddress
		);
		if (hexToDecimal(res_balance._hex) / 1000000000000000000 < Number(amount)) {
			toast.warning("there are not enough founds in your wallet");
			setStateButton(true);
			return;
		} else {
			try {
				const tx = await contractUSDTETH.transfer(
					address,
					amountInTheSmallestUnit
				);
				toast.loading("Waiting for payment confirmation...");
				modal.open(<LoadingPayment />);
				await tx.wait();
				contractUSDTETH.provider
					.waitForTransaction(tx.hash)
					.then(async function () {
						console.log("transactionHash is " + tx.hash);
						clearTimeout(TimeOut);
						await checkPayment(history_id, 0, intl);
					})
					.catch(() => {
						toast.error("Something went wrong");
					});
			} catch (er) {
				setStateButton(true);
			}
		}
	};

	const paySKALEETH = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		setStateButton(false);
		const account = await getEthereumAccount(METAMASK_NETWORK_IDS.ETH);
		if (!account) {
			setStateButton(true);
			return;
		}
		const contractETHSKALE = new ethers.Contract(
			ADDRESS_ETH_SKALE,
			abiUSDTETH,
			account.signer
		);
		const amountInTheSmallestUnit = ethers.utils
			.parseUnits(amount, "ether")
			.toHexString();
		const res_balance: any = await contractETHSKALE.balanceOf(
			account.walletAddress
		);
		if (hexToDecimal(res_balance._hex) / 1000000000000000000 < Number(amount)) {
			toast.warning("there are not enough founds in your wallet");
			setStateButton(true);
			return;
		} else {
			try {
				const tx = await contractETHSKALE.transfer(
					address,
					amountInTheSmallestUnit
				);
				toast.loading("Waiting for payment confirmation...");
				modal.open(<LoadingPayment />);
				await tx.wait();
				contractETHSKALE.provider
					.waitForTransaction(tx.hash)
					.then(async function () {
						console.log("transactionHash is " + tx.hash);
						clearTimeout(TimeOut);
						await checkPayment(history_id, 0, intl);
					})
					.catch(() => {
						toast.error("Something went wrong");
					});
			} catch (er) {
				setStateButton(true);
			}
		}
	};

	const payUSDCETH = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		setStateButton(false);
		const account = await getEthereumAccount(METAMASK_NETWORK_IDS.ETH);
		if (!account) {
			setStateButton(true);
			return;
		}
		const contractUSDTETH = new ethers.Contract(
			ADDRESS_ETH_USDC,
			abiUSDTETH,
			account.signer
		);
		const amountInTheSmallestUnit = ethers.utils
			.parseUnits(amount, "ether")
			.toHexString();
		const res_balance: any = await contractUSDTETH.balanceOf(
			account.walletAddress
		);
		if (hexToDecimal(res_balance._hex) / 1000000000000000000 < Number(amount)) {
			toast.warning("there are not enough founds in your wallet");
			setStateButton(true);
			return;
		} else {
			try {
				const tx = await contractUSDTETH.transfer(
					address,
					amountInTheSmallestUnit
				);
				toast.loading("Waiting for payment confirmation...");
				modal.open(<LoadingPayment />);
				await tx.wait();
				contractUSDTETH.provider
					.waitForTransaction(tx.hash)
					.then(async function () {
						console.log("transactionHash is " + tx.hash);
						clearTimeout(TimeOut);
						await checkPayment(history_id, 0, intl);
					})
					.catch(() => {
						toast.error("Something went wrong");
					});
			} catch (er) {
				setStateButton(true);
			}
		}
	};

	const payBUSD = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		const account = await getEthereumAccount(METAMASK_NETWORK_IDS.BNB);
		if (!account) {
			return;
		}
		const contractBUSD = new ethers.Contract(
			ADDRESS_BNB_BUSD,
			abiBUSD,
			account.signer
		);
		const amountInTheSmallestUnit = BigNumber.from(
			String(1000000000000000000 * Number(amount))
		);
		const res_balance: any = await contractBUSD.balanceOf(
			account.walletAddress
		);
		if (hexToDecimal(res_balance._hex) / 1000000000000000000 < Number(amount)) {
			toast.warning("there are not enough founds in your wallet");
			setStateButton(true);
			return;
		} else {
			try {
				const tx = await contractBUSD.transfer(
					address,
					amountInTheSmallestUnit
				);
				toast.loading("Waiting for payment confirmation...");
				modal.open(<LoadingPayment />);
				await tx.wait();
				contractBUSD.provider
					.waitForTransaction(tx.hash)
					.then(async function () {
						console.log("transactionHash is " + tx.hash);
						clearTimeout(TimeOut);
						await checkPayment(history_id, 0, intl);
					})
					.catch(() => {
						toast.error("Something went wrong");
					});
			} catch (er) {
				setStateButton(true);
			}
		}
	};

	const payVlx = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		try {
			setStateButton(false);
			const account = await getEthereumAccount(METAMASK_NETWORK_IDS.VELAS);
			if (!account) {
				setStateButton(true);
				return;
			}
			const params = [
				{
					from: account.walletAddress,
					to: address,
					value: ethers.utils.parseUnits(amount, "ether").toHexString(),
				},
			];
			const transactionHash = await account.provider
				.send("eth_sendTransaction", params)
				.catch((er) => {
					console.error(er);
					setStateButton(true);
					throw new Error("payment interrupted");
				});
			toast.loading("Waiting for payment confirmation...");
			modal.open(<LoadingPayment />);
			account.provider
				.waitForTransaction(transactionHash)
				.then(async function () {
					console.log("transactionHash is " + transactionHash);
					await checkPayment(history_id, 0, intl);
					clearTimeout(TimeOut);
					setStateButton(true);
				})
				.catch(() => {
					toast.error("Something went wrong");
					setStateButton(true);
				});
		} catch (er) {
			console.error(er);
		}
	};

	async function sendTRC20Token(
		fromAddress: null,
		toAddress: string,
		amount: any,
		CONTRACT: string
	) {
		// @ts-ignore
		const tronWeb = await window.tronLink.tronWeb;
		const options = {
			feeLimit: 15000000,
			callValue: 0,
		};
		const tx = await tronWeb.transactionBuilder.triggerSmartContract(
			CONTRACT,
			"transfer(address,uint256)",
			options,
			[
				{
					type: "address",
					value: toAddress,
				},
				{
					type: "uint256",
					value: amount * 1000000,
				},
			],
			tronWeb.address.toHex(fromAddress)
		);
		const signedTx = await tronWeb.trx.sign(tx.transaction);
		const broadcastTx = await tronWeb.trx.sendRawTransaction(signedTx);
		await tronWeb.trx.getTransactionInfo(broadcastTx.txid);
		console.log("transaction hash:", broadcastTx.txid);
		return broadcastTx;
	}

	const payUSDTTRX = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		setStateButton(false);
		try {
			// @ts-ignore
			await window.tronLink.request({ method: "tron_requestAccounts" });
			// @ts-ignore
			let accounts = await window.tronLink;
			await setWalletAddress(accounts.tronWeb.defaultAddress.base58);
			// @ts-ignore
			const tronWeb = await window.tronLink.tronWeb;
			const walletBalance = await tronWeb.trx.getBalance(walletAddress);
			let contract = await tronWeb.contract().at(ADDRESS_TRON_USDT);
			const result = await contract.balanceOf(address).call();
			let number = bigInt(result.toString());

			if (walletBalance >= 15000000) {
				if (number.toJSNumber() >= Number(amount)) {
					await sendTRC20Token(
						walletAddress,
						address,
						amount,
						ADDRESS_TRON_USDT
					).then(() => {
						setStateButton(true);
						toast.loading("Waiting for payment confirmation...");
						modal.open(<LoadingPayment />);
						checkPayment(history_id, 0, intl);
						clearTimeout(TimeOut);
					});
				} else {
					setStateButton(true);
					toast.error(`you don't have enough USDT on your balance`);
				}
			} else {
				setStateButton(true);
				toast.error(`you don't have enough trx on your balance to pay gas`);
			}
		} catch (er) {
			console.log(er);
			setStateButton(true);
			toast.error("try again "); //and check the selected network in tronlink
		}
	};

	const payNearMyNearWallet = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		try {
			const amountParse = utils.format.parseNearAmount(
				String(Number(amount) + 0.00001)
			);
			localStorage.setItem("history_id", String(history_id));

			const walletSelector = await (await selector).wallet("my-near-wallet");

			let state = (await selector)!.store.getState();
			const accountId =
				state.accounts.find((account: any) => account.active)?.accountId ||
				null;

			if (!(await checkAuthInSelector(walletSelector, selector))) {
				return;
			}

			await walletSelector.signAndSendTransaction({
				receiverId: address,
				actions: [
					{
						type: "Transfer",
						params: {
							deposit: amountParse!,
						},
					},
				],
			});

			toast.loading("Waiting for payment confirmation...");
			modal.open(<LoadingPayment />);
			await checkPayment(history_id, 0, intl);
			clearTimeout(TimeOut);
		} catch (er) {
			console.error(er);
		}
	};

	const payNear = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		try {
			// Payment
			const amountParse = utils.format.parseNearAmount(
				String(Number(amount) + 0.00001)
			);
			localStorage.setItem("history_id", String(history_id));

			// const walletSelector = await (await selector).wallet("my-near-wallet");
			// const walletSelector = await (await selector).wallet("here-wallet");
			// const walletSelector = await (await selector).wallet("meteor-wallet");
			const walletSelector = await (await selector).wallet("near-wallet");

			let state = (await selector)!.store.getState();
			const accountId =
				state.accounts.find((account:any) => account.active)?.accountId || null;

			if (!(await checkAuthInSelector(walletSelector, selector))) {
				return;
			}

			await walletSelector.signAndSendTransaction({
				receiverId: address,
				actions: [
					{
						type: "Transfer",
						params: {
							deposit: amountParse!,
						},
					},
				],
			});

			toast.loading("Waiting for payment confirmation...");
			modal.open(<LoadingPayment />);
			await checkPayment(history_id, 0, intl);
			clearTimeout(TimeOut);
		} catch (er) {
			console.error(er);
		}
	};

	const amountInYoctoNEAR = 1;

	const payNearUSDCMyNearWallet = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		try {
			const walletSelector = await (await selector).wallet("my-near-wallet");
			if (!(await checkAuthInSelector(walletSelector, selector))) {
				return;
			}

			// Payment
			localStorage.setItem("history_id", String(history_id));
			await walletSelector.signAndSendTransaction({
				receiverId: ADDRESS_NEAR_USDC,
				actions: [
					{
						type: "FunctionCall",
						params: {
							deposit: String(amountInYoctoNEAR),
							methodName: "ft_transfer",
							gas: "30000000000000",
							args: {
								amount: String(Number(amount) * 1000000),
								receiver_id: address,
								msg: "",
							},
						},
					},
				],
			});

			toast.loading("Waiting for payment confirmation...");
			modal.open(<LoadingPayment />);
			await checkPayment(history_id, 0, intl);
			clearTimeout(TimeOut);
		} catch (er) {
			console.error(er);
		}
	};

	const payNearUSDC = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		try {
			const walletSelector = await (await selector).wallet("near-wallet");
			if (!(await checkAuthInSelector(walletSelector, selector))) {
				return;
			}

			localStorage.setItem("history_id", String(history_id));

			await walletSelector.signAndSendTransaction({
				receiverId: ADDRESS_NEAR_USDC,
				actions: [
					{
						type: "FunctionCall",
						params: {
							deposit: String(amountInYoctoNEAR),
							methodName: "ft_transfer",
							gas: "30000000000000",
							args: {
								amount: String(Number(amount) * 1000000),
								receiver_id: address,
								msg: "",
							},
						},
					},
				],
			});

			toast.loading("Waiting for payment confirmation...");
			modal.open(<LoadingPayment />);
			await checkPayment(history_id, 0, intl);
			clearTimeout(TimeOut);
		} catch (er) {
			console.error(er);
		}
	};

	const payNearUSDTMyNearWallet = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		try {
			const walletSelector = await (await selector).wallet("my-near-wallet");
			if (!(await checkAuthInSelector(walletSelector, selector))) {
				return;
			}

			// Payment
			localStorage.setItem("history_id", String(history_id));
			await walletSelector.signAndSendTransaction({
				receiverId: ADDRESS_NEAR_USDT,
				actions: [
					{
						type: "FunctionCall",
						params: {
							deposit: String(amountInYoctoNEAR),
							methodName: "ft_transfer",
							gas: "30000000000000",
							args: {
								amount: String(Number(amount) * 1000000),
								receiver_id: address,
								msg: "",
							},
						},
					},
				],
			});

			toast.loading("Waiting for payment confirmation...");
			modal.open(<LoadingPayment />);
			await checkPayment(history_id, 0, intl);
			clearTimeout(TimeOut);
		} catch (er) {
			console.error(er);
		}
	};

	const payNearUSDT = async (
		address: string,
		amount: string,
		history_id: number
	) => {
		try {
			const walletSelector = await (await selector).wallet("near-wallet");

			if (!(await checkAuthInSelector(walletSelector, selector))) {
				return;
			}

			// Payment
			localStorage.setItem("history_id", String(history_id));

			await walletSelector.signAndSendTransaction({
				receiverId: ADDRESS_NEAR_USDT,
				actions: [
					{
						type: "FunctionCall",
						params: {
							deposit: String(amountInYoctoNEAR),
							methodName: "ft_transfer",
							gas: "30000000000000",
							args: {
								amount: String(Number(amount) * 1000000),
								receiver_id: address,
								msg: "",
							},
						},
					},
				],
			});

			toast.loading("Waiting for payment confirmation...");
			modal.open(<LoadingPayment />);
			await checkPayment(history_id, 0, intl);
			clearTimeout(TimeOut);
		} catch (er) {
			console.error(er);
		}
	};

	switch (typePaymentSystem) {
		case "VLX":
			return (
				<div className={styles.modal}>
					<div
						className={styles.wDiv}
						onClick={() => {
							if (stateButton) {
								pay(blockchain, coin, "metaMask");
							}
						}}
					>
						<img src={cryptoIcons.wallets.png.Metamask} alt={""} />
						<div>{intl.formatMessage({ id: "Pay with Metamask" })}</div>
					</div>
					{/*<div*/}
					{/*	className={styles.velasPay}*/}
					{/*	onClick={() => {*/}
					{/*		if (stateButton) {*/}
					{/*			pay(blockchain, coin, "velas");*/}
					{/*		}*/}
					{/*	}}*/}
					{/*>*/}
					{/*	<div>*/}
					{/*		<img*/}
					{/*			src={cryptoIcons.blockchains.png.Velas}*/}
					{/*			alt={""}*/}
					{/*			className={styles.velas}*/}
					{/*		/>{" "}*/}
					{/*		{intl.formatMessage({ id: "Velas Account" })}*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>
			);
			break;
		case "NEO":
		case "GAS":
			return (
				<div className={styles.modalVelas}>
					<div
						className={styles.velasPay}
						onClick={() => {
							if (stateButton) {
								pay(blockchain, coin, "neoLine");
							}
						}}
					>
						<div>
							<img
								src={cryptoIcons.wallets.png.NeoLine}
								alt={""}
								className={styles.neoIcons}
							/>{" "}
							{intl.formatMessage({ id: "NeoLine Wallet" })}
						</div>
					</div>
					<div
						className={styles.velasPay}
						onClick={() => {
							if (stateButton) {
								pay(blockchain, coin, "neoWallet");
							}
						}}
					>
						<div>
							<img
								src={cryptoIcons.wallets.svg.Neon}
								alt={""}
								className={styles.neoIcons}
							/>{" "}
							{intl.formatMessage({ id: "Neon Wallet" })}
						</div>
					</div>
				</div>
			);
			break;
		default:
			if (blockchain === "NEAR") {
				return (
					<div className={styles.modalVelas}>
						<div
							className={styles.velasPay}
							onClick={() => {
								if (stateButton) {
									pay(blockchain, coin, "NEAR Wallet");
								}
							}}
						>
							<div>
								<img
									src={cryptoIcons.cryptocurrencies.png.NEAR}
									alt={""}
									className={styles.neoIcons}
								/>{" "}
								{intl.formatMessage({ id: "NEAR Wallet" })}
							</div>
						</div>
						<div
							className={styles.velasPay}
							onClick={() => {
								if (stateButton) {
									pay(blockchain, coin, "MyNearWallet");
								}
							}}
						>
							<div>
								<img
									src={cryptoIcons.wallets.svg.MyNearWallet}
									alt={""}
									className={styles.myNearWallet}
								/>{" "}
								MyNearWallet
							</div>
						</div>
					</div>
					// 	<div className={styles.modal}>
					// 	<div
					// 		className={styles.wDiv}
					// 		onClick={() => {
					// 			if (stateButton) {
					// 				pay(blockchain, coin, "NEAR Wallet");
					// 			}
					// 		}}
					// 	>
					// 		<img
					// 			src={cryptoIcons.blockchains.png.NEAR}
					// 			alt={""}
					// 			style={{ marginLeft: "126px" }}
					// 			className={styles.near}
					// 		/>
					// 		<div
					// 			style={{
					// 				marginRight: "100px",
					// 				width: "160px",
					// 			}}
					// 		>
					// 			{intl.formatMessage({ id: "Pay with NEAR" })}
					// 		</div>
					// 	</div>
					// 	</div>
				);
			} else
				return (
					<div className={styles.modal}>
						{/* eslint-disable-next-line eqeqeq */}
						{blockchain !== "NEAR" && blockchain !== "Tron" && (
							<div
								className={styles.wDiv}
								onClick={() => {
									if (stateButton) {
										pay(blockchain, coin);
									}
								}}
							>
								<img src={cryptoIcons.wallets.png.Metamask} alt={""} />
								<div>{intl.formatMessage({ id: "Pay with Metamask" })}</div>
							</div>
						)}
						{/*{(blockchain === 'Tron') && <div className={styles.wDiv} onClick={() => {*/}
						{/*	if (stateButton) {*/}
						{/*		getWallet(blockchain, coin)*/}
						{/*	}*/}
						{/*}}>*/}
						{/*	<img src={iconTronLink} alt={''} style={{ marginLeft: '126px', width: '38px' }} />*/}
						{/*	<div style={{ marginRight: '80px' }}>Pay with TronLink</div>*/}
						{/*</div>}*/}
					</div>
				);
			break;
	}
};
