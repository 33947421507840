import { fetchGetPrices, fetchGetWallet } from "../api/payment";
import { modal } from "../../common/general/ui/Modal";
import { SelectMethodOfPayment } from "../ui/modals/SelectMethodOfPayment/SelectMethodOfPayment";
import {
	convertNameBlockchain,
	convertNameCoin,
	products,
} from "../types/payment";
import React from "react";
import StoreUser from "../../common/user/store/StoreUser";

export const openPaymentModal = async (storeUser: StoreUser) => {
	const resActivePaymentData = await storeUser.activePaymentData();
	const prices = await fetchGetPrices();
	const resFetchGetWallet = (
		await fetchGetWallet(
			resActivePaymentData.blockchain,
			resActivePaymentData.type,
			resActivePaymentData.coin,
			resActivePaymentData.mintOn,
			resActivePaymentData.amount
		)
	).data;
	console.log("resFetchGetWallet", resFetchGetWallet);

	modal.open(
		<SelectMethodOfPayment
			amount={resFetchGetWallet.amount}
			// @ts-ignore
			coin={convertNameCoin[resFetchGetWallet.coin]}
			// @ts-ignore
			blockchain={convertNameBlockchain[resFetchGetWallet.blockchain]}
			address={resFetchGetWallet.wallet}
			history_id={resFetchGetWallet.history_id}
			start={resFetchGetWallet.start}
			block={false}
			// @ts-ignore //todo
			chain={resActivePaymentData.mintOn}
			numberOfProducts={resActivePaymentData.count}
			isOpenAgain={false}
			convert={prices.data}
			product={products.find((el) => resActivePaymentData.type === el.type)!}
		/>,
		{ abilityHide: true, className: "none" }
	);
};
