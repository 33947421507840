import React, { lazy } from "react";
import styles from "src/modules/mainPage/ui/sections/FindRandomSets/FindRandomSets.module.scss";
import TabletTitle from "src/modules/mainPage/assets/img/png/TabletTitle.png";
import flare from "src/modules/mainPage/assets/img/flares/png/Flare2.png";
import MP1 from "src/modules/mainPage/assets/img/png/MP1.png";
import MP2 from "src/modules/mainPage/assets/img/png/MP2.png";
import MP3 from "src/modules/mainPage/assets/img/png/MP3.png";
import cl from "classnames";
import AroundTitle from "src/modules/mainPage/assets/img/sections/plate/png/AroundTitle.png";
import { ButtonJoin } from "src/modules/common/general/ui/buttons/ButtonJoin/ButtonJoin";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useIntl } from "react-intl";

const WrapForButtonBuy = lazy(
	() =>
		import(
			/* webpackChunkName: "WrapForButtonBuy" */ "src/modules/payment/ui/WrapForButtonBuy/WrapForButtonBuy"
		)
);

export const FindRandomSets: React.FC = () => {
	const intl = useIntl();
	const card1 = (
		<div className={styles.card}>
			<div className={styles.cardImage}>
				<LazyLoadComponent>
					<img loading="lazy" src={MP1} alt="" />
				</LazyLoadComponent>
			</div>
			<div className={styles.cardContent}>
				<div className={styles.cardContentTop}>
					<div className={styles.cardContentTitle}>
						{intl.formatMessage({ id: "Mystery Pack" })}
					</div>
					<div className={cl(styles.cardContentRare, styles.rareCommon)}>
						{intl.formatMessage({ id: "Common" })}
					</div>
				</div>
				<div className={styles.cardContentCenter}>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Series" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Searching for Dragon Stone" })}
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Rarity" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Common" })}
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Price" })}
						</div>
						<div className={cl(styles.cardContentRight, styles.price)}>
							$13.99
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Release" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Available now" })}
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Unpacking" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Available now" })}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
	const card2 = (
		<div className={styles.card}>
			<div className={styles.cardImage}>
				<LazyLoadComponent>
					<img loading="lazy" src={MP2} alt="" />
				</LazyLoadComponent>
			</div>
			<div className={styles.cardContent}>
				<div className={styles.cardContentTop}>
					<div className={styles.cardContentTitle}>
						{intl.formatMessage({ id: "Mystery Pack" })}
					</div>
					<div className={cl(styles.cardContentRare, styles.rareRare)}>
						{intl.formatMessage({ id: "Rare" })}
					</div>
				</div>
				<div className={styles.cardContentCenter}>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Series" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Searching for Dragon Stone" })}
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Rarity" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Rare" })}
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Price" })}
						</div>
						<div className={cl(styles.cardContentRight, styles.price)}>
							$49.99
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Release" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Available now" })}
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Unpacking" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Available now" })}
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	const card3 = (
		<div className={styles.card}>
			<div className={styles.cardImage}>
				<LazyLoadComponent>
					<img loading="lazy" src={MP3} alt="" />
				</LazyLoadComponent>
			</div>
			<div className={styles.cardContent}>
				<div className={styles.cardContentTop}>
					<div className={styles.cardContentTitle}>
						{intl.formatMessage({ id: "Mystery Pack" })}
					</div>
					<div className={cl(styles.cardContentRare, styles.rareLegendary)}>
						{intl.formatMessage({ id: "Legendary" })}
					</div>
				</div>
				<div className={styles.cardContentCenter}>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Series" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Searching for Dragon Stone" })}
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Rarity" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Legendary" })}
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Price" })}
						</div>
						<div className={cl(styles.cardContentRight, styles.price)}>
							$99.99
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Release" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Available now" })}
						</div>
					</div>
					<div className={styles.cardContentLine}>
						<div className={styles.cardContentLeft}>
							{intl.formatMessage({ id: "Unpacking" })}
						</div>
						<div className={styles.cardContentRight}>
							{intl.formatMessage({ id: "Available now" })}
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<div className={styles.content} id="FindRandomSets">
			<img loading="lazy" className={styles.flare} src={flare} alt="" />
			<div className={styles.title}>
				<div className={styles.titleSection}>
					{intl.formatMessage({ id: "Find random sets of items in" })} <br />
					{intl.formatMessage({ id: "Mystery Packs" })}
				</div>
			</div>
			<div className={styles.titleSeason}>
				<img loading="lazy" src={TabletTitle} alt="" />
				<div className={styles.titleSeasonText}>
					{intl.formatMessage({ id: "season 1" })}
				</div>
			</div>
			<div className={styles.titleMain}>
				{intl.formatMessage({ id: "SEARCHING FOR DRAGON STONE" })}
			</div>
			<div className={cl(styles.slider, styles.cards)}>
				<Swiper
					mousewheel
					slidesPerView={2}
					loop
					modules={[Navigation, Mousewheel, Pagination, Keyboard]}
					navigation
					pagination={{ clickable: true }}
					className={styles.mySwiper}
					breakpoints={{
						770: {
							slidesPerView: 2,
						},
						100: {
							slidesPerView: 1,
						},
					}}
				>
					<SwiperSlide className={styles.SwiperSlide}>
						<div className={styles.pack}>
							{card1}
							<WrapForButtonBuy product={1}>
								<ButtonJoin>{intl.formatMessage({ id: "Buy a pack" })}</ButtonJoin>
							</WrapForButtonBuy>
						</div>
					</SwiperSlide>
					<SwiperSlide className={styles.SwiperSlide}>
						<div className={styles.pack}>
							{card2}
							<WrapForButtonBuy product={2}>
								<ButtonJoin>{intl.formatMessage({ id: "Buy a pack" })}</ButtonJoin>
							</WrapForButtonBuy>
						</div>
					</SwiperSlide>
					<SwiperSlide className={styles.SwiperSlide}>
						<div className={styles.pack}>
							{card3}
							<WrapForButtonBuy product={3}>
								<ButtonJoin>{intl.formatMessage({ id: "Buy a pack" })}</ButtonJoin>
							</WrapForButtonBuy>
						</div>
					</SwiperSlide>
				</Swiper>
				<div className={styles.slider__arrows}>
					{/* <div onClick={() => swiper?.slideNext()}><SvgArrowSliderL /></div>
        <div onClick={() => swiper?.slidePrev()}><SvgArrowSliderR /></div> */}
				</div>
			</div>
			<div className={cl(styles.cards, styles.listCards)}>
				{card1}
				{card2}
				{card3}
			</div>
			<div className={styles.buttons}>
				<WrapForButtonBuy product={1}>
					<ButtonJoin>{intl.formatMessage({ id: "Buy a pack" })}</ButtonJoin>
				</WrapForButtonBuy>
				<WrapForButtonBuy product={2}>
					<ButtonJoin>{intl.formatMessage({ id: "Buy a pack" })}</ButtonJoin>
				</WrapForButtonBuy>
				<WrapForButtonBuy product={3}>
					<ButtonJoin>{intl.formatMessage({ id: "Buy a pack" })}</ButtonJoin>
				</WrapForButtonBuy>
			</div>

			<div className={styles.bottom}>
				<img
					loading="lazy"
					className={styles.bottomImg}
					src={AroundTitle}
					alt=""
				/>
				{/*<div className={styles.btn}>*/}
				{/*	<WrapForButtonBuy product={0}>*/}
				{/*		<ButtonJoin>{intl.formatMessage({ id: "Learn more" })}</ButtonJoin>*/}
				{/*	</WrapForButtonBuy>*/}
				{/*</div>*/}
			</div>
		</div>
	);
};
