import React from "react";
import styles from "src/modules/mainPage/ui/sections/Roadmap/Roadmap.module.scss";
import RoadmapSteps from "src/modules/mainPage/assets/img/png/RoadmapSteps.png";
import RoadmapStep from "src/modules/mainPage/assets/img/png/RoadmapStep.png";
import flare from "src/modules/mainPage/assets/img/flares/png/Flare3.png";
import cl from "classnames";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useIntl } from "react-intl";

const doneSvg = (
	<svg
		width="62"
		height="62"
		viewBox="0 0 62 62"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle
			cx="31"
			cy="31"
			r="29.5"
			stroke="url(#paint0_linear_896_1320)"
			strokeWidth="3"
		/>
		<path
			d="M40.6596 20.7793L43.3396 22.6593L31.7196 39.4193H29.0396L22.5596 30.3393L25.2396 27.8393L30.3796 32.6393L40.6596 20.7793Z"
			fill="white"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_896_1320"
				x1="30.8491"
				y1="-24.1551"
				x2="30.851"
				y2="64.4137"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#EBB16D" />
				<stop offset="1" stopColor="#D67300" />
			</linearGradient>
		</defs>
	</svg>
);

export const Roadmap: React.FC = () => {
	// const navigate = useNavigate();
	const intl = useIntl();
	return (
		<div className={styles.content} id={"Roadmap"}>
			<div className={styles.title}>
				<div className={styles.titleText}>
					{intl.formatMessage({ id: "Roadmap" })}
				</div>
			</div>
			<img loading="lazy" src={flare} alt="" className={styles.flare} />
			<LazyLoadComponent>
				<div className={styles.roadmapSteps}>
					<img loading="lazy" src={RoadmapSteps} alt="" />
				</div>
			</LazyLoadComponent>
			<div className={styles.roadmapWrapper}>
				<div className={cl(styles.stageWrapper, styles.stageWrapper1)}>
					<div className={cl(styles.stageNumber)}>1</div>
					<div className={cl(styles.stageNumberQ)}>Q3‘22</div>
					<div className={cl(styles.stageTitle)}>
						{intl.formatMessage({ id: "Stage" })} 1
					</div>
					<div className={cl(styles.stageNumberDone)}>{doneSvg}</div>
					<div className={cl(styles.tabs, styles.tabs1)}>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Game concept development" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Start of litepaper creation" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Market research" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Art concept development" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Launch of social media channels" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Game logic development" })}
							</div>
						</div>
					</div>
					<div className={cl(styles.stage, styles.stage1)}></div>
				</div>
				<div className={styles.stepImg}>
					<img loading="lazy" src={RoadmapStep} alt="" />
				</div>
				<div className={cl(styles.stageWrapper, styles.stageWrapper2)}>
					<div className={cl(styles.stageNumber)}>2</div>
					<div className={cl(styles.stageNumberQ)}>Q4 ‘22</div>
					<div className={cl(styles.stageTitle)}>
						{intl.formatMessage({ id: "Stage" })} 2
					</div>
					<div className={cl(styles.stageNumberDone)}>{doneSvg}</div>
					<div className={cl(styles.tabs, styles.tabs2)}>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)} style={{ maxWidth: 350 }}>
								{intl.formatMessage({
									id: "Season 1 preparation: story, scenario, first board (night and day version)",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "Creation of the Season 1 basic set of in-game items",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Code for the generator" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "Creation of all components for generative characters and pets",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "Landing Page design and development",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Community development strategy" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "GTM Strategy" })}
							</div>
						</div>
					</div>
					<div className={cl(styles.stage, styles.stage2)}></div>
				</div>
				<div className={styles.stepImg} style={{ transform: "scale(-1, 1) " }}>
					<img loading="lazy" src={RoadmapStep} alt="" />
				</div>
				<div className={cl(styles.stageWrapper, styles.stageWrapper3)}>
					<div className={cl(styles.stageNumber)}>3</div>
					<div className={cl(styles.stageNumberQ)}>Q1 ‘23</div>
					<div className={cl(styles.stageTitle)}>
						{intl.formatMessage({ id: "Stage" })} 3
					</div>
					<div className={cl(styles.tabs, styles.tabs3)}>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Game engine development" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Game mechanics development" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "NFT Collection development: custom extension NFT standard, generative art, collection page",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Litepaper alpha release" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Start of community development" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "Development of the guild structure",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Blockchain integration" })}
							</div>
						</div>
					</div>
					<div className={cl(styles.stage, styles.stage3)}></div>
				</div>
				<div className={styles.stepImg}>
					<img loading="lazy" src={RoadmapStep} alt="" />
				</div>
				<div className={cl(styles.stageWrapper, styles.stageWrapper4)}>
					<div className={cl(styles.stageNumber)}>4</div>
					<div className={cl(styles.stageNumberQ)}>Q2 ‘23</div>
					<div className={cl(styles.stageTitle)}>
						{intl.formatMessage({ id: "Stage" })} 4
					</div>
					<div className={cl(styles.tabs, styles.tabs4)}>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Alpha game release" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Debugging" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "NFT Collection Launch" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Marketplace Launch" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Game testing by KOLs" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Development of referral program" })}
							</div>
						</div>
					</div>
					<div className={cl(styles.stage, styles.stage4)}></div>
				</div>
				<div className={styles.stepImg} style={{ transform: "scale(-1, 1) " }}>
					<img loading="lazy" src={RoadmapStep} alt="" />
				</div>
				<div className={cl(styles.stageWrapper, styles.stageWrapper5)}>
					<div className={cl(styles.stageNumber)}>5</div>
					<div className={cl(styles.stageNumberQ)}>Q3 ‘23</div>
					<div className={cl(styles.stageTitle)}>
						{intl.formatMessage({ id: "Stage" })} 5
					</div>
					<div className={cl(styles.tabs)}>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Game trailer release" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "In-game tournaments release" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "AMA Sessions Launch" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Fundraising start on launchpads" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "Mobile Version start of development",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "Nintendo Switch version start of development",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Referral program launch" })}
							</div>
						</div>
					</div>
					<div className={cl(styles.stage, styles.stage5)}></div>
				</div>
				<div className={styles.stepImg}>
					<img loading="lazy" src={RoadmapStep} alt="" />
				</div>
				<div className={cl(styles.stageWrapper, styles.stageWrapper6)}>
					<div className={cl(styles.stageNumber)}>6</div>
					<div className={cl(styles.stageNumberQ)}>Q4 ‘23</div>
					<div className={cl(styles.stageTitle)}>
						{intl.formatMessage({ id: "Stage" })} 6
					</div>
					<div className={cl(styles.tabs, styles.tabs4)}>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "KPI Analysis" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "Mobile version continued development",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "Nintendo Switch version continued development",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Interactive board release" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({
									id: "Start of development of the physical board",
								})}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Strategic partnerships" })}
							</div>
						</div>
						<div className={cl(styles.tab)}>
							<div className={styles.dot} />
							<div className={cl(styles.text)}>
								{intl.formatMessage({ id: "Season 2 preparation" })}
							</div>
						</div>
					</div>
					<div className={cl(styles.stage, styles.stage6)}></div>
				</div>
			</div>
		</div>
	);
};
