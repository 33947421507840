import React from "react";
import styles from "./PacksBody.module.scss";
import { products } from "src/modules/payment/types/payment";
import { useIntl } from "react-intl";
import { packDescriptions } from "src/modules/payment/ui/nftsBody/PacksBody/packDescriptions";
import common from "src/modules/payment/assets/img/mysteryPacksIcons/smallCommon.png";
import rare from "src/modules/payment/assets/img/mysteryPacksIcons/smallRare.png";
import legendary from "src/modules/payment/assets/img/mysteryPacksIcons/smallLegendary.png";
import backgroundForPack from "src/modules/payment/assets/img/backgrounds/backgroundForPack.png";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

//@ts-ignore
const PacksBody = ({ typePack, setSelectedPack }) => {
	const intl = useIntl();
	console.log("payment", typePack, setSelectedPack);

	return (
		<div className={styles.body}>
			<div className={styles.subTitle}>
				{intl.formatMessage({ id: "Choose your option" })}
			</div>
			{window.innerWidth > 800 && (
				<div className={styles.wPacks}>
					<div
						onClick={() => {
							setSelectedPack(products[1]);
						}}
					>
						<div
							className={styles.pack}
							style={
								typePack === "pack_common_1"
									? { background: `url(${backgroundForPack}) no-repeat` }
									: {}
							}
						>
							<img src={common} alt={""} />
						</div>
						<div className={styles.namePack}>
							{intl.formatMessage({ id: "common" })}
						</div>
					</div>
					<div
						onClick={() => {
							setSelectedPack(products[2]);
						}}
					>
						<div
							className={styles.pack}
							style={
								typePack === "pack_rare_1"
									? { background: `url(${backgroundForPack}) no-repeat` }
									: {}
							}
						>
							<img src={rare} alt={""} />
						</div>
						<div className={styles.namePack}>
							{intl.formatMessage({ id: "rare" })}
						</div>
					</div>
					<div
						onClick={() => {
							setSelectedPack(products[3]);
						}}
					>
						<div
							className={styles.pack}
							style={
								typePack === "pack_legendary_1"
									? { background: `url(${backgroundForPack}) no-repeat` }
									: {}
							}
						>
							<img src={legendary} alt={""} />
						</div>
						<div className={styles.namePack}>
							{intl.formatMessage({ id: "legendary" })}
						</div>
					</div>
				</div>
			)}
			{window.innerWidth < 800 && (
				<Swiper
					mousewheel
					slidesPerView={1}
					loop
					modules={[Navigation, Mousewheel, Pagination, Keyboard]}
					navigation
					pagination={{ clickable: true }}
					className={styles.wPacks}
				>
					<SwiperSlide
						onClick={() => {
							setSelectedPack(products[1]);
						}}
					>
						<div
							className={styles.pack}
							style={
								typePack === "pack_common_1"
									? { background: `url(${backgroundForPack}) no-repeat` }
									: {}
							}
						>
							<img src={common} alt={""} />
						</div>
						<div className={styles.namePack}>
							{intl.formatMessage({ id: "common" })}
						</div>
					</SwiperSlide>
					<SwiperSlide
						onClick={() => {
							setSelectedPack(products[2]);
						}}
					>
						<div
							className={styles.pack}
							style={
								typePack === "pack_rare_1"
									? { background: `url(${backgroundForPack}) no-repeat` }
									: {}
							}
						>
							<img src={rare} alt={""} />
						</div>
						<div className={styles.namePack}>
							{intl.formatMessage({ id: "rare" })}
						</div>
					</SwiperSlide>
					<SwiperSlide
						onClick={() => {
							setSelectedPack(products[3]);
						}}
					>
						<div
							className={styles.pack}
							style={
								typePack === "pack_legendary_1"
									? { background: `url(${backgroundForPack}) no-repeat` }
									: {}
							}
						>
							<img src={legendary} alt={""} />
						</div>
						<div className={styles.namePack}>
							{intl.formatMessage({ id: "legendary" })}
						</div>
					</SwiperSlide>
				</Swiper>
			)}
			<div className={styles.packDescription}>
				{intl.formatMessage({ id: packDescriptions[typePack] })}
			</div>
		</div>
	);
};

export default PacksBody;
