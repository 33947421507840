import React, { useEffect, useState } from "react";
import styles from "src/modules/payment/ui/modals/SelectMethodOfPayment/SelectMethodOfPayment.module.scss";
import warning from "src/modules/payment/assets/img/png/warning.png";
import copyIcon from "src/modules/payment/assets/img/png/copy.png";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { PaymentButtonWithPaymentLogic } from "src/modules/payment/ui/PaymentButtonWithPaymentLogic/PaymentButtonWithPaymentLogic";
import { SuccessfulPaymentNotification } from "src/modules/payment/ui/modals/SuccessfulPaymentNotification/SuccessfulPaymentNotification";
import { useTimer } from "react-timer-hook";
import { ErrorWentWrongModal } from "src/modules/payment/ui/modals/ErrorWentWrongModal/ErrorWentWrongModal";
import { LoadingPayment } from "src/modules/payment/ui/modals/LoadingPayment/LoadingPayment";
import { QRCodeSVG } from "qrcode.react";
import { useIntl } from "react-intl";
import { BlockPayment } from "src/modules/payment/ui/modals/BlockPayment/BlockPayment";
import { PurchaseStep2 } from "src/modules/payment/ui/modals/PurchaseStep2/PurchaseStep2";
import {
	allPaymentMethods,
	convertChainForFront,
	IHistoryResult,
	IPackPrices,
	IProduct,
	TBlockchainMint,
} from "src/modules/payment/types/payment";
import {
	fetchCheckPayment,
	fetchGetHistoryById,
} from "src/modules/payment/api/payment";
import { checkPayment } from "src/modules/payment/utils/paymentMarket";
import { minuteFormat } from "src/modules/common/general/utils/minuteFormat";
import { roundingUpPrice } from "src/modules/common/general/utils/common";
import { modal } from "src/modules/common/general/ui/Modal";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { fetchGetTimeOnTheServer } from "src/modules/common/general/api/api";
import { objectInLocalStorage } from "../../../../common/general/types/common";

export interface ISelectMethodPaymentParams {
	product: IProduct;
	numberOfProducts: number;
	chain: TBlockchainMint;
	convert: IPackPrices[];

	amount: string;
	coin: string;
	blockchain: string;
	address: string;
	history_id: number;
	start: number;
	block: boolean;
	isOpenAgain: boolean;
}

export const SelectMethodOfPayment: React.FC<ISelectMethodPaymentParams> = ({
	product,
	numberOfProducts,
	chain,
	convert,

	amount,
	coin,
	blockchain,
	address,
	history_id,
	start,
	block,
	isOpenAgain,
}) => {
	const date = new Date(Number(start) + 5 * 59 * 1000);
	const time = Number(date) - Number(new Date());
	const [TimeOut, setTimeOut] = useState();
	const [isOpen, setIsOpen] = useState(block);
	const timer = useTimer({ autoStart: true, expiryTimestamp: date });
	const intl = useIntl();

	useEffect(() => {
		localStorage.removeItem(objectInLocalStorage.addWalletSelector);
	}, []);

	const timeSynchronization = async () => {
		const res = await fetchGetTimeOnTheServer();
		console.log(
			"timeDifferenceForSynchronization\n",
			res.data.timestamp - Math.floor(Date.now() / 1000),
			"diff\n",
			res.data.timestamp,
			"server\n",
			Math.floor(Date.now() / 1000),
			"client"
		);

		return res.data.timestamp - Math.floor(Date.now() / 1000);
	};

	useEffect(() => {
		timeSynchronization().then((res) => {
			const date = new Date(Number(start) + 5 * 59 * 1000 - res * 1000);
			timer.restart(date, true);
		});
		console.log(coin, blockchain);
		const interval: any = setTimeout(async () => {
			modal.open(<LoadingPayment />);
			const intervalAfterPayment: any = setInterval(async () => {
				const res: IHistoryResult = (await fetchGetHistoryById(history_id))
					.data;
				if (
					Number(res.inUseEnd) > 0 &&
					res.result &&
					convertChainForFront.get(res.mintOn)
				) {
					modal.open(
						<SuccessfulPaymentNotification
							chainMint={convertChainForFront.get(res.mintOn)!}
							typePack={res.type}
							numberOfProducts={res.count}
						/>,
						{ className: "none ", abilityHide: true }
					);
					clearInterval(intervalAfterPayment);
					clearTimeout(timeOutWarning);

					clearTimeout(interval);
					clearTimeout(TimeOut);
				}
				if (Number(res.inUseEnd) > 0 && !res.result) {
					clearTimeout(timeOutWarning);
					clearInterval(intervalAfterPayment);

					clearTimeout(interval);
					clearTimeout(TimeOut);

					modal.close();
					toast.error("payment was not received");
				}
			}, 5000);
			const timeOutWarning: any = setTimeout(() => {
				modal.open(<ErrorWentWrongModal />, {
					className: "none",
					abilityHide: true,
				});
				clearInterval(intervalAfterPayment);
				clearTimeout(timeOutWarning);
			}, time + 70000);
		}, time);
		setTimeOut(interval);
	}, []);

	const onClickBack = async () => {
		clearTimeout(TimeOut);
		await fetchCheckPayment(history_id, 1);
		modal.open(
			<PurchaseStep2
				numberOfProducts={numberOfProducts}
				product={product}
				chain={chain}
				convert={convert}
				paymentMethods={allPaymentMethods.get(chain)!}
			/>,
			{ abilityHide: true, className: "none" }
		);
	};

	const onClickCancel = async () => {
		clearTimeout(TimeOut);
		await fetchCheckPayment(history_id, 1);
		modal.close();
	};

	const onClickComplete = async () => {
		clearTimeout(TimeOut);
		await checkPayment(history_id, 0, intl);
		setIsOpen(true);
	};

	return (
		<div className={styles.modal}>
			<LazyLoadComponent>
				{isOpen && <BlockPayment />}
				<div className={styles.wrap}>
					<div className={styles.title}>
						{intl.formatMessage({ id: "Pay with" })} {coin?.toUpperCase()}
					</div>
					<div className={styles.informQR}>
						<div>{intl.formatMessage({ id: "Scan this QR code to pay" })}:</div>
						<div className={styles.informSession}>
							{intl.formatMessage({ id: "Session will end in" })}{" "}
							{minuteFormat(timer.minutes)} : {minuteFormat(timer.seconds)}
						</div>
					</div>
					<div className={styles.areaForQrCode}>
						<div className={styles.wrapperQrCode}>
							<QRCodeSVG value={address} size={230} />
						</div>
					</div>
					<div className={styles.warningWindow}>
						<img src={warning} alt={""} />
						<div className={styles.descriptionsWarning}>
							{intl.formatMessage({ id: "Only send " })}
							{coin} {intl.formatMessage({ id: "using" })} {blockchain}{" "}
							{intl.formatMessage({ id: "network" })}
						</div>
					</div>
					{!(blockchain === "Tron") ? (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifySelf: "center",
							}}
						>
							<div className={styles.lineOr}></div>
							<div
								style={{ marginLeft: "18px", marginRight: "18px" }}
								className={styles.textOr}
							>
								{intl.formatMessage({ id: "or" })}
							</div>
							<div className={styles.lineOr}></div>
						</div>
					) : (
						<div></div>
					)}
					{!(blockchain === "Tron") && (
						<div className={styles.areaSelectPayment}>
							<LazyLoadComponent>
								<PaymentButtonWithPaymentLogic
									coin={coin}
									blockchain={blockchain}
									typePaymentSystem={coin}
									TimeOut={TimeOut}
									history_id={history_id}
									amountPrice={amount}
									walletPay={address}
								/>
							</LazyLoadComponent>
						</div>
					)}
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifySelf: "center",
						}}
					>
						<div className={styles.lineOr}></div>
						<div
							style={{ marginLeft: "18px", marginRight: "18px" }}
							className={styles.textOr}
						>
							{intl.formatMessage({ id: "or" })}
						</div>
						<div className={styles.lineOr}></div>
					</div>
					<div className={styles.informAboutPay}>
						<div className={styles.textPay}>
							{intl.formatMessage({ id: "Send" })} {coin}{" "}
							{intl.formatMessage({ id: "to this address:" })}
						</div>
						<div className={styles.textPay}>{address}</div>
						<div
							className={styles.copyButton}
							onClick={() => {
								copy(address);
								toast.success("Address copied");
							}}
						>
							<img src={copyIcon} alt={""} />{" "}
							{intl.formatMessage({ id: "copy" })}
						</div>
						<div className={styles.textPay}>
							{intl.formatMessage({ id: "Amount to pay" })}:
						</div>
						<div className={styles.textPay}>
							{coin === "NEO"
								? Math.ceil(Number(amount))
								: roundingUpPrice(Number(amount))}{" "}
							{coin}
						</div>
						<div
							className={styles.copyButton}
							onClick={() => {
								copy(String(amount));
								toast.success("Amount copied");
							}}
						>
							<img src={copyIcon} alt={""} />{" "}
							{intl.formatMessage({ id: "copy" })}
						</div>
					</div>
					<div className={styles.line}></div>
					<div className={styles.bottomPart}>
						{isOpenAgain && (
							<>
								<div className={styles.buttonBack} onClick={onClickBack}>
									{intl.formatMessage({ id: "Back" })}
								</div>
							</>
						)}
						{!isOpenAgain && (
							<div className={styles.buttonBack} onClick={onClickCancel}>
								{intl.formatMessage({ id: "Cancel" })}
							</div>
						)}
						<div className={styles.buttonPurchase} onClick={onClickComplete}>
							{intl.formatMessage({ id: "Complete" })}
						</div>
					</div>
				</div>
			</LazyLoadComponent>
		</div>
	);
};
