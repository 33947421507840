import { AxiosResponse } from "axios";

import agent from "src/app/api/axios";
import { IAuth } from "src/modules/common/general/types/common";

export const fetchAuthLogin = (
	loginType: IAuth.AuthTypes,
	id: string,
	password?: string
): Promise<AxiosResponse<IAuth.Tokens>> => {
	return agent.post("/auth/login", {
		login_type: loginType,
		id,
		password,
	});
};

export const fetchAuthLogout = (): Promise<AxiosResponse<any>> => {
	return agent.post("/auth/logout");
};

export const fetchAuthRefresh = (
	refreshToken: string,
): Promise<AxiosResponse<any>> => {
	return agent.post(`/auth/refresh`, {
		refreshToken: refreshToken,
	});
};

export const fetchGetNonce = (
	loginType: IAuth.AuthTypes,
	address: string,
): Promise<AxiosResponse<IAuth.Tokens | any>> => {
	return agent.get(
		`/auth/get_nonce?login_type=${loginType}&address=${address}`,
	);
};

export const fetchAuthLoginMetaMask = (
	loginType: IAuth.AuthTypes,
	address: string,
	signature: string,
): Promise<AxiosResponse<IAuth.Tokens | any>> => {
	return agent.post("/auth/login_metamask", {
		login_type: loginType,
		address,
		signature,
	});
};