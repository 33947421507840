import React from "react";

export const SvgLogoTwitterGrad: React.FC = () => {
	return (
		<svg
			width="30"
			height="24"
			viewBox="0 0 30 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M30 2.83778C28.8867 3.32228 27.6973 3.63951 26.4551 3.79524C27.7324 3.04542 28.7051 1.86878 29.1621 0.449892C27.9727 1.1478 26.6602 1.63807 25.2598 1.91492C24.1348 0.732516 22.5234 0 20.7715 0C17.3672 0 14.625 2.72242 14.625 6.05624C14.625 6.53497 14.666 6.99639 14.7656 7.43475C9.65039 7.1925 5.12695 4.77577 2.08594 1.10166C1.55273 2.00721 1.24219 3.04542 1.24219 4.16438C1.24219 6.26388 2.33789 8.12112 3.97266 9.19394C2.98242 9.17664 2.01562 8.89402 1.19531 8.44989C1.19531 8.4672 1.19531 8.49027 1.19531 8.51911C1.19531 11.4607 3.32812 13.9063 6.12305 14.4658C5.625 14.5984 5.07422 14.6676 4.51172 14.6676C4.11914 14.6676 3.7207 14.6446 3.3457 14.5638C4.14258 16.9632 6.4043 18.7224 9.09375 18.7801C7.00195 20.3893 4.3418 21.3641 1.4707 21.3641C0.966797 21.3641 0.480469 21.341 0 21.2833C2.72461 23.0137 5.95312 24 9.43359 24C20.7539 24 26.9414 14.7715 26.9414 6.77145C26.9414 6.50613 26.9297 6.24658 26.918 5.98702C28.1367 5.13338 29.1621 4.06633 30 2.83778Z"
				fill="url(#paint0_linear_768_766)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_768_766"
					x1="15.0955"
					y1="-3.52941"
					x2="15.0954"
					y2="29.6471"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EBB16D" />
					<stop offset="1" stopColor="#E7E4E2" />
				</linearGradient>
			</defs>
		</svg>
	);
};
