import React, { lazy, useContext, useRef, useState } from "react";
import styles from "src/modules/common/general/ui/Header/Header.module.scss";
import logo from "src/modules/common/general/assets/img/common/png/Logo.svg";
import logoMobile from "src/modules/common/general/assets/img/common/png/LogoMobile@2x.png";
import headerImgBg from "src/modules/mainPage/assets/img/png/Header.png";
import { ButtonJoin } from "src/modules/common/general/ui/buttons/ButtonJoin/ButtonJoin";
import cl from "classnames";
import { useIntl } from "react-intl";
import { Context } from "src/index";
import { DropdownMenuLanguages } from "src/modules/common/general/ui/DropdownMenuLanguages/DropdownMenuLanguages";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const WrapForButtonBuy = lazy(
	() =>
		import(
			/* webpackChunkName: "WrapForButtonBuy" */ "src/modules/payment/ui/WrapForButtonBuy/WrapForButtonBuy"
		)
);

//somewhere in your app/component

//somewhere in your app/component

const menuIconSvg = (
	<svg
		width="34"
		height="34"
		viewBox="0 0 34 34"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.08301 9.91669H26.9163"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M7.08301 17H26.9163"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M7.08301 24.0833H26.9163"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
);
const menuCloseIconSvg = (
	<svg
		width="21"
		height="21"
		viewBox="0 0 21 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.3125 19.6875L19.6875 1.3125M19.6875 19.6875L1.3125 1.3125"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const Header: React.FC = () => {
	// const navigate = useNavigate();
	const [isOpenSelectLang, setIsOpenSelectLang] = useState(false);
	const [isOpenSelectLangMobile, setIsOpenSelectLangMobile] = useState(false);
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const navigate = useNavigate();
	const intl = useIntl();
	const { languages } = useContext(Context);
	const rootElement = useRef<any>(null);

	document.addEventListener("click", (e) => {
		if (
			rootElement.current &&
			!rootElement.current.contains(e.target) &&
			isOpenSelectLangMobile === true
		) {
			setIsOpenSelectLangMobile(false);
		}
	});

	return (
		<header className={styles.header}>
			<img className={styles.headerImg} src={headerImgBg} alt="" />
			<div className={styles.content}>
				<div
					className={styles.logo}
					onClick={() => {
						navigate("/");
					}}
				>
					<img srcSet={`${logoMobile} 2x`} src={logo} alt="" />
				</div>
				<div className={styles.tabs}>
					<div
						className={styles.tab}
						onClick={() => {
							navigate("/");
							window.scrollTo({ behavior: "smooth", top: 0 });
						}}
					>
						{intl.formatMessage({ id: "Gameplay" })}
					</div>
					<HashLink className={styles.tab} smooth to={"/#FindRandomSets"}>
						{intl.formatMessage({ id: "Mystery Packs" })}
					</HashLink>
					<HashLink className={styles.tab} smooth to={"/#PlayToCollect"}>
						{intl.formatMessage({ id: "Play-to-Collect" })}
					</HashLink>
					<HashLink className={styles.tab} smooth to={"/#Roadmap"}>
						{intl.formatMessage({ id: "Roadmap" })}
					</HashLink>
					<HashLink className={styles.tab} to={"/check-nft"}>
						{intl.formatMessage({ id: "My NFTs" })}
					</HashLink>
				</div>
				<div className={styles.WselectLanguage}>
					<div
						className={styles.selectLanguage}
						onClick={() => {
							setIsOpenSelectLang(!isOpenSelectLang);
						}}
					>
						<div>{languages.language}</div>
					</div>
					<DropdownMenuLanguages stateModal={isOpenSelectLang} />
				</div>

				<div className={styles.btn} onClick={()=>window.open("https://game.pixudi.com/")}>
						<ButtonJoin>
							{intl.formatMessage({ id: "Play Now!" })}
						</ButtonJoin>
				</div>
				<div
					className={styles.menuIcon}
					onClick={() => {
						setIsOpenMenu(!isOpenMenu);
						setIsOpenSelectLang(false);
					}}
				>
					{isOpenMenu ? menuCloseIconSvg : menuIconSvg}
				</div>
				{
					<div className={cl(styles.menu, { [styles.isOpenMenu]: isOpenMenu })}>
						<div className={styles.tabsMobile}>
							<div
								className={styles.tab}
								onClick={() => {
									navigate("/");
									window.scrollTo({ behavior: "smooth", top: 0 });
									setIsOpenMenu(!isOpenMenu);
								}}
							>
								{intl.formatMessage({ id: "Gameplay" })}
							</div>
							<HashLink className={styles.tab} smooth to={"/#FindRandomSets"}>
								{intl.formatMessage({ id: "Mystery Packs" })}
							</HashLink>
							<HashLink className={styles.tab} smooth to={"/#PlayToCollect"}>
								{intl.formatMessage({ id: "Play-to-Collect" })}
							</HashLink>
							<HashLink className={styles.tab} smooth to={"/#Roadmap"}>
								{intl.formatMessage({ id: "Roadmap" })}
							</HashLink>
							<HashLink className={styles.tab} to={"/check-nft"}>
								{intl.formatMessage({ id: "My NFTs" })}
							</HashLink>
							<div ref={rootElement} className={styles.WselectLanguage}>
								<div
									className={styles.selectLanguage}
									onClick={() => {
										setIsOpenSelectLangMobile(!isOpenSelectLangMobile);
									}}
								>
									<div>{intl.formatMessage({ id: "en" })}</div>
								</div>
								<DropdownMenuLanguages stateModal={isOpenSelectLangMobile} />
							</div>
							{/* <div className={styles.tab} onClick={() => {
								languages.changeLanguage(languages.language === 'en' ? 'ko' : 'en')
							}}>
								{intl.formatMessage({ id: String(languages.language) })}
							</div> */}
						</div>
						<div className={styles.btnMobile} onClick={()=>window.open("https://game.pixudi.com/")}>
								<ButtonJoin>
									{intl.formatMessage({ id: "Play Now!" })}
								</ButtonJoin>
						</div>
					</div>
				}
			</div>
		</header>
	);
};
