import React from "react";
import styles from "src/modules/payment/ui/modals/SuccessfulPaymentNotification/SuccessfulPaymentNotification.module.scss";
import cross from "src/modules/payment/assets/img/svg/cross.svg";
import Telegram from "src/modules/payment/assets/img/socialNetworkIcon/telegramIcon.png";
import Twitter from "src/modules/payment/assets/img/socialNetworkIcon/twitterIcon.png";
import Discord from "src/modules/payment/assets/img/socialNetworkIcon/discordIcon.png";
import Linkdln from "src/modules/payment/assets/img/socialNetworkIcon/linkedlnIcon.png";
import { useIntl } from "react-intl";
import {
	blockchains,
	products,
	TBlockchainMint,
	TProduct,
} from "src/modules/payment/types/payment";
import { modal } from "src/modules/common/general/ui/Modal";

interface ISuccessfulPaymentNotificationParams {
	chainMint: TBlockchainMint;
	numberOfProducts: number;
	typePack: TProduct;
}

export const SuccessfulPaymentNotification: React.FC<
	ISuccessfulPaymentNotificationParams
> = ({ chainMint, numberOfProducts, typePack }) => {
	const intl = useIntl();
	const product = products.find((el) => el.type === typePack);

	return (
		<div className={styles.modal}>
			<div
				className={styles.cross}
				onClick={() => {
					modal.close();
				}}
			>
				<img src={cross} alt={""} />
			</div>
			{/* eslint-disable-next-line eqeqeq */}
			{numberOfProducts == 1 && (
				<div className={styles.title}>
					{intl.formatMessage({
						id: "Congratulations! You have successfully purchased",
					})}{" "}
					{numberOfProducts} {intl.formatMessage({ id: product?.name })}
				</div>
			)}
			{numberOfProducts > 1 && (
				<div className={styles.title}>
					{intl.formatMessage({
						id: "Congratulations! You have successfully purchased",
					})}{" "}
					{numberOfProducts} {intl.formatMessage({ id: product?.name + "s" })}{" "}
				</div>
			)}
			<div className={styles.area}>
				<div className={styles.leftSide}>
					<img
						src={product?.bigIcon}
						alt={""}
						className={styles.imgPackCommon}
					/>
					<div className={styles.blockchain}>
						<img src={blockchains[chainMint]} alt={""} width={38} />
						{intl.formatMessage({ id: "Minted on" })} {chainMint}{" "}
						{intl.formatMessage({ id: "Blockchain" })}
					</div>
				</div>
				<div>
					{product?.bodyInSuccessfullyWindow}
					<div className={styles.socialNetwork}>
						<div
							className={styles.icon}
							onClick={() => {
								window.open("https://t.me/pixudi", "_blank");
							}}
						>
							<img src={Telegram} className={styles.icon} alt={""} />
						</div>
						<div
							className={styles.icon}
							onClick={() => {
								window.open("https://twitter.com/thepixudi", "_blank");
							}}
						>
							<img src={Twitter} className={styles.icon} alt={""} />
						</div>
						<div
							className={styles.icon}
							onClick={() => {
								window.open("https://discord.gg/W4QGHh9FRX", "_blank");
							}}
						>
							<img src={Discord} className={styles.icon} alt={""} />
						</div>
						<div
							className={styles.icon}
							onClick={() => {
								window.open(
									"https://www.linkedin.com/company/pixudi/",
									"_blank"
								);
							}}
						>
							<img src={Linkdln} className={styles.icon} alt={""} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
