import React, { lazy } from "react";
import styles from "src/modules/mainPage/ui/sections/3TokenSystem/TokenSystem.module.scss";
import TabletTitle from "src/modules/mainPage/assets/img/png/TabletTitle2.png";
import Plate from "src/modules/mainPage/assets/img/png/PlateV2.png";
import coin from "src/modules/mainPage/assets/img/png/Coin.png";
import crystal from "src/modules/mainPage/assets/img/png/Crystal.png";
import crystal2 from "src/modules/mainPage/assets/img/png/CrystalV2.png";
import cl from "classnames";
import { ButtonJoin } from "src/modules/common/general/ui/buttons/ButtonJoin/ButtonJoin";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useIntl } from "react-intl";
import rowsForButtonJoin from "src/modules/mainPage/assets/img/png/rowsForButtonJoin.png";

const WrapForButtonBuy = lazy(
	() =>
		import(
			/* webpackChunkName: "WrapForButtonBuy" */ "src/modules/payment/ui/WrapForButtonBuy/WrapForButtonBuy"
		)
);

export const TokenSystem: React.FC = () => {
	const intl = useIntl();
	return (
		<div className={styles.content} id={"TokenSystem"}>
			<div className={styles.title}>
				<div className={styles.titleSeason}>
					<img loading="lazy" src={TabletTitle} alt="" />
					<div className={styles.titleSeasonText}>
						{intl.formatMessage({ id: "3-token system" })}
					</div>
				</div>
			</div>
			<div className={styles.center}>
				<div className={styles.plate}>
					<div className={styles.plateTitle}>
						{intl.formatMessage({ id: "ingems" })}
					</div>
					<div className={styles.plateText}>
						{intl.formatMessage({
							id: "INGEM is an off-chain currency with unlimited supply. It is used for purchasing in-game items, pet supplies, skins, raising your pets, etc. INGEMS are earned by completing quests, winning in games, playing mini games, and other ways.",
						})}
					</div>
					<img loading="lazy" className={styles.plateImg} src={Plate} alt="" />
					<img
						loading="lazy"
						className={styles.bottomImg1}
						src={crystal}
						alt=""
					/>
				</div>
				<div className={cl(styles.plate, styles.plateCenter)}>
					<div className={styles.plateTitle}>
						{intl.formatMessage({ id: "$XUDI token" })}
					</div>
					<div className={cl(styles.plateText, styles.plateText2)}>
						{intl.formatMessage({
							id: "$XUDI token is the native in-game currency powering Pixudi. It can be used in many ways: buying game items on the marketplace, paying entrance fee in paid games, trading on the flea market, buying supplies for your pet, etc.",
						})}{" "}
					</div>
					<img
						loading="lazy"
						className={cl(styles.plateImg, styles.plateImgCenter)}
						width={601}
						src={Plate}
						alt=""
					/>
					<img loading="lazy" className={styles.bottomImg2} src={coin} alt="" />
				</div>
				<div className={styles.plate}>
					<div className={styles.plateTitle}>
						{intl.formatMessage({ id: "shards" })}
					</div>
					<div className={cl(styles.plateText, styles.plateText3)}>
						{intl.formatMessage({
							id: "SHARD is an off-chain currency which only exists on the board. They are earned and spent during the game and their balance is reset each time the game is over.",
						})}{" "}
					</div>
					<img loading="lazy" className={styles.plateImg} src={Plate} alt="" />
					<img
						loading="lazy"
						className={styles.bottomImg3}
						src={crystal2}
						alt=""
					/>
				</div>
			</div>
			<div className={cl(styles.slider, styles.cards)}>
				<Swiper
					mousewheel
					slidesPerView={2}
					loop
					modules={[Navigation, Mousewheel, Pagination, Keyboard]}
					navigation
					pagination={{ clickable: true }}
					className={styles.mySwiper}
					breakpoints={{
						870: {
							slidesPerView: 2,
						},
						100: {
							slidesPerView: 1,
						},
					}}
				>
					<SwiperSlide className={styles.SwiperSlide}>
						<div className={styles.plate}>
							<div className={styles.plateTitle}>
								{intl.formatMessage({ id: "ingems" })}
							</div>
							<div className={styles.plateText}>
								{intl.formatMessage({
									id: "INGEM is an off-chain currency with unlimited supply. It is used for purchasing in-game items, pet supplies, skins, raising your pets, etc. INGEMS are earned by completing quests, winning in games, playing mini games, and other ways.",
								})}
							</div>
							<LazyLoadComponent>
								<img
									loading="lazy"
									className={styles.plateImg}
									src={Plate}
									alt=""
								/>
								<img
									loading="lazy"
									className={styles.bottomImg1}
									src={crystal}
									alt=""
								/>
							</LazyLoadComponent>
						</div>
					</SwiperSlide>
					<SwiperSlide className={styles.SwiperSlide}>
						<div className={cl(styles.plate)}>
							<div className={styles.plateTitle}>
								{intl.formatMessage({ id: "$XUDI token" })}
							</div>
							<div className={cl(styles.plateText, styles.plateText2)}>
								{intl.formatMessage({
									id: "$XUDI token is the native in-game currency powering Pixudi. It can be used in many ways: buying game items on the marketplace, paying entrance fee in paid games, trading on the flea market, buying supplies for your pet, etc.",
								})}{" "}
							</div>
							<LazyLoadComponent>
								<img
									loading="lazy"
									className={cl(styles.plateImg, styles.plateImgCenter)}
									src={Plate}
									alt=""
								/>
								<img
									loading="lazy"
									className={styles.bottomImg2}
									src={coin}
									alt=""
								/>
							</LazyLoadComponent>
						</div>
					</SwiperSlide>
					<SwiperSlide className={styles.SwiperSlide}>
						<div className={styles.plate}>
							<div className={styles.plateTitle}>
								{intl.formatMessage({ id: "shards" })}
							</div>
							<div className={cl(styles.plateText, styles.plateText3)}>
								{intl.formatMessage({
									id: "SHARD is an off-chain currency which only exists on the board. They are earned and spent during the game and their balance is reset each time the game is over.",
								})}{" "}
							</div>
							<LazyLoadComponent>
								<img
									loading="lazy"
									className={styles.plateImg}
									src={Plate}
									alt=""
								/>
								<img
									loading="lazy"
									className={styles.bottomImg3}
									src={crystal2}
									alt=""
								/>
							</LazyLoadComponent>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
			<div className={styles.btn}>
				<WrapForButtonBuy product={2}>
					<ButtonJoin>
						{intl.formatMessage({ id: "Buy Packs" })}
					</ButtonJoin>
				</WrapForButtonBuy>
				<img
					loading="lazy"
					className={styles.btnFirework}
					src={rowsForButtonJoin}
					alt=""
				/>
			</div>
		</div>
	);
};
