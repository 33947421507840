import { AxiosResponse } from "axios";
import agent from "../../../../app/api/axios";
import { IBlockchain } from "../types/common";

export const fetchGetPrices = async (): Promise<AxiosResponse<any>> => {
	return agent.get(`/payment/get_prices`);
};

export const fetchGetNews = (): Promise<AxiosResponse<any>> => {
	return agent.get(`/general/news`);
};

export const fetchGetLeaderboard = async (): Promise<AxiosResponse<any>> => {
	return await agent.get(`/profile/get_leaderboard`);
};

export const fetchGetCollections = (
	type: string
): Promise<AxiosResponse<any>> => {
	return agent.get(`/general/collections?type=${type}`);
};

export const fetchGetTimeOnTheServer = (): Promise<
	AxiosResponse<{ timestamp: number }>
> => {
	return agent.get("/general/time");
};

export const fetchGetBlockchains = (): Promise<
	AxiosResponse<{ blockchains: IBlockchain[] }>
> => {
	return agent.get(`/admin/get_blockchains`);
};

export const fetchCheckAppPassword = (
	password: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/admin/check_common_password`, {
		password: password,
	});
};

export const fetchGetState = (): Promise<AxiosResponse<any>> => {
	return agent.get(`/admin/get_state`);
};

export const fetchOpenBox = (
	blockchain: string,
	type: string,
	nftId: number
): Promise<AxiosResponse<any>> => {
	return agent.post(`/nfts/open_box`, {
		blockchain: blockchain,
		type: type,
		nftId: nftId,
	});
};

export const fetchCheckBoxes = (
	boxes: { collectionId: number; nftId: string }[]
): Promise<AxiosResponse<any>> => {
	return agent.post(`/nfts/check_boxes`, {
		boxes: boxes,
	});
};

export const fetchExchangeWhitelist = (
	blockchain: string,
	type: string,
	nftId: number
): Promise<AxiosResponse<any>> => {
	return agent.post(`/nfts/exchange_whitelist`, {
		blockchain: blockchain,
		type: type,
		nftId: nftId,
	});
};
