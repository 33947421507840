import React from "react";
import styles from "src/modules/mainPage/ui/sections/HaveAnyQuestions/HaveAnyQuestions.module.scss";
import TabletTitle from "src/modules/mainPage/assets/img/png/TabletTitle3.png";
import Garland from "src/modules/mainPage/assets/img/png/Garland.png";
import { SocialNetworkGrad } from "src/modules/mainPage/ui/SocialNetworkGrad/SocialNetworkGrad";
import { useIntl } from "react-intl";
import { LazyLoadComponent } from "react-lazy-load-image-component";

export const HaveAnyQuestions: React.FC = () => {
	// const navigate = useNavigate();
	const intl = useIntl();
	return (
		<div className={styles.content} id="HaveAnyQuestions">
			<LazyLoadComponent>
				<div className={styles.title}>
					<div className={styles.titleSeason}>
						<img loading="lazy" src={TabletTitle} alt="" />
						<div className={styles.titleSeasonText}>
							<div className={styles.textMain}>
								{intl.formatMessage({ id: "have any questions?" })}
							</div>
							<div className={styles.textSubMain}>
								{intl.formatMessage({ id: "Feel free to to contact us" })}
							</div>
							<div className={styles.socialNetwork}>
								<SocialNetworkGrad />
							</div>
						</div>
					</div>
				</div>

				<img
					loading="lazy"
					className={styles.garlandLeft}
					src={Garland}
					alt=""
				/>
				<img
					loading="lazy"
					className={styles.garlandRight}
					src={Garland}
					alt=""
				/>
			</LazyLoadComponent>
		</div>
	);
};
