export const logger = (function () {
	let oldConsoleLog: any = null;
	let pub = {
		disableLogger: () => {},
		enableLogger: () => {},
	};

	pub.enableLogger = function enableLogger() {
		if (oldConsoleLog == null) return;

		window["console"]["log"] = oldConsoleLog;
	};

	pub.disableLogger = function disableLogger() {
		// @ts-ignore
		oldConsoleLog = console.log;
		window["console"]["log"] = function () {};
	};

	return pub;
})();
