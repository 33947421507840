import React from "react";

export const SvgLogoMailGrad: React.FC = () => {
	return (
		<svg
			width="30"
			height="24"
			viewBox="0 0 30 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 24C2.175 24 1.469 23.7065 0.882 23.1195C0.294 22.5315 0 21.825 0 21V3C0 2.175 0.294 1.469 0.882 0.882C1.469 0.294 2.175 0 3 0H27C27.825 0 28.5315 0.294 29.1195 0.882C29.7065 1.469 30 2.175 30 3V21C30 21.825 29.7065 22.5315 29.1195 23.1195C28.5315 23.7065 27.825 24 27 24H3ZM15 13.2375C15.125 13.2375 15.256 13.2185 15.393 13.1805C15.531 13.1435 15.6625 13.0875 15.7875 13.0125L26.4 6.375C26.6 6.25 26.75 6.094 26.85 5.907C26.95 5.719 27 5.5125 27 5.2875C27 4.7875 26.7875 4.4125 26.3625 4.1625C25.9375 3.9125 25.5 3.925 25.05 4.2L15 10.5L4.95 4.2C4.5 3.925 4.0625 3.9185 3.6375 4.1805C3.2125 4.4435 3 4.8125 3 5.2875C3 5.5375 3.05 5.756 3.15 5.943C3.25 6.131 3.4 6.275 3.6 6.375L14.2125 13.0125C14.3375 13.0875 14.469 13.1435 14.607 13.1805C14.744 13.2185 14.875 13.2375 15 13.2375Z"
				fill="url(#paint0_linear_768_770)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_768_770"
					x1="15.0955"
					y1="-3.52941"
					x2="15.0954"
					y2="29.6471"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EBB16D" />
					<stop offset="1" stopColor="#E7E4E2" />
				</linearGradient>
			</defs>
		</svg>
	);
};
