import React from "react";
import styles from "src/modules/mainPage/ui/SocialNetworkGrad/SocialNetworkGrad.module.scss";
import { SvgLogoDiscordGrad } from "src/modules/mainPage/assets/img/svg/SvgLogoDiscordGrad";
import { SvgLogoInGrad } from "src/modules/mainPage/assets/img/svg/SvgLogoInGrad";
import { SvgLogoMailGrad } from "src/modules/mainPage/assets/img/svg/SvgLogoMailGrad";
import { SvgLogoTelegramGrad } from "src/modules/mainPage/assets/img/svg/SvgLogoTelegramGrad";
import { SvgLogoTwitterGrad } from "src/modules/mainPage/assets/img/svg/SvgLogoTwitterGrad";

export const SocialNetworkGrad: React.FC = () => {
	return (
		<div className={styles.icons}>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("mailto:welcome@pixudi.com?subject=Pixudi", "_blank");
				}}
			>
				<SvgLogoMailGrad />
			</div>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("https://t.me/pixudi", "_blank");
				}}
			>
				{<SvgLogoTelegramGrad />}
			</div>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("https://twitter.com/thepixudi", "_blank");
				}}
			>
				{<SvgLogoTwitterGrad />}
			</div>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("https://discord.gg/W4QGHh9FRX", "_blank");
				}}
			>
				{<SvgLogoDiscordGrad />}
			</div>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("https://www.linkedin.com/company/pixudi/", "_blank");
				}}
			>
				{<SvgLogoInGrad />}
			</div>
		</div>
	);
};
