import { IAuth } from "../../common/general/types/common";
import { toast } from "react-toastify";
import StoreUser from "../../common/user/store/StoreUser";
import { IntlShape } from "react-intl";
import { login } from "./auth/login";

export const connectWalletInPayment = async (
	walletType:any,
	walletId:any,
	storeUser: StoreUser,
	intl: IntlShape
) => {
	try {
		let res:any = await storeUser.setWalletFetch(walletType, walletId);
		if (res.data.alreadyExist) {
			await login(walletType, walletId.toLowerCase(), storeUser);
		} else {
			if (res.data.result) {
				storeUser.setProfileData();
			} else {
				await login(walletType, walletId.toLowerCase(), storeUser);
			}
		}
	} catch (error: any) {
		await login(walletType, walletId.toLowerCase(), storeUser);
		console.error(intl.formatMessage({ id: "Something went wrong" }));
	}
};
