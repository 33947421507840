import React from "react";
import styles from "./WhitelistBodySuccess.module.scss";
import { useIntl } from "react-intl";
import { modal } from "src/modules/common/general/ui/Modal";
import { HashLink } from "react-router-hash-link";

const WhitelistBodySuccess = () => {
	const intl = useIntl();

	return (
		<>
			<p className={styles.paragraph}>
				{intl.formatMessage({
					id: "You can currently check it in the Inventory on ",
				})}
				<HashLink
					to={"/check-nft"}
					className={styles.checkNft}
					onClick={() => {
						window.scrollTo(0, -200);
						modal.close();
					}}
				>
					{intl.formatMessage({ id: "My NFTs page" })}
				</HashLink>
			</p>
			<p className={styles.paragraph}>
				{intl.formatMessage({
					id: "You will be able to unpack your Mystery Pack and see what’s inside later.",
				})}
			</p>
			<p className={styles.paragraph}>
				{intl.formatMessage({
					id: "Please keep in mind that you will be able to sell your Mystery Pack on the Flea Market once it’s available. If you open your pack by that date, you will be able to sell each item you found inside separately.",
				})}
			</p>
			<p className={styles.paragraph}>
				{intl.formatMessage({ id: "Stay in touch and follow our news!" })}
			</p>
		</>
	);
};

export default WhitelistBodySuccess;
