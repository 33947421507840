import { ConnectConfig } from "near-api-js";

import { ENearEnvironment } from "src/modules/common/general/utils/crypto/near/near.environment";

export const getConfig = (env: ENearEnvironment): ConnectConfig => {
	switch (env) {
		case ENearEnvironment.MAIN_NET:
			return {
				networkId: "mainnet",
				nodeUrl: "https://rpc.mainnet.near.org",
				walletUrl: "https://wallet.near.org",
				helperUrl: "https://helper.mainnet.near.org",
				headers: {},
			};
		case ENearEnvironment.BETA_NET:
			return {
				networkId: "betanet",
				nodeUrl: "https://rpc.betanet.near.org",
				walletUrl: "https://wallet.betanet.near.org",
				helperUrl: "https://helper.betanet.near.org",
				headers: {},
			};
		case ENearEnvironment.TEST:
			return {
				networkId: "shared-test",
				nodeUrl: "https://rpc.ci-testnet.near.org",
				masterAccount: "test.near",
				headers: {},
			};
		case ENearEnvironment.TEST_NET:
		default:
			return {
				networkId: "testnet",
				nodeUrl: "https://rpc.testnet.near.org",
				walletUrl: "https://wallet.testnet.near.org",
				helperUrl: "https://helper.testnet.near.org",
				headers: {},
			};
	}
};
