import React, { lazy, useEffect, useState } from "react";
import styles from "./Main.module.scss";
import "./App.scss";
import cl from "classnames";
import {
	LazyLoadComponent,
	trackWindowScroll,
} from "react-lazy-load-image-component";
import topImg from "src/modules/mainPage/assets/img/png/TopLayer.png";
import bottomImg from "src/modules/mainPage/assets/img/png/BottomLayer.png";
import { Plate } from "src/modules/mainPage/ui/sections/Plate/Plate";
import { Parchments } from "src/modules/mainPage/ui/sections/Parchments/Parchments";
import { Machine } from "src/modules/mainPage/ui/sections/Machine/Machine";
import { FindRandomSets } from "src/modules/mainPage/ui/sections/FindRandomSets/FindRandomSets";
import { Web3NextGen } from "src/modules/mainPage/ui/sections/Web3NextGen/Web3NextGen";
import { BlockchainIsATool } from "src/modules/mainPage/ui/sections/BlockchainIsATool/BlockchainIsATool";
import { TokenSystem } from "src/modules/mainPage/ui/sections/3TokenSystem/TokenSystem";
import { HaveAnyQuestions } from "src/modules/mainPage/ui/sections/HaveAnyQuestions/HaveAnyQuestions";
import { Footer } from "src/modules/common/general/ui/Footer/Footer";
import { PreFooter } from "src/modules/common/general/ui/PreFooter/PreFooter";
import { Roadmap } from "src/modules/mainPage/ui/sections/Roadmap/Roadmap";
import { observer } from "mobx-react";
import { JoinWhiteListSection } from "src/modules/mainPage/ui/sections/JoinWiteListSection/JoinWhiteListSection";
import { Buffer } from "buffer";
import { Header } from "src/modules/common/general/ui/Header/Header";
import { BonusProgram } from "src/modules/mainPage/ui/sections/BonusProgram/BonusProgram";
import cross from "../../modules/common/general/assets/img/common/svg/cross.svg";
import play from "../../modules/common/general/assets/img/common/svg/play.svg";
import { Partners } from "src/modules/mainPage/ui/sections/Partners/Partners";
import { useIntl } from "react-intl";
import border from "src/modules/mainPage/assets/img/svg/trailer.svg";
import YouTube, { YouTubeProps } from "react-youtube";

const SocialNetworkLeftSide = lazy(
	() =>
		import(
			/* webpackChunkName: "SocialNetworkLeftSide" */ "../../modules/common/general/ui/SocialNetworkLeftSide/SocialNetworkLeftSide"
		)
);

window.Buffer = Buffer;

function Main() {
	const [isClosed, setIsClosed] = useState<boolean>(false);
	const intl = useIntl();
	const [isClicked, setIsClicked] = useState<boolean>(false);

	useEffect(() => {
		const topLayer = document.getElementById("topLayer");
		const topImage = document.getElementById("topImage");
		//const main = document.getElementById("main");
		window.addEventListener("scroll", () => {
			const { scrollTop, scrollHeight, clientHeight } =
				document.documentElement;
			const maxScroll = scrollHeight - clientHeight;
			const power = 60;
			const scale = 1 + (scrollTop / maxScroll) * power;
			if (scrollTop <= 500) {
				topLayer!.style.transform = `scale(${scale})`;
				topImage!.style.position = `fixed`;
				topImage!.style.marginTop = `${0}px`;

				// main!.style.marginTop = `115vh`
			}
			if (scrollTop > 500) {
				topImage!.style.position = `absolute`;
				topImage!.style.marginTop = `${501}px`;
				// main!.style.marginTop = `${0}px`
			}
			if (scrollTop >= 400) {
				topLayer!.style.opacity = "0";
				topLayer!.style.paddingBottom = `${scrollTop * 0.5}px`;
			} else {
				topLayer!.style.opacity = "1";
				topLayer!.style.paddingBottom = `${scrollTop * 0.5}px`;
			}
		});
	}, []);

	const opts: YouTubeProps["opts"] = {
		height: "186px",
		width: "286px",
		playerVars: {
			autoplay: 1,
			allowFullScreen: true,
			rel: 0,
			controls: 1,
			mute: 1,
			loop: 1,
			playlist: "Cnqn5E01aOU",
		},
	};

	const onPlayerStateChange = (event: any) => {
		if (event.data === 2) {
			setIsClicked(true);
			window.open("https://youtu.be/Cnqn5E01aOU")
		}
		// const player = event.target;
		// player.playVideo();
	};

	return (
		<div className={styles.App} id={"App1"}>
			<div
				className={styles.content}
				// style={{ height: `calc(130vh + ${mainHeight}px)` }}
				style={{ height: `100%` }}
			>
				<Header />
				<SocialNetworkLeftSide />
				{!isClosed && (
					<div className={styles.trailer}>
						<YouTube
							videoId="Cnqn5E01aOU"
							opts={opts}
							onStateChange={onPlayerStateChange}
							title="Pixudi Official Trailer | Season 1 Searching for Dragon Stone"
						/>
						{/* <iframe
							id="trailer"
							src={`https://www.youtube.com/embed/Cnqn5E01aOU?rel=0&controls=1&mute=1&autoplay=1&loop=1&playlist=Cnqn5E01aOU`}
							title="Pixudi Official Trailer | Season 1 Searching for Dragon Stone"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe> */}
						<div
							className={cl(styles.watch, { [styles.displayNone]: isClicked })}
						>
							{intl.formatMessage({ id: "Watch trailer" })}
						</div>
						<img
							src={cross}
							alt=""
							className={styles.cross}
							onClick={() => setIsClosed(!isClosed)}
						/>
						<img
							onClick={() => window.open("https://youtu.be/Cnqn5E01aOU")}
							src={play}
							alt=""
							className={cl(styles.play, { [styles.displayNone]: isClicked })}
						/>
					</div>
				)}
				<div className={styles.topImage} id={"topImage"}>
					<div className={styles.topLayer} id={"topLayer"}>
						<div className={styles.topLayerBackLeft} />
						<img src={topImg} alt="" />
						<div className={styles.topLayerBackRight} />
					</div>
					<div className={styles.bottomLayer} id={"bottomLayer"}>
						<img src={bottomImg} alt="" />
						<div className={styles.bottomLayerGrad}></div>
					</div>
				</div>
				<div className={styles.test}></div>
				<main className={cl(styles.main)} id={"main"}>
					<div className={cl(styles.mainContent, styles.container)}>
						<Plate />
						<div
							onClick={() => window.open("https://youtu.be/Cnqn5E01aOU")}
							className={styles.trailerMobile}
						>
							<img className={styles.background} src={border} alt="" />
							<iframe
								id="trailer"
								src={`https://www.youtube.com/embed/Cnqn5E01aOU?rel=0&controls=1&mute=1&autoplay=1&loop=1&playlist=Cnqn5E01aOU`}
								title="Pixudi Official Trailer | Season 1 Searching for Dragon Stone"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
							<div>{intl.formatMessage({ id: "Watch trailer" })}</div>
							<img src={play} alt="" className={styles.play} />
						</div>
						{/*<JoinWhiteListSection />*/}
						{/*<BonusProgram />*/}
						<Parchments />
						<Machine />
						<FindRandomSets />
						<Web3NextGen />
						<BlockchainIsATool />
						<TokenSystem />
						<Partners />
						<Roadmap />
						<HaveAnyQuestions />
					</div>
					<div className={styles.footer}>
						<LazyLoadComponent>
							<PreFooter />
						</LazyLoadComponent>
						<LazyLoadComponent>
							<Footer />
						</LazyLoadComponent>
					</div>
				</main>
			</div>
		</div>
	);
}

export default trackWindowScroll(observer(Main));
