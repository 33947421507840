import whitelist from "src/modules/payment/assets/img/mysteryPacksIcons/smallWhitelist.png";
import bigWhitelist from "src/modules/payment/assets/img/mysteryPacksIcons/bigWhitelist.png";
import bigCommon from "src/modules/payment/assets/img/mysteryPacksIcons/bigCommon.png";
import common from "src/modules/payment/assets/img/mysteryPacksIcons/smallCommon.png";
import bigRare from "src/modules/payment/assets/img/mysteryPacksIcons/bigRare.png";
import rare from "src/modules/payment/assets/img/mysteryPacksIcons/smallRare.png";
import bigLegendary from "src/modules/payment/assets/img/mysteryPacksIcons/bigLegendary.png";
import legendary from "src/modules/payment/assets/img/mysteryPacksIcons/smallLegendary.png";
import React from "react";
import WhitelistBody from "src/modules/payment/ui/nftsBody/WhitelistBody/WhitelistBody";
import PacksBody from "src/modules/payment/ui/nftsBody/PacksBody/PacksBody";
import WhitelistBodySuccess from "src/modules/payment/ui/bodyInSuccessfullyWindow/WhitelistBodySuccess/WhitelistBodySuccess";
import MPackBodySuccess from "src/modules/payment/ui/bodyInSuccessfullyWindow/MPackBodySuccess/MPackBodySuccess";
import { cryptoIcons } from "src/modules/common/general/utils/crypto/cryptoIcons";

export type TBlockchainPayment =
	| "eth"
	| "vlx"
	| "bnb"
	| "near"
	| "trx"
	| "neo"
	| any;
export type TProduct =
	| "pack_common_1"
	| "pack_rare_1"
	| "pack_legendary_1"
	| "whitelist";
export type TCoinPayment =
	| "eth"
	| "vlx"
	| "bnb"
	| "near"
	| "usdc"
	| "usdt"
	| "busd"
	| "neo"
	| "gas"
	| any;

export type TBlockchainMint =
	| "Velas"
	| "NEAR"
	| "SKALE"
	| "Neo"
	| "Oasis"
	| "Avalanche";
export type TBlockchainMintForBackend =
	| "vlx"
	| "near"
	| "oasis"
	| "skale"
	| "eth"
	| "neo"
	| "avax";

export type TSuccessPayment = -1 | 0 | 1;

export interface IResponseGetWallet {
	mintOn: TBlockchainMint;
	wallet: string;
	amount: string;
	blockchain: TBlockchainPayment;
	coin: TCoinPayment;
	success: TSuccessPayment;
	start: number;
	history_id: number;
}

export interface IHistoryResult {
	amount: number;
	result: boolean;
	type: TProduct;
	blockchain: string;
	coin: string;
	inUseEnd: number;
	check: boolean;
	count: number;
	mintOn: "vlx" | "near" | "skale" | "oasis" | "neo";
}

export interface IGetActivePayment {
	id: number;
	amount: number;
	walletId: string;
	inUseStart: number;
	type: TProduct;
	blockchain: string;
	coin: string;
	check: boolean;
	count: number;
	mintOn: "vlx" | "near" | "skale" | "oasis" | "neo";
	recipient: string;
}

export interface IPaymentInfo {
	packType: TProduct;
	numberOfProducts: number;
	chain: TBlockchainMint;
	convert: IPackPrices[];
}

//Pack Prices
export interface IPackPrices {
	price: string;
	type: string;
	bnb: string;
	eth: string;
	near: string;
	vlx: string;
	oasis: string;
	skale: string;
	neo: string;
}

// Packs

export interface IProduct {
	type: TProduct;
	descriptionOnNextWindows: string;
	smallIcon: string;
	bigIcon: string;
	title: string;
	name: string;

	body(
		type?: string,
		state?: React.Dispatch<React.SetStateAction<IProduct>>
	): JSX.Element;

	bodyInSuccessfullyWindow: any;
	descriptionUnderIcon: string;
}

export const products: IProduct[] = [
	{
		type: "whitelist",
		name: "Whitelist Card",
		descriptionOnNextWindows:
			"This Whitelist Card can be exchanged for a Common Mystery Pack",
		smallIcon: whitelist,
		bigIcon: bigWhitelist,
		title: "You’re about to join the Whitelist!",
		descriptionUnderIcon: "Can be exchanged for Mystery Pack",
		body: () => <WhitelistBody />,
		bodyInSuccessfullyWindow: <WhitelistBodySuccess />,
	},
	{
		type: "pack_common_1",
		name: "Mystery Pack",
		descriptionOnNextWindows:
			"You can find amongst them unique generative characters with random stats, pets that you can grow, over 50 power ups and more!",
		smallIcon: common,
		bigIcon: bigCommon,
		title: "Do you want to purchase a Mystery Pack?",
		descriptionUnderIcon: " ",
		body: (type, state) => (
			<PacksBody typePack={type} setSelectedPack={state} />
		),
		bodyInSuccessfullyWindow: <MPackBodySuccess />,
	},
	{
		type: "pack_rare_1",
		name: "Mystery Pack",
		descriptionOnNextWindows:
			"You can find amongst them unique generative characters with random stats, pets that you can grow, over 50 power ups and more!",
		smallIcon: rare,
		bigIcon: bigRare,
		title: "Do you want to purchase a Mystery Pack?",
		descriptionUnderIcon: " ",
		body: (type, state) => (
			<PacksBody typePack={type} setSelectedPack={state} />
		),
		bodyInSuccessfullyWindow: <MPackBodySuccess />,
	},
	{
		type: "pack_legendary_1",
		name: "Mystery Pack",
		descriptionOnNextWindows:
			"You can find amongst them unique generative characters with random stats, pets that you can grow, over 50 power ups and more!",
		smallIcon: legendary,
		bigIcon: bigLegendary,
		title: "Do you want to purchase a Mystery Pack?",
		descriptionUnderIcon: " ",
		body: (type, state) => (
			<PacksBody typePack={type} setSelectedPack={state} />
		),
		bodyInSuccessfullyWindow: <MPackBodySuccess />,
	},
];

export const blockchains = {
	NEAR: cryptoIcons.blockchains.png.NEAR,
	Oasis: cryptoIcons.blockchains.svg.Oasis,
	SKALE: cryptoIcons.blockchains.svg.SKALE,
	Velas: cryptoIcons.blockchains.png.Velas,
	Neo: cryptoIcons.blockchains.svg.Neo,
	Avalanche: cryptoIcons.blockchains.svg.Avax,
};

export interface ICrypto {
	name: string;
	icon: string;
	blockchain: TBlockchainPayment;
	coin: TCoinPayment;
	blockchainForBackend: string;
	coinForBackend: string;
	isStablecoin: boolean;
}

//todo extract data from deeps
const standardPaymentMethods: ICrypto[] = [
	{
		name: "BNB",
		icon: cryptoIcons.cryptocurrencies.png.BNB,
		blockchain: "BSC",
		coin: "BNB",
		blockchainForBackend: "bnb",
		coinForBackend: "bnb",
		isStablecoin: false,
	},
	{
		name: "BUSD",
		icon: cryptoIcons.cryptocurrencies.png.BUSD,
		blockchain: "BSC",
		coin: "BUSD",
		blockchainForBackend: "bnb",
		coinForBackend: "busd",
		isStablecoin: true,
	},
	{
		name: "ETH",
		icon: cryptoIcons.cryptocurrencies.png.ETH,
		blockchain: "ETH",
		coin: "ETH",
		blockchainForBackend: "eth",
		coinForBackend: "eth",
		isStablecoin: false,
	},
	{
		name: "USDT (ETH)",
		icon: cryptoIcons.cryptocurrencies.png.USDTETH,
		blockchain: "ETH",
		coin: "USDT",
		blockchainForBackend: "eth",
		coinForBackend: "usdt",
		isStablecoin: true,
	},
	{
		name: "USDT (TRON)",
		icon: cryptoIcons.cryptocurrencies.png.USDTTRON,
		blockchain: "Tron",
		coin: "USDT",
		blockchainForBackend: "trx",
		coinForBackend: "usdt",
		isStablecoin: true,
	},
	{
		name: "USDC (ETH)",
		icon: cryptoIcons.cryptocurrencies.png.USDCETH,
		blockchain: "ETH",
		coin: "USDC",
		blockchainForBackend: "eth",
		coinForBackend: "usdc",
		isStablecoin: true,
	},
];

export const allPaymentMethods: Map<TBlockchainMint, ICrypto[]> = new Map([
	[
		"NEAR",
		[
			...standardPaymentMethods,
			{
				name: "NEAR",
				icon: cryptoIcons.cryptocurrencies.png.NEAR,
				blockchain: "NEAR",
				coin: "NEAR",
				blockchainForBackend: "near",
				coinForBackend: "near",
				isStablecoin: false,
			},
			{
				name: "USDT (NEAR)",
				icon: cryptoIcons.cryptocurrencies.png.USDTNEAR,
				blockchain: "NEAR",
				coin: "USDT",
				blockchainForBackend: "near",
				coinForBackend: "usdt",
				isStablecoin: true,
			},
			{
				name: "USDC (NEAR)",
				icon: cryptoIcons.cryptocurrencies.png.USDCNEAR,
				blockchain: "NEAR",
				coin: "USDC",
				blockchainForBackend: "near",
				coinForBackend: "usdc",
				isStablecoin: true,
			},
		],
	],
	[
		"Velas",
		[
			...standardPaymentMethods,
			{
				name: "VLX",
				icon: cryptoIcons.cryptocurrencies.png.Velas,
				blockchain: "Velas",
				coin: "VLX",
				blockchainForBackend: "vlx",
				coinForBackend: "vlx",
				isStablecoin: false,
			},
		],
	],
	[
		"SKALE",
		[
			...standardPaymentMethods,
			{
				name: "SKALE",
				icon: cryptoIcons.cryptocurrencies.svg.SKALE,
				blockchain: "ETH",
				coin: "SKL",
				blockchainForBackend: "eth",
				coinForBackend: "skale",
				isStablecoin: false,
			},
		],
	],
	[
		"Oasis",
		[
			...standardPaymentMethods,
			{
				name: "ROSE (Sapphire)",
				icon: cryptoIcons.cryptocurrencies.svg.Oasis,
				blockchain: "Oasis (Sapphire)",
				coin: "ROSE",
				blockchainForBackend: "oasis",
				coinForBackend: "oasis",
				isStablecoin: false,
			},
		],
	],
	[
		"Neo",
		[
			...standardPaymentMethods,
			{
				name: "NEO",
				icon: cryptoIcons.cryptocurrencies.svg.Neo,
				blockchain: "Neo",
				coin: "NEO",
				blockchainForBackend: "neo",
				coinForBackend: "neo",
				isStablecoin: false,
			},
			{
				name: "GAS",
				icon: cryptoIcons.cryptocurrencies.png.GAS,
				blockchain: "Neo",
				coin: "GAS",
				blockchainForBackend: "neo",
				coinForBackend: "gas",
				isStablecoin: false,
			},
		],
	],
	[
		"Avalanche",
		[
			...standardPaymentMethods,
			{
				name: "AVAX",
				icon: cryptoIcons.cryptocurrencies.svg.Avax,
				blockchain: "Avalanche",
				coin: "AVAX",
				blockchainForBackend: "avax",
				coinForBackend: "avax",
				isStablecoin: false,
			},
		],
	],
]);

export const arrayBlockchain: IChain[] = [
	{
		type: "SKALE",
		icon: cryptoIcons.blockchains.svg.SKALE,
		coin: "SKL",
		typeForDisplay: "SKALE Network",
	},
	{
		type: "NEAR",
		icon: cryptoIcons.blockchains.png.NEAR,
		coin: "NEAR",
		typeForDisplay: "NEAR Protocol",
	},
	{
		type: "Velas",
		icon: cryptoIcons.blockchains.png.Velas,
		coin: "VLX",
		typeForDisplay: "Velas Blockchain",
	},
	{
		type: "Oasis",
		icon: cryptoIcons.blockchains.svg.Oasis,
		coin: "ROSE",
		typeForDisplay: "Oasis Network",
	},
	{
		type: "Neo",
		icon: cryptoIcons.blockchains.svg.Neo,
		coin: "NEO",
		typeForDisplay: "Neo Blockchain",
	},
	{
		type: "Avalanche",
		icon: cryptoIcons.blockchains.svg.Avax,
		coin: "AVAX",
		typeForDisplay: "Avalanche Network",
	},
];

export const convertChainForBack: Map<
	TBlockchainMint,
	TBlockchainMintForBackend
> = new Map([
	["NEAR", "near"],
	["Oasis", "oasis"],
	["SKALE", "skale"],
	["Velas", "vlx"],
	["Neo", "neo"],
	["Avalanche", "avax"],
]);

export const convertChainForFront: Map<
	TBlockchainMintForBackend,
	TBlockchainMint
> = new Map([
	["near", "NEAR"],
	["oasis", "Oasis"],
	["skale", "SKALE"],
	["vlx", "Velas"],
	["neo", "Neo"],
	["avax", "Avalanche"],
]);

export interface IChain {
	type: TBlockchainMint;
	icon: string;
	coin: string;
	typeForDisplay:
		| "NEAR Protocol"
		| "Velas Blockchain"
		| "Oasis Network"
		| "SKALE Network"
		| "Neo Blockchain"
		| "Avalanche Network";
}

export type authMethod = {
	name: string;
	icon: string;
	typePayment: string;
};

export type TAuthMethods = {
	NEAR: authMethod[];
	Oasis: authMethod[];
	SKALE: authMethod[];
	Velas: authMethod[];
	Neo: authMethod[];
	Avalanche: authMethod[];
};

const authMethodsVelas: authMethod[] = [
	// {
	// 	name: "Velas Account",
	// 	icon: cryptoIcons.blockchains.png.Velas,
	// 	typePayment: "Velas Account",
	// },
	{
		name: "MetaMask Velas",
		icon: cryptoIcons.wallets.png.Metamask,
		typePayment: "Metamask",
	},
];
const authMethodsNear: authMethod[] = [
	{
		name: "NEAR wallet",
		icon: cryptoIcons.blockchains.png.NEAR,
		typePayment: "NEAR wallet selector",
	},
];
const authMethodsOasis: authMethod[] = [
	{
		name: "MetaMask Oasis",
		icon: cryptoIcons.wallets.png.Metamask,
		typePayment: "Metamask",
	},
];
const authMethodsSkale: authMethod[] = [
	{
		name: "MetaMask SKALE",
		icon: cryptoIcons.wallets.png.Metamask,
		typePayment: "Metamask",
	},
];
const authMethodsAvalanche: authMethod[] = [
	{
		name: "MetaMask Avalanche",
		icon: cryptoIcons.wallets.png.Metamask,
		typePayment: "Metamask",
	},
];
const authMethodsNeo: authMethod[] = [
	{
		name: "Neon Wallet",
		icon: cryptoIcons.wallets.svg.Neon,
		typePayment: "Neon Wallet",
	},
	{
		name: "NeoLine Wallet",
		icon: cryptoIcons.wallets.png.NeoLine,
		typePayment: "NeoLine Wallet",
	},
];

export const AuthMethods: TAuthMethods = {
	NEAR: authMethodsNear,
	Oasis: authMethodsOasis,
	SKALE: authMethodsSkale,
	Velas: authMethodsVelas,
	Neo: authMethodsNeo,
	Avalanche: authMethodsAvalanche,
};

export const convertNameCoin = {
	oasis: "ROSE",
	vlx: "VLX",
	skale: "SKL",
	bnb: "BNB",
	busd: "BUSD",
	eth: "ETH",
	near: "NEAR",
	usdt: "USDT",
	usdc: "USDC",
	trx: "Tron",
	neo: "NEO",
	gas: "GAS",
	avax: "Avax",
};

export const convertNameBlockchain = {
	oasis: "Oasis (Sapphire)",
	vlx: "velas",
	skale: "ETH",
	bnb: "BSC",
	eth: "ETH",
	near: "NEAR",
	usdt: "USDT",
	usdc: "USDC",
	trx: "Tron",
	neo: "Neo",
	avax: "Avalanche",
};
