import { AxiosResponse } from "axios";
import agent from "src/app/api/axios";
import { IAuth } from "src/modules/common/general/types/common";

export const fetchGetProfile = (): Promise<AxiosResponse<any>> => {
	return agent.get(`/profile/get_profile`);
};
export const fetchGetProfileByWallet = (
	walletId: string,
	wallet: string
): Promise<AxiosResponse<any>> => {
	return agent.get(
		`/profile/get_profile_by_wallet?walletId=${walletId}&wallet=${wallet}&`
	);
};
export const fetchUpdateProfile = (data: any): Promise<AxiosResponse<any>> => {
	// console.log("data.walletAddress",accountId)
	return agent.post(`/profile/update_profile`, data);
};

export const fetchSetWallet = (
	walletType: IAuth.AuthTypes,
	walletId: string
): Promise<AxiosResponse<any>> => {
	// console.log("data.walletAddress",accountId)
	return agent.post(`/profile/set_wallet`, {
		wallet_type: walletType,
		wallet_id: walletId,
	});
};
