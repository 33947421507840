import React, { ButtonHTMLAttributes } from "react";
import styles from "src/modules/common/general/ui/buttons/ButtonJoin/ButtonJoin.module.scss";
import cl from "classnames";
import btn from "src/modules/mainPage/assets/img/png/Button.png";

interface IButtonJoinProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: any;
	className?: string;
}

export const ButtonJoin: React.FC<IButtonJoinProps> = ({
	children,
	className,
	...props
}) => {
	return (
		<button className={cl(styles.btn, className)} {...props}>
			<div className={styles.title}>{children}</div>
			<div className={styles.border}>
				<img src={btn} alt="" />
			</div>
		</button>
	);
};
