import React from "react";
import styles from "src/modules/payment/ui/modals/ErrorWentWrongModal/ErrorWentWrongModal.module.scss";
import Error from "src/modules/common/general/assets/img/common/png/error.png";
import cross from "src/modules/payment/assets/img/svg/cross.svg";
import { useIntl } from "react-intl";
import { modal } from "src/modules/common/general/ui/Modal";

export const ErrorWentWrongModal: React.FC<any> = () => {
	const intl = useIntl();
	return (
		<div className={styles.modal}>
			<div
				className={styles.cross}
				onClick={() => {
					modal.close();
				}}
			>
				<img src={cross} alt={""} />
			</div>
			<div className={styles.title}>
				{intl.formatMessage({ id: "Oops... Something went wrong" })}
			</div>
			<img src={Error} alt={""} />
			<div className={styles.description}>
				{intl.formatMessage({
					id: "Looks like there’s a problem. Please try using another wallet",
				})}{" "}
				<br />
				{intl.formatMessage({ id: "or come back later." })}
			</div>
		</div>
	);
};
