import { CHAIN_SETTINGS_MAINNET_AND_TESTNET } from "src/modules/common/general/utils/crypto/metamask/networkParams";
import { logger } from "src/modules/common/general/utils/disableLogs";

let CONTAINER_RPC_VELAS = "";
let CONTAINER_RPC_OASIS = "";
let CONTAINER_RPC_AVAX = "";
let CONTAINER_RPC_SKALE = "";
let CONTAINER_RPC_NEO = "";

let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS = "";
let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR = "";
let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS = "";
let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX = "";
let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE = "";
let CONTAINER_ADDRESS_BETS = "";
let CONTAINER_ADDRESS_DEPOSIT = "";
let CONTAINER_CHAIN_OASIS_BETS: any = "";
let CONTAINER_CHAIN_OASIS_DEPOSIT: any = "";

const RPC_VELAS_TESTNET = "https://evmexplorer.testnet.velas.com/rpc";
const RPC_OASIS_TESTNET = "https://testnet.sapphire.oasis.dev/";
const RPC_AVAX_TESTNET = "https://ava-testnet.public.blastapi.io/ext/bc/C/rpc";
const RPC_SKALE_TESTNET =
	"https://staging-v3.skalenodes.com/v1/staging-utter-unripe-menkar";
const RPC_NEO_TESTNET = "https://testnet2.neo.coz.io:443";

const RPC_NEO_MAINNET = "http://seed9.ngd.network:10332";
const RPC_VELAS_MAINNET = "";
const RPC_OASIS_MAINNETT = "";
const RPC_AVAX_MAINNET = "";
const RPC_SKALE_MAINNET = "";

const CHRISTMAS_CHARACTER_CONTRACT_VELAS_TESTNET =
	"0x8A9d29d315a87F57bB8764BC2bD7669b2C55f1f0";
const CHRISTMAS_CHARACTER_CONTRACT_NEAR_TESTNET = "nft1.pixudi2022.testnet";
const CHRISTMAS_CHARACTER_CONTRACT_OASIS_TESTNET =
	"0xD8499A7fb6E3aA2747030554cE340B4257FEe63e";
const CHRISTMAS_CHARACTER_CONTRACT_AVAX_TESTNET =
	"0x0C72629E750b1552c2290D06EACA8a5c3d24f8cB";
const CHRISTMAS_CHARACTER_CONTRACT_SKALE_TESTNET =
	"0x48eA43897FC733744eE75a68492aF031747a8Ce9";

const CHRISTMAS_CHARACTER_CONTRACT_VELAS_MAINNET = "";
const CHRISTMAS_CHARACTER_CONTRACT_NEAR_MAINNET = "";
const CHRISTMAS_CHARACTER_CONTRACT_OASIS_MAINNET = "";
const CHRISTMAS_CHARACTER_CONTRACT_AVAX_MAINNET = "";
const CHRISTMAS_CHARACTER_CONTRACT_SKALE_MAINNET = "";

const ADDRESS_BETS_TESTNET = "0x97229FA938DBcc20BB7F81805ceF07355381777f";
const ADDRESS_DEPOSIT_TESTNET = "0x30C1e6Ef7631fc5B87F9d1603EBe131395B0EE46";

const ADDRESS_BETS_MAINNET = "";
const ADDRESS_DEPOSIT_MAINNET = "";

if (process.env.REACT_APP_MODE === "local") {
	// PROFILE
	CONTAINER_RPC_VELAS = RPC_VELAS_TESTNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_TESTNET;
	CONTAINER_RPC_AVAX = RPC_AVAX_TESTNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_TESTNET;
	CONTAINER_RPC_NEO = RPC_NEO_TESTNET;

	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS =
		CHRISTMAS_CHARACTER_CONTRACT_VELAS_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR =
		CHRISTMAS_CHARACTER_CONTRACT_NEAR_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS =
		CHRISTMAS_CHARACTER_CONTRACT_OASIS_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX =
		CHRISTMAS_CHARACTER_CONTRACT_AVAX_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE =
		CHRISTMAS_CHARACTER_CONTRACT_SKALE_TESTNET;

	//BETS
	CONTAINER_ADDRESS_BETS = ADDRESS_BETS_TESTNET;
	CONTAINER_CHAIN_OASIS_BETS = CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//DEPOSIT
	CONTAINER_ADDRESS_DEPOSIT = ADDRESS_DEPOSIT_TESTNET;
	CONTAINER_CHAIN_OASIS_DEPOSIT =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	logger.enableLogger();
}

if (process.env.REACT_APP_MODE === "beta") {
	// PROFILE
	CONTAINER_RPC_VELAS = RPC_VELAS_MAINNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_MAINNETT;
	CONTAINER_RPC_AVAX = RPC_AVAX_MAINNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_MAINNET;
	CONTAINER_RPC_NEO = RPC_NEO_MAINNET;

	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS =
		CHRISTMAS_CHARACTER_CONTRACT_VELAS_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR =
		CHRISTMAS_CHARACTER_CONTRACT_NEAR_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS =
		CHRISTMAS_CHARACTER_CONTRACT_OASIS_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX =
		CHRISTMAS_CHARACTER_CONTRACT_AVAX_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE =
		CHRISTMAS_CHARACTER_CONTRACT_SKALE_MAINNET;

	//BETS
	CONTAINER_ADDRESS_BETS = ADDRESS_BETS_MAINNET;
	CONTAINER_CHAIN_OASIS_BETS = CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//DEPOSIT
	CONTAINER_ADDRESS_DEPOSIT = ADDRESS_DEPOSIT_MAINNET;
	CONTAINER_CHAIN_OASIS_DEPOSIT =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	logger.enableLogger();
}
if (process.env.REACT_APP_MODE === "dev") {
	// PROFILE
	CONTAINER_RPC_VELAS = RPC_VELAS_TESTNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_TESTNET;
	CONTAINER_RPC_AVAX = RPC_AVAX_TESTNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_TESTNET;
	CONTAINER_RPC_NEO = RPC_NEO_TESTNET;

	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS =
		CHRISTMAS_CHARACTER_CONTRACT_VELAS_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR =
		CHRISTMAS_CHARACTER_CONTRACT_NEAR_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS =
		CHRISTMAS_CHARACTER_CONTRACT_OASIS_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX =
		CHRISTMAS_CHARACTER_CONTRACT_AVAX_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE =
		CHRISTMAS_CHARACTER_CONTRACT_SKALE_TESTNET;

	//BETS
	CONTAINER_ADDRESS_BETS = ADDRESS_BETS_TESTNET;
	CONTAINER_CHAIN_OASIS_BETS = CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//DEPOSIT
	CONTAINER_ADDRESS_DEPOSIT = ADDRESS_DEPOSIT_TESTNET;
	CONTAINER_CHAIN_OASIS_DEPOSIT =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	logger.enableLogger();
}
if (process.env.REACT_APP_MODE === "prod") {
	// PROFILE
	CONTAINER_RPC_VELAS = RPC_VELAS_MAINNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_MAINNETT;
	CONTAINER_RPC_AVAX = RPC_AVAX_MAINNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_MAINNET;
	CONTAINER_RPC_NEO = RPC_NEO_MAINNET;

	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS =
		CHRISTMAS_CHARACTER_CONTRACT_VELAS_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR =
		CHRISTMAS_CHARACTER_CONTRACT_NEAR_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS =
		CHRISTMAS_CHARACTER_CONTRACT_OASIS_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX =
		CHRISTMAS_CHARACTER_CONTRACT_AVAX_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE =
		CHRISTMAS_CHARACTER_CONTRACT_SKALE_MAINNET;

	//BETS
	CONTAINER_ADDRESS_BETS = ADDRESS_BETS_MAINNET;
	CONTAINER_CHAIN_OASIS_BETS = CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//DEPOSIT
	CONTAINER_ADDRESS_DEPOSIT = ADDRESS_DEPOSIT_MAINNET;
	CONTAINER_CHAIN_OASIS_DEPOSIT =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	logger.disableLogger();
}

export const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN!;
export const SERVER_SOCKET_DOMAIN = process.env.REACT_APP_SERVER_SOCKET_DOMAIN!;

export const cellSize = 112;
export const timeCloseModal = 3000;

export const TIME_TO_DISPLAY_RESULT_ROUND_IN_DUEL: number = 10000;

export const TIME_OF_ONE_MOVER_PER_CELL = 1500;
export const MODAL_WINDOW_OPENING_DELAY = 1000;
export const MODAL_WINDOW_OPENING_DELAY_DICE_ROLL = 3000;
export const DELAY_DISAPPEARANCE_OF_DICE = 3000;
export const DELAY_BEFORE_NEXT_MOVE = 5000;

// BETS
export const ADDRESS_CONTRACT_BETS = CONTAINER_ADDRESS_BETS;
export const CHAIN_OASIS_BETS = CONTAINER_CHAIN_OASIS_BETS;

//DEPOSIT
export const DEPOSIT_CONTRACT = CONTAINER_ADDRESS_DEPOSIT;
export const CHAIN_OASIS_DEPOSIT = CONTAINER_CHAIN_OASIS_DEPOSIT;

// PROFILE
export const RPC_VELAS = CONTAINER_RPC_VELAS;
export const RPC_OASIS = CONTAINER_RPC_OASIS;
export const RPC_AVAX = CONTAINER_RPC_AVAX;
export const RPC_SKALE = CONTAINER_RPC_SKALE;
export const RPC_NEO = CONTAINER_RPC_NEO;
export const CHRISTMAS_CHARACTER_CONTRACT_VELAS =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS;
export const CHRISTMAS_CHARACTER_CONTRACT_NEAR =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR;
export const CHRISTMAS_CHARACTER_CONTRACT_OASIS =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS;
export const CHRISTMAS_CHARACTER_CONTRACT_AVAX =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX;
export const CHRISTMAS_CHARACTER_CONTRACT_SKALE =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE;

export const RPC_CHARACTERS_VELAS = "https://evmexplorer.testnet.velas.com/rpc";
export const RPC_CHARACTERS_AVAX =
	"https://ava-testnet.public.blastapi.io/ext/bc/C/rpc";
export const RPC_CHARACTERS_OASIS = "https://testnet.sapphire.oasis.dev/";
export const RPC_CHARACTERS_SKALE =
	"https://staging-v3.skalenodes.com/v1/staging-utter-unripe-menkar";
export const CONTRACT_CHARACTERS_VELAS =
	"0x8A9d29d315a87F57bB8764BC2bD7669b2C55f1f0";
export const CONTRACT_CHARACTERS_NEAR = "nft1.pixudi2022.testnet";
export const CONTRACT_CHARACTERS_AVAX =
	"0x0C72629E750b1552c2290D06EACA8a5c3d24f8cB";
export const CONTRACT_CHARACTERS_OASIS =
	"0xD8499A7fb6E3aA2747030554cE340B4257FEe63e";
export const CONTRACT_CHARACTERS_SKALE =
	"0x48eA43897FC733744eE75a68492aF031747a8Ce9";

// Game create options
export const NUMBER_OF_TURNS = [5, 7, 10, 15, 20, 25];
export const TIME_PER_ROLL = 2;
export const TIME_FOR_TURN = 120;

// SETTINGS CHAIN FOR METAMASK AUTHORIZATION AND REGISTRATION
const CHAIN_SETTINGS_METAMASK_PROD = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_MAINNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_MAINNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET,
};
const CHAIN_SETTINGS_METAMASK_LOCAL = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_MAINNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_MAINNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET,
};
const CHAIN_SETTINGS_METAMASK_DEV = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_TESTNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_TESTNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_TESTNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_TESTNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_TESTNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_TESTNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_TESTNET,
};

const CHAIN_SETTINGS_METAMASK_BETA = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_MAINNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_MAINNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET,
};

export const CHAIN_SETTINGS_METAMASK_AUTH =
	process.env.REACT_APP_MODE === "prod"
		? CHAIN_SETTINGS_METAMASK_PROD
		: process.env.REACT_APP_MODE === "dev"
		? CHAIN_SETTINGS_METAMASK_DEV
		: process.env.REACT_APP_MODE === "beta"
		? CHAIN_SETTINGS_METAMASK_BETA
		: CHAIN_SETTINGS_METAMASK_LOCAL;
