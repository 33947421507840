import { ENearEnvironment } from "src/modules/common/general/utils/crypto/near/near.environment";

let CONTAINER_TESTNET_OR_MAINNET_NEO = "";
let CONTAINER_ENVIRONMENT_NEAR = ENearEnvironment.MAIN_NET;

let CONTAINER_ADDRESS_NEAR_USDT = "";
let CONTAINER_ADDRESS_NEAR_USDC = "";
let CONTAINER_ADDRESS_TRON_USDT = "";
let CONTAINER_ADDRESS_ETH_USDT = "";
let CONTAINER_ADDRESS_ETH_USDC = "";
let CONTAINER_ADDRESS_ETH_SKALE = "";
let CONTAINER_ADDRESS_BNB_BUSD = "";

let CONTAINER_ADDRESS_OASIS_MARKET = "";
let CONTAINER_ADDRESS_NEAR_MARKET = "";
let CONTAINER_ADDRESS_SKALE_MARKET = "";
let CONTAINER_ADDRESS_VELAS_MARKET = "";
let CONTAINER_ADDRESS_NEO_MARKET = "";
let CONTAINER_ADDRESS_AVAX_MARKET = "";

let CONTAINER_RPC_VELAS = "";
let CONTAINER_RPC_SKALE = "";
let CONTAINER_RPC_OASIS = "";
let CONTAINER_RPC_NEO = "";
let CONTAINER_RPC_AVAX = "";

//const PROVIDER_VELAS_TESTNET = 'https://testnet.velas.com/rpc'
//const ADDRESS_VELAS_MARKET_TESTNET = '0xcDf55a37D26C7668C3Cd0879c50156747f4Dd085'
//const ADDRESS_NEAR_MARKET_TESTNET = 'dev-1676536624231-80455694649699'

const TESTNET = "testnet";
const MAINNET = "mainnet";

const ENVIRONMENT_NEAR_TESTNET = ENearEnvironment.TEST_NET;
const ENVIRONMENT_NEAR_MAINNET = ENearEnvironment.MAIN_NET;
const ENVIRONMENT_NEAR_BETANET = ENearEnvironment.BETA_NET;

const RPC_VELAS_MAINNET = "https://api.velas.com/rpc";
const RPC_SKALE_MAINNET =
	"https://mainnet.skalenodes.com/v1/honorable-steel-rasalhague";
const RPC_OASIS_MAINNET = "https://sapphire.oasis.io";
const RPC_NEO_MAINNET = "https://mainnet1.neo.coz.io:443";
const RPC_NEO_TESTNET = "https://testnet2.neo.coz.io:443";
// const RPC_NEO_TESTNET = "https://mainnet1.neo.coz.io:443";

const RPC_AVAX_MAINNET = "https://api.avax.network/ext/bc/C/rpc";

const ADDRESS_VELAS_MARKET_MAINNET =
	"0xb7E35ae53CFe3b24d0D1Ada5514A9332d413438e";
const ADDRESS_NEAR_MARKET_MAINNET = "ticketnft.xudipay.near";
const ADDRESS_SKALE_MARKET_MAINNET =
	"0x12019b5bE762815DeFf88CD12c3D9c8D900Ed41d";
const ADDRESS_OASIS_MARKET_MAINNET =
	"0x12019b5bE762815DeFf88CD12c3D9c8D900Ed41d";
const ADDRESS_AVAX_MARKET_MAINNET =
	"0x8ce5b7b074e661cd4bfd93667227cc0f5399f0f7";
const ADDRESS_NEO_MARKET_MAINNET = "0x66f21c88f41326152c98ba5a4df04d8c34c4d48e";
const ADDRESS_NEO_MARKET_TESTNET = "0xaa966a2eb9d9dde22b992d9a92c167fefcc31d11";

const ADDRESS_TESTNET_TRON_USDT = "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj";
const ADDRESS_TESTNET_ETH_USDT = "0x0Fd92076677A97771D0DA9BdC91e8D4971d706E3";
const ADDRESS_TESTNET_ETH_USDC = "0x0Fd92076677A97771D0DA9BdC91e8D4971d706E3";
const ADDRESS_TESTNET_ETH_SKALE = "0x0Fd92076677A97771D0DA9BdC91e8D4971d706E3";
const ADDRESS_TESTNET_BNB_BUSD = "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee";

const ADDRESS_MAINNET_NEAR_USDT =
	"dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near";
const ADDRESS_MAINNET_NEAR_USDC =
	"a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.factory.bridge.near";
const ADDRESS_MAINNET_TRON_USDT = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";
const ADDRESS_ETH_MAINNET_USDT = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
const ADDRESS_ETH_MAINNET_USDC = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
const ADDRESS_ETH_MAINNET_SKALE = "0x12019b5bE762815DeFf88CD12c3D9c8D900Ed41d";
const ADDRESS_BNB_MAINNET_BUSD = "0xe9e7cea3dedca5984780bafc599bd69add087d56";

if (process.env.REACT_APP_MODE_FOR_PAYMENT === "local") {
	CONTAINER_TESTNET_OR_MAINNET_NEO = TESTNET;
	CONTAINER_ENVIRONMENT_NEAR = ENVIRONMENT_NEAR_MAINNET;

	CONTAINER_ADDRESS_TRON_USDT = ADDRESS_TESTNET_TRON_USDT;
	CONTAINER_ADDRESS_ETH_USDT = ADDRESS_TESTNET_ETH_USDT;
	CONTAINER_ADDRESS_ETH_USDC = ADDRESS_TESTNET_ETH_USDC;
	CONTAINER_ADDRESS_BNB_BUSD = ADDRESS_TESTNET_BNB_BUSD;
	CONTAINER_ADDRESS_ETH_SKALE = ADDRESS_TESTNET_ETH_SKALE;
	CONTAINER_ADDRESS_NEAR_USDT = ADDRESS_MAINNET_NEAR_USDT;
	CONTAINER_ADDRESS_NEAR_USDC = ADDRESS_MAINNET_NEAR_USDC;

	CONTAINER_ADDRESS_OASIS_MARKET = ADDRESS_OASIS_MARKET_MAINNET;
	CONTAINER_ADDRESS_NEAR_MARKET = ADDRESS_NEAR_MARKET_MAINNET;
	CONTAINER_ADDRESS_SKALE_MARKET = ADDRESS_SKALE_MARKET_MAINNET;
	CONTAINER_ADDRESS_VELAS_MARKET = ADDRESS_VELAS_MARKET_MAINNET;
	CONTAINER_ADDRESS_NEO_MARKET = ADDRESS_NEO_MARKET_TESTNET;
	CONTAINER_ADDRESS_AVAX_MARKET = ADDRESS_AVAX_MARKET_MAINNET;

	CONTAINER_RPC_VELAS = RPC_VELAS_MAINNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_MAINNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_MAINNET;
	CONTAINER_RPC_NEO = RPC_NEO_TESTNET;
	CONTAINER_RPC_AVAX = RPC_AVAX_MAINNET;
}

if (process.env.REACT_APP_MODE_FOR_PAYMENT === "dev") {
	CONTAINER_TESTNET_OR_MAINNET_NEO = TESTNET;
	CONTAINER_ENVIRONMENT_NEAR = ENVIRONMENT_NEAR_MAINNET;

	CONTAINER_ADDRESS_TRON_USDT = ADDRESS_TESTNET_TRON_USDT;
	CONTAINER_ADDRESS_ETH_USDT = ADDRESS_TESTNET_ETH_USDT;
	CONTAINER_ADDRESS_ETH_USDC = ADDRESS_TESTNET_ETH_USDC;
	CONTAINER_ADDRESS_ETH_SKALE = ADDRESS_TESTNET_ETH_SKALE;
	CONTAINER_ADDRESS_BNB_BUSD = ADDRESS_TESTNET_BNB_BUSD;
	CONTAINER_ADDRESS_NEAR_USDT = ADDRESS_MAINNET_NEAR_USDT;
	CONTAINER_ADDRESS_NEAR_USDC = ADDRESS_MAINNET_NEAR_USDC;

	CONTAINER_ADDRESS_OASIS_MARKET = ADDRESS_OASIS_MARKET_MAINNET;
	CONTAINER_ADDRESS_NEAR_MARKET = ADDRESS_NEAR_MARKET_MAINNET;
	CONTAINER_ADDRESS_SKALE_MARKET = ADDRESS_SKALE_MARKET_MAINNET;
	CONTAINER_ADDRESS_VELAS_MARKET = ADDRESS_VELAS_MARKET_MAINNET;
	CONTAINER_ADDRESS_NEO_MARKET = ADDRESS_NEO_MARKET_TESTNET;
	CONTAINER_ADDRESS_AVAX_MARKET = ADDRESS_AVAX_MARKET_MAINNET;

	CONTAINER_RPC_VELAS = RPC_VELAS_MAINNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_MAINNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_MAINNET;
	CONTAINER_RPC_NEO = RPC_NEO_TESTNET;
	CONTAINER_RPC_AVAX = RPC_AVAX_MAINNET;
}

if (process.env.REACT_APP_MODE_FOR_PAYMENT === "prod") {
	CONTAINER_TESTNET_OR_MAINNET_NEO = MAINNET;
	CONTAINER_ENVIRONMENT_NEAR = ENVIRONMENT_NEAR_MAINNET;

	CONTAINER_ADDRESS_TRON_USDT = ADDRESS_MAINNET_TRON_USDT;
	CONTAINER_ADDRESS_ETH_USDT = ADDRESS_ETH_MAINNET_USDT;
	CONTAINER_ADDRESS_ETH_USDC = ADDRESS_ETH_MAINNET_USDC;
	CONTAINER_ADDRESS_BNB_BUSD = ADDRESS_BNB_MAINNET_BUSD;
	CONTAINER_ADDRESS_ETH_SKALE = ADDRESS_ETH_MAINNET_SKALE;
	CONTAINER_ADDRESS_NEAR_USDT = ADDRESS_MAINNET_NEAR_USDT;
	CONTAINER_ADDRESS_NEAR_USDC = ADDRESS_MAINNET_NEAR_USDC;

	CONTAINER_ADDRESS_OASIS_MARKET = ADDRESS_OASIS_MARKET_MAINNET;
	CONTAINER_ADDRESS_NEAR_MARKET = ADDRESS_NEAR_MARKET_MAINNET;
	CONTAINER_ADDRESS_SKALE_MARKET = ADDRESS_SKALE_MARKET_MAINNET;
	CONTAINER_ADDRESS_VELAS_MARKET = ADDRESS_VELAS_MARKET_MAINNET;
	CONTAINER_ADDRESS_NEO_MARKET = ADDRESS_NEO_MARKET_MAINNET;
	CONTAINER_ADDRESS_AVAX_MARKET = ADDRESS_AVAX_MARKET_MAINNET;

	CONTAINER_RPC_VELAS = RPC_VELAS_MAINNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_MAINNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_MAINNET;
	CONTAINER_RPC_NEO = RPC_NEO_MAINNET;
	CONTAINER_RPC_AVAX = RPC_AVAX_MAINNET;
}

export const CHAIN_NEO = CONTAINER_TESTNET_OR_MAINNET_NEO;

export const ADDRESS_NEAR_USDT = CONTAINER_ADDRESS_NEAR_USDT;
export const ADDRESS_NEAR_USDC = CONTAINER_ADDRESS_NEAR_USDC;
export const ADDRESS_TRON_USDT = CONTAINER_ADDRESS_TRON_USDT;
export const ADDRESS_ETH_USDT = CONTAINER_ADDRESS_ETH_USDT;
export const ADDRESS_ETH_USDC = CONTAINER_ADDRESS_ETH_USDC;
export const ADDRESS_ETH_SKALE = CONTAINER_ADDRESS_ETH_SKALE;
export const ADDRESS_BNB_BUSD = CONTAINER_ADDRESS_BNB_BUSD;

export const ENVIRONMENT_NEAR = CONTAINER_ENVIRONMENT_NEAR;

export const RPC_VELAS = CONTAINER_RPC_VELAS;
export const RPC_SKALE = CONTAINER_RPC_SKALE;
export const RPC_OASIS = CONTAINER_RPC_OASIS;
export const RPC_NEO = CONTAINER_RPC_NEO;
export const RPC_AVAX = CONTAINER_RPC_AVAX;

export const ADDRESS_VELAS_MARKET = CONTAINER_ADDRESS_VELAS_MARKET;
export const ADDRESS_NEAR_MARKET = CONTAINER_ADDRESS_NEAR_MARKET;
export const ADDRESS_SKALE_MARKET = CONTAINER_ADDRESS_SKALE_MARKET;
export const ADDRESS_OASIS_MARKET = CONTAINER_ADDRESS_OASIS_MARKET;
export const ADDRESS_NEO_MARKET = CONTAINER_ADDRESS_NEO_MARKET;
export const ADDRESS_AVAX_MARKET = CONTAINER_ADDRESS_AVAX_MARKET;
