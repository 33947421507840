import React, { ButtonHTMLAttributes } from "react";
import styles from "src/modules/common/general/ui/buttons/ButtonModalFill/ButtonModalFill.module.scss";
import cl from "classnames";

interface IButtonModalFillProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: any;
	className?: string;
}

export const ButtonModalFill: React.FC<IButtonModalFillProps> = ({
	children,
	className,
	...props
}) => {
	return (
		<div>
			<button className={cl(styles.btn, className)} {...props}>
				{children}
			</button>
		</div>
	);
};
