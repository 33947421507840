import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import styles from "src/modules/payment/ui/modals/PurchaseStep2/PurchaseStep2.module.scss";
import cross from "src/modules/payment/assets/img/svg/cross.svg";
import { toast } from "react-toastify";
import { roundingUpPrice } from "src/modules/common/general/utils/common";
import { useIntl } from "react-intl";
import {
	checkPayment,
	getInfoPack,
} from "src/modules/payment/utils/paymentMarket";
import cl from "classnames";
import lineForPayment from "src/modules/payment/assets/img/png/lineForPayment.png";
import {
	blockchains,
	convertChainForBack,
	convertNameBlockchain,
	convertNameCoin,
	ICrypto,
	IPackPrices,
	IProduct,
	products,
	TBlockchainMint,
} from "src/modules/payment/types/payment";
import { fetchGetWallet } from "src/modules/payment/api/payment";
import { SelectMethodOfPayment } from "src/modules/payment/ui/modals/SelectMethodOfPayment/SelectMethodOfPayment";
import { modal } from "src/modules/common/general/ui/Modal";
import { Context } from "src/index";
import { LoadingPayment } from "src/modules/payment/ui/modals/LoadingPayment/LoadingPayment";
import { objectInLocalStorage } from "../../../../common/general/types/common";

const PurchaseMysteryPackModal = lazy(
	() =>
		import(
			/* webpackChunkName: "PurchaseMysteryPackModal" */ "src/modules/payment/ui/modals/PurchaseMysteryPackModal/PurchaseMysteryPackModal"
		)
);

interface IPurchaseStep2 {
	product: IProduct;
	numberOfProducts: number;
	chain: TBlockchainMint;
	convert: IPackPrices[];
	paymentMethods: ICrypto[];
}

export const PurchaseStep2: React.FC<IPurchaseStep2> = ({
	product,
	numberOfProducts,
	chain,
	convert,
	paymentMethods,
}) => {
	const [selectedCryptocurrency, setSelectedCryptocurrency] = useState(
		paymentMethods[1]
	);
	const { storeUser } = useContext(Context);
	const intl = useIntl();
	const [isClickPurchase, setIsClickPurchase] = useState(false);
	useEffect(() => {
		if (isClickPurchase) {
			setTimeout(() => {
				setIsClickPurchase(false);
			}, 10000);
		}
	});

	const onClickPurchase = async () => {
		setIsClickPurchase(true);
		try {
			const res = (
				await fetchGetWallet(
					selectedCryptocurrency.blockchainForBackend,
					product.type,
					selectedCryptocurrency.coinForBackend,
					convertChainForBack.get(chain)!,
					numberOfProducts
				)
			).data;
			console.log("fetchGetWallet", res);
			if (res.success === 1) {
				modal.open(
					<SelectMethodOfPayment
						product={product}
						numberOfProducts={numberOfProducts}
						chain={chain}
						convert={convert}
						coin={selectedCryptocurrency.coin}
						blockchain={selectedCryptocurrency.blockchain}
						amount={res.amount}
						address={res.wallet}
						history_id={res.history_id}
						start={res.start}
						block={false}
						isOpenAgain={true}
					/>,
					{ abilityHide: true, className: "none" }
				);
			} else if (res.success === 0) {
				toast.warning(
					"you already have an active payment with " +
						selectedCryptocurrency.coin +
						" on the " +
						selectedCryptocurrency.blockchain +
						" blockchain"
				);
				const resActivePaymentData = await storeUser.activePaymentData();
				if (resActivePaymentData.check) {
					modal.open(<LoadingPayment />);
					await checkPayment(resActivePaymentData.id, 0, intl);
				} else {
					const resFetchGetWallet = (
						await fetchGetWallet(
							resActivePaymentData.blockchain,
							resActivePaymentData.type,
							resActivePaymentData.coin,
							resActivePaymentData.mintOn,
							resActivePaymentData.amount
						)
					).data;

					modal.open(
						<SelectMethodOfPayment
							amount={resFetchGetWallet.amount}
							// @ts-ignore
							coin={convertNameCoin[resFetchGetWallet.coin]}
							// @ts-ignore
							blockchain={convertNameBlockchain[resFetchGetWallet.blockchain]}
							address={resFetchGetWallet.wallet}
							history_id={resFetchGetWallet.history_id}
							start={resFetchGetWallet.start}
							block={false}
							// @ts-ignore //todo
							chain={resActivePaymentData.mintOn}
							numberOfProducts={resActivePaymentData.count}
							isOpenAgain={false}
							convert={convert}
							product={
								products.find((el) => resActivePaymentData.type === el.type)!
							}
						/>,
						{ abilityHide: true, className: "none" }
					);
				}
			} else {
				toast.error(
					intl.formatMessage({
						id: "error receiving wallet for payment, we are working on fixing the error",
					})
				);
			}
		} catch (er) {
			toast.error(intl.formatMessage({ id: "something went wrong" }));
		}
	};

	return (
		<div className={styles.modal}>
			<div
				className={styles.cross}
				onClick={() => {
					localStorage.removeItem(objectInLocalStorage.paymentInfo);
					modal.close();
				}}
			>
				<img src={cross} alt="" />
			</div>
			<div className={styles.title}>
				{intl.formatMessage({ id: "Checkout" })}
			</div>
			<div className={styles.leftSide}>
				<div className={styles.titleItem}>
					{intl.formatMessage({ id: "Item" })}
				</div>
				<div className={styles.areaForIcon}>
					<img
						src={product.smallIcon}
						alt={""}
						className={styles.iconMysteryPack}
					/>
				</div>
				<div className={styles.namePack}>
					{intl.formatMessage({ id: String(product.name) })}
					<span style={{ fontWeight: 700, marginLeft: "8px" }}>
						x{numberOfProducts}
					</span>
				</div>
				<div className={styles.wTypePack}></div>
				<div className={styles.priceOnePack}>
					${convert?.find((item) => item.type === product.type)?.price}
				</div>
				<div className={styles.packDescription}>
					{intl.formatMessage({
						id: getInfoPack(product.type).descriptionOnNextWindows,
					})}
				</div>
				<div className={styles.subTitleIconPack}>
					<img
						src={blockchains[chain]}
						className={styles.iconBlockchainPack}
						alt={""}
					/>{" "}
					{intl.formatMessage({ id: "Minted on " })}
					{chain} Blockchain
				</div>
				<div className={styles.totalAmount}>
					$
					{
						// eslint-disable-next-line array-callback-return
						convert?.map((item) => {
							if (item.type === product.type) {
								return (
									<>{roundingUpPrice(Number(item.price) * numberOfProducts)}</>
								);
							}
						})
					}{" "}
					{intl.formatMessage({ id: "Total" })}
				</div>
				<div
					className={styles.buttonBack}
					onClick={() => {
						modal.open(
							<Suspense>
								<PurchaseMysteryPackModal
									product={products.findIndex((el) => el.type === product.type)}
								/>
							</Suspense>,
							{
								className: "none",
								abilityHide: true,
							}
						);
					}}
				>
					{intl.formatMessage({ id: "Back" })}
				</div>
			</div>
			<div className={styles.rightSide}>
				<div className={styles.titleRightSide}>
					{intl.formatMessage({ id: "Payment Option" })}
				</div>
				<div className={styles.areaForSelect}>
					{paymentMethods.map((crypto) => {
						return (
							<div
								className={cl(styles.selectCryptocurrency, {
									[styles.active]: crypto.name === selectedCryptocurrency.name,
								})}
								onClick={() => {
									setSelectedCryptocurrency(crypto);
								}}
							>
								<img src={crypto.icon} alt={""} />
								<div>
									<div>{crypto.name}</div>
									<div className={styles.priceInCrypto}>
										{/* eslint-disable-next-line array-callback-return */}
										{convert?.map((convert) => {
											if (
												convert.type == product.type &&
												!crypto.isStablecoin
											) {
												// @ts-ignore //todo
												return (
													<>
														{crypto.coin === "NEO"
															? Math.ceil(
																	// @ts-ignore
																	Number(convert[crypto.coinForBackend])
															  )
															: roundingUpPrice(
																	// @ts-ignore
																	Number(convert[crypto.coinForBackend]) *
																		numberOfProducts,
																	2
															  )}{" "}
														{crypto.coin.toUpperCase()}
													</>
												);
											}
											if (convert.type == product.type && crypto.isStablecoin) {
												return (
													<>
														{crypto.coin === "NEO"
															? Math.ceil(Number(convert.price))
															: roundingUpPrice(
																	Number(convert.price) * numberOfProducts,
																	2
															  )}{" "}
														{crypto.coin.toUpperCase()}
													</>
												);
											}
										})}
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className={styles.wFooter}>
					<div className={styles.addressUser}></div>
					<div className={styles.PriceInCryptoAndButtonPurchase}>
						<div className={styles.PriceInCrypto1}>
							$
							{convert?.map((item) => {
								if (item.type === product.type) {
									return (
										<>
											{roundingUpPrice(Number(item.price) * numberOfProducts)}
										</>
									);
								}
							})}
						</div>
						{/* eslint-disable-next-line array-callback-return */}
						<div className={styles.PriceInCrypto2}>
							{convert?.map((convert) => {
								if (
									convert.type == product.type &&
									!selectedCryptocurrency.isStablecoin
								) {
									// @ts-ignore
									return (
										<>
											{roundingUpPrice(
												selectedCryptocurrency.coin.toUpperCase() === "NEO"
													? Math.ceil(
															Number(
																// @ts-ignore
																convert[selectedCryptocurrency.coinForBackend]
															) * numberOfProducts
													  )
													: Number(
															// @ts-ignore
															convert[selectedCryptocurrency.coinForBackend]
													  ) * numberOfProducts,
												2
											)}{" "}
											{selectedCryptocurrency.coin.toUpperCase()}
										</>
									);
								}
								if (
									convert.type == product.type &&
									selectedCryptocurrency.isStablecoin
								) {
									return (
										<>
											{roundingUpPrice(
												selectedCryptocurrency.coin.toUpperCase() === "NEO"
													? Math.ceil(Number(convert.price) * numberOfProducts)
													: Number(convert.price) * numberOfProducts,
												2
											)}{" "}
											{selectedCryptocurrency.coin.toUpperCase()}
										</>
									);
								}
							})}
						</div>
						<div
							className={cl(styles.buttonPurchase, {
								[styles.disabled]: isClickPurchase,
							})}
							onClick={onClickPurchase}
						>
							{intl.formatMessage({ id: "Purchase" })}
						</div>
					</div>
				</div>
			</div>
			<img src={lineForPayment} alt={""} className={styles.line} />
			<div
				className={styles.buttonBackH}
				onClick={() => {
					modal.open(
						<PurchaseMysteryPackModal
							product={products.findIndex((el) => el.type === product.type)}
						/>,
						{
							className: "none",
							abilityHide: true,
						}
					);
				}}
			>
				{intl.formatMessage({ id: "Back" })}
			</div>
		</div>
	);
};
