import React from "react";

export const SvgLogoInGrad: React.FC = () => {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.21525e-07 2.17218C3.21525e-07 1.59609 0.228855 1.04358 0.636218 0.63622C1.04358 0.228857 1.59608 2.89582e-06 2.17218 2.89582e-06H23.8255C24.111 -0.000463392 24.3937 0.0553863 24.6576 0.164354C24.9215 0.273323 25.1613 0.433269 25.3633 0.635037C25.5653 0.836804 25.7255 1.07643 25.8347 1.3402C25.944 1.60396 26.0002 1.88669 26 2.17218V23.8255C26.0003 24.111 25.9443 24.3938 25.8352 24.6577C25.7261 24.9216 25.5661 25.1614 25.3642 25.3634C25.1623 25.5653 24.9226 25.7255 24.6588 25.8348C24.395 25.944 24.1122 26.0002 23.8266 26H2.17218C1.88683 26 1.60427 25.9438 1.34065 25.8345C1.07703 25.7253 0.837521 25.5652 0.6358 25.3634C0.434078 25.1615 0.274101 24.9219 0.165009 24.6583C0.0559157 24.3946 -0.000154931 24.112 3.21525e-07 23.8266V2.17218ZM10.2913 9.91309H13.8119V11.6811C14.3201 10.6647 15.6201 9.75 17.5736 9.75C21.3188 9.75 22.2064 11.7745 22.2064 15.4889V22.3695H18.4163V16.3351C18.4163 14.2196 17.9081 13.026 16.6175 13.026C14.8271 13.026 14.0825 14.313 14.0825 16.3351V22.3695H10.2913V9.91309ZM3.79127 22.2075H7.58255V9.75H3.79127V22.2064V22.2075ZM8.125 5.68691C8.13215 6.01152 8.07439 6.3343 7.9551 6.63628C7.83582 6.93827 7.65742 7.21339 7.43036 7.4455C7.20331 7.6776 6.93219 7.86202 6.6329 7.98792C6.33361 8.11382 6.01219 8.17868 5.6875 8.17868C5.36281 8.17868 5.04139 8.11382 4.7421 7.98792C4.44281 7.86202 4.17169 7.6776 3.94464 7.4455C3.71759 7.21339 3.53918 6.93827 3.4199 6.63628C3.30061 6.3343 3.24285 6.01152 3.25 5.68691C3.26403 5.04974 3.527 4.44339 3.98259 3.99772C4.43818 3.55205 5.05017 3.3025 5.6875 3.3025C6.32483 3.3025 6.93682 3.55205 7.39241 3.99772C7.848 4.44339 8.11097 5.04974 8.125 5.68691Z"
				fill="url(#paint0_linear_768_769)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_768_769"
					x1="13.0828"
					y1="-3.82353"
					x2="13.0827"
					y2="32.1176"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EBB16D" />
					<stop offset="1" stopColor="#E7E4E2" />
				</linearGradient>
			</defs>
		</svg>
	);
};
