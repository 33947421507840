import React from "react";
import styles from "src/modules/mainPage/ui/sections/Web3NextGen/Web3NextGen.module.scss";
import TabletTitle from "src/modules/mainPage/assets/img/png/TabletTitle1.png";
import SectionWeb3 from "src/modules/mainPage/assets/img/png/SectionWeb3.png";
import SectionWeb3Mobile from "src/modules/mainPage/assets/img/png/SectionWeb3Mobile.png";
import Garland from "src/modules/mainPage/assets/img/png/Garland.png";
import { useIntl } from "react-intl";

export const Web3NextGen: React.FC = () => {
	// const navigate = useNavigate();
	const intl = useIntl();
	return (
		<div className={styles.content} id={"PlayToCollect"}>
			<div className={styles.title}>
				<div className={styles.titleSection}>
					{intl.formatMessage({ id: "Web3 NextGen project" })}
				</div>
				<div className={styles.titleSeason}>
					<img loading="lazy" src={TabletTitle} alt="" />
					<div className={styles.titleSeasonText}>
						{intl.formatMessage({ id: "play-to-collect" })}
					</div>
				</div>
			</div>

			<div className={styles.center}>
				<img
					loading="lazy"
					className={styles.mainImg}
					src={SectionWeb3}
					alt=""
				/>
				<img
					loading="lazy"
					className={styles.mainImgMobile}
					src={SectionWeb3Mobile}
					alt=""
				/>
				<div className={styles.textBlock}>
					<div className={styles.centerText1}>
						{intl.formatMessage({ id: "Collect items, characters and pets" })}
					</div>
					<div className={styles.centerText2}>
						{intl.formatMessage({
							id: "Play the games with entrance fee and win the whole prize fund",
						})}
					</div>
					<div className={styles.centerText3}>
						{intl.formatMessage({
							id: "Free-to-Play: No upfront investment is needed to start playing",
						})}
					</div>
					<div className={styles.centerText4}>
						{intl.formatMessage({
							id: "Trade game items, characters and pets to earn",
						})}
					</div>
				</div>
			</div>
			<img loading="lazy" className={styles.Garland} src={Garland} alt="" />
		</div>
	);
};
