import { makeAutoObservable, runInAction } from "mobx";
import { IUser } from "src/modules/common/user/types/user";

import {
	fetchAuthLogin,
	fetchAuthLoginMetaMask,
	fetchAuthLogout,
	fetchAuthRefresh,
	fetchGetNonce,
} from "src/modules/common/general/api/auth";
import {
	fetchGetProfile,
	fetchGetProfileByWallet,
	fetchSetWallet,
	fetchUpdateProfile,
} from "src/modules/common/general/api/profile";

import BigNumber from "bignumber.js";
import Web3 from "web3";
import { getTypeAuthorization } from "src/modules/common/general/utils/localStorage";

import { toast } from "react-toastify";
import { IAuth } from "src/modules/common/general/types/common";
import { fetchGetActivePayment } from "src/modules/payment/api/payment";
import { vaclient } from "src/modules/common/general/utils/crypto/velas/vaclient";
import { getEthereumAccount } from "../../general/utils/crypto/metamask/metamask";
import { digestMessage } from "../../general/utils/hashing";
// import { history } from "../index";

export default class StoreUser {
	isAuth: boolean = false;
	typeAuthorization: IAuth.AuthTypes = IAuth.AuthTypes.default;
	currentUser: IUser = {} as IUser;
	isLoading: boolean = true;
	wallet: any;
	isLoadingProfile: boolean = false;

	walletRegistr: string = "";
	walletTyepRegistr: IAuth.AuthTypes = IAuth.AuthTypes.default;


	language: "en" | "ko" = "en";

	constructor() {
		makeAutoObservable(this);
		this.wallet = localStorage.getItem("wallet");
		this.typeAuthorization = getTypeAuthorization();
		console.log("isAuth", this.isAuth, this.typeAuthorization);
	}

	setAuth = (bool: boolean) => {
		this.isAuth = bool;
		console.log("isAuth", this.isAuth);
	};
	setWallet = (accountId: string) => {
		this.wallet = accountId;
	};
	setTypeAuthorization = (type: IAuth.AuthTypes) => {
		this.typeAuthorization = type;
	};

	setUser = (user: IUser) => {
		this.currentUser = user;
	};

	setLoading = (bool: boolean) => {
		this.isLoading = bool;
	};

	setProfileData = async () => {
		runInAction(() => {
			this.isLoadingProfile = false;
		});
		const res = await fetchGetProfile();
		console.log("fetchGetProfile", res.data);
		this.setUser(res.data);
		console.log("currentUser", this.currentUser);
		runInAction(() => {
			this.isLoadingProfile = true;
		});
	};

	setWalletFetch = async (type: IAuth.AuthTypes, accountId: string) => {
		runInAction(() => {
			this.isLoadingProfile = false;
		});
		const res = await fetchSetWallet(type, accountId);
		console.log("setWalletFetch", res.data);
		this.setUser(res.data);
		console.log("currentUser", this.currentUser);
		runInAction(() => {
			this.isLoadingProfile = true;
		});
	};

	joiningWalletOrLogin = async (type: IAuth.AuthTypes, accountId: string) => {
		const isWallet = (await fetchGetProfileByWallet(accountId, type)).data;
		console.log("isWallet", isWallet);
		if (isWallet !== "") {
			const res = await this.login(type, accountId);
			// if (res) {
			// 	toast.success("Successful login");
			// }
		} else {
			const res = await this.setWalletFetch(type, accountId);
			console.log("setWalletFetch res", res, accountId);
			toast.success("The wallet is connected to the account");
		}
	};

	updateProfileData = async (data: any) => {
		const res = await fetchUpdateProfile(data);
		console.log(res.data);
		this.setProfileData();
	};

	login = async (type: IAuth.AuthTypes, idUser: string, password?: string) => {
		if (password) {
			password = await digestMessage(password);
		}
		try {
			this.setTypeAuthorization(type);
			this.setWallet(idUser);
			console.log(
				"LOGINNNNNNNNN    accountId",
				idUser,
				type,
				password,
				this.walletTyepRegistr,
				this.walletRegistr,
				this.walletTyepRegistr === IAuth.AuthTypes.default
			);
			let serverResponse;
			if (type === IAuth.AuthTypes.velas) {
				serverResponse = await fetchAuthLogin(
					IAuth.AuthTypes.velas,
					idUser.toLocaleLowerCase(),
					password || ""
				);
			} else {
				serverResponse = await fetchAuthLogin(type, idUser, password || "");
			}

			const isNewAccount = !Boolean(
				(await fetchGetProfileByWallet(idUser, IAuth.AuthTypes.velas)).data
					.nickname
			);

			console.log(
				"login serverResponse.data.refreshToken",
				serverResponse.data
			);
			if (serverResponse.data.accessToken) {
				localStorage.setItem("wallet", idUser);
				localStorage.setItem("accessToken", serverResponse.data.accessToken);
				localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
				localStorage.setItem("typeAuthorization", type);
				this.setAuth(true);
				await this.setProfileData();


				return true;
			} else {
				return serverResponse.data;
			}
		} catch (e) {
			console.log(e);
			return false;
		} finally {
			this.setLoading(false);
		}
	};

	logout = async () => {
		console.log("LOGOUT ______________________");
		this.setLoading(true);
		try {
			await fetchAuthLogout();
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("typeAuthorization");
			localStorage.removeItem("sessionVelas");
			localStorage.removeItem("wallet");

			this.setWallet("");
			this.setTypeAuthorization(IAuth.AuthTypes.default);
			this.setAuth(false);
			this.setUser({} as IUser);
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	};

	loginMetaMask = async (
		type: IAuth.AuthTypes,
		idUser: string,
		password?: string,
	) => {
		try {
			let typeAuth;

			typeAuth = type;

			const account = await getEthereumAccount(
				Number(window.ethereum.networkVersion),
			);
			if (!account) {
				return;
			}
			const resNonce = await fetchGetNonce(
				typeAuth,
				idUser.toLocaleLowerCase(),
			);
			const signedData: string = await account.signer.signMessage(
				String(resNonce.data.nonce),
			);
			const serverResponse = await fetchAuthLoginMetaMask(
				typeAuth,
				idUser.toLocaleLowerCase(),
				signedData,
			);

			this.setTypeAuthorization(type);
			this.setWallet(idUser);
			console.log(
				"LOGINNNNNNNNN    accountId",
				idUser,
				type,
				password,
				this.walletTyepRegistr,
				this.walletRegistr,
				this.walletTyepRegistr === IAuth.AuthTypes.default,
			);

			console.log(
				"login serverResponse.data.refreshToken",
				serverResponse.data,
			);
			if (serverResponse.data.accessToken) {
				localStorage.setItem("wallet", idUser);
				localStorage.setItem("accessToken", serverResponse.data.accessToken);
				localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
				localStorage.setItem("typeAuthorization", type);
				this.setAuth(true);
				await this.setProfileData();

				return true;
			} else {
				return serverResponse.data.errorMessage;
			}
		} catch (e) {
			console.log(e);
			return false;
		} finally {
			this.setLoading(false);
		}
	};


	checkAuth = async () => {
		console.log("checkAuth");
		this.setLoading(true);
		try {
			const refreshToken = localStorage.getItem("refreshToken");
			if (!refreshToken) {
				return;
			}
			console.log("checkAuth refreshToken", refreshToken);
			const serverResponse = await fetchAuthRefresh(refreshToken);
			console.log("1");
			localStorage.setItem("accessToken", serverResponse.data.accessToken);
			localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
			localStorage.setItem("wallet", serverResponse.data.user.walletId);
			// localStorage.setItem(
			//   "typeAuthorization",
			//   serverResponse.data.user.authType
			// );
			this.wallet = serverResponse.data.user.walletId;
			console.log("fetchAuthRefresh", serverResponse.data);
			await this.setProfileData();
			this.setAuth(true);
		} catch (error) {
			console.log("error", error);
		} finally {
			console.log("finally");
			this.setLoading(false);
		}
	};
	activePaymentData = async () => {
		runInAction(() => {
			this.isLoadingProfile = false;
		});
		const res = await fetchGetActivePayment();
		console.log("fetchGetActivPayment", res);
		runInAction(() => {
			this.isLoadingProfile = true;
		});
		return res.data;
	};
}
