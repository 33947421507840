import { logger } from "src/modules/common/general/utils/disableLogs";
import { CHAIN_SETTINGS_MAINNET_AND_TESTNET } from "src/modules/common/general/utils/crypto/metamask/networkParams";
import { ENearEnvironment } from "src/modules/common/general/utils/crypto/near/near.environment";

let CONTAINER_SERVER_DOMAIN: any = "";
let CONTAINER_SERVER_SOCKET_DOMAIN: any = "";
let CONTAINER_SERVER_SOCKET_DOMAIN_CONNECTOR: any = "";

let CONTAINER_RPC_VELAS = "";
let CONTAINER_RPC_OASIS = "";
let CONTAINER_RPC_AVAX = "";
let CONTAINER_RPC_SKALE = "";
let CONTAINER_RPC_NEO = "";

let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS = "";
let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR = "";
let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS = "";
let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX = "";
let CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE = "";
let CONTAINER_ADDRESS_BETS = "";
let CONTAINER_ADDRESS_DEPOSIT = "";
let CONTAINER_CHAIN_OASIS_BETS: any = "";
let CONTAINER_CHAIN_OASIS_DEPOSIT: any = "";

let CONTAINER_VACLIENT__CLIENT_ID: any = "";
let CONTAINER_VACLIENT__REDIRECT_URL: any = "";
let CONTAINER_VACLIENT__NETWORK: any = "";
let CONTAINER_VACLIENT__ACCOUNT_PROVIDER_HOST: any = "";

let CONTAINER_NEAR_NETWORK: any = "";
let CONTAINER_SELECTOR_NETWORK: any = "";

const RPC_VELAS_TESTNET = "https://evmexplorer.testnet.velas.com/rpc";
const RPC_OASIS_TESTNET = "https://testnet.sapphire.oasis.dev/";
const RPC_AVAX_TESTNET = "https://ava-testnet.public.blastapi.io/ext/bc/C/rpc";
const RPC_SKALE_TESTNET =
	"https://staging-v3.skalenodes.com/v1/staging-utter-unripe-menkar";
const RPC_NEO_TESTNET = "https://testnet2.neo.coz.io:443";

const RPC_NEO_MAINNET = "https://mainnet2.neo.coz.io:443";
const RPC_VELAS_MAINNET = "https://api.velas.com/rpc";
const RPC_OASIS_MAINNETT = "https://sapphire.oasis.io";
const RPC_AVAX_MAINNET = "https://api.avax.network/ext/bc/C/rpc";
const RPC_SKALE_MAINNET =
	"https://mainnet.skalenodes.com/v1/honorable-steel-rasalhague";

const CHRISTMAS_CHARACTER_CONTRACT_VELAS_TESTNET =
	"0x8A9d29d315a87F57bB8764BC2bD7669b2C55f1f0";
const CHRISTMAS_CHARACTER_CONTRACT_NEAR_TESTNET = "nft1.pixudi2022.testnet";
const CHRISTMAS_CHARACTER_CONTRACT_OASIS_TESTNET =
	"0xD8499A7fb6E3aA2747030554cE340B4257FEe63e";
const CHRISTMAS_CHARACTER_CONTRACT_AVAX_TESTNET =
	"0x0C72629E750b1552c2290D06EACA8a5c3d24f8cB";
const CHRISTMAS_CHARACTER_CONTRACT_SKALE_TESTNET =
	"0x48eA43897FC733744eE75a68492aF031747a8Ce9";

const CHRISTMAS_CHARACTER_CONTRACT_VELAS_MAINNET = "";
const CHRISTMAS_CHARACTER_CONTRACT_NEAR_MAINNET = "";
const CHRISTMAS_CHARACTER_CONTRACT_OASIS_MAINNET = "";
const CHRISTMAS_CHARACTER_CONTRACT_AVAX_MAINNET = "";
const CHRISTMAS_CHARACTER_CONTRACT_SKALE_MAINNET = "";

const ADDRESS_BETS_TESTNET = "0xA6daB48209c0a696C7985EE9EC46200Da54b7703";
const ADDRESS_DEPOSIT_TESTNET = "0x30C1e6Ef7631fc5B87F9d1603EBe131395B0EE46";

const ADDRESS_BETS_MAINNET = "";
const ADDRESS_DEPOSIT_MAINNET = "";

const NEAR_NETWORK_MAINNET = ENearEnvironment.MAIN_NET;
const NEAR_NETWORK_TESTNET = ENearEnvironment.TEST_NET;
const SELECTOR_NETWORK_MAINNET = "mainnet";
const SELECTOR_NETWORK_TESTNET = "testnet";

const LOCAL_SERVER_DOMAIN = "http://localhost:4004";
const DEV_SERVER_DOMAIN = "https://apidev.pixudi.com";
const MAIN_SERVER_DOMAIN = "https://api.pixudi.com";
const DEV2ND_SERVER_DOMAIN = "http://80.89.239.207:4004";

const LOCAL_SERVER_SOCKET_DOMAIN = "http://localhost:8081";
const DEV_SERVER_SOCKET_DOMAIN = "https://socketsdev.pixudi.com";
const MAIN_SERVER_SOCKET_DOMAIN = "https://sockets.pixudi.com";
const DEV2ND_SERVER_SOCKET_DOMAIN = "http://80.89.239.207:8087";

const LOCAL_SERVER_SOCKET_DOMAIN_CONNECTOR = "http://localhost:4099";
const DEV_SERVER_SOCKET_DOMAIN_CONNECTOR = "http://80.89.239.207:4099";
const MAIN_SERVER_SOCKET_DOMAIN_CONNECTOR = "*****";

//VELAS -----------------------------------------------------------------------------------------------------------------------------------
const MARKET_ADDRESS_TESTNET = "0x9361411330e9d1faed60ABA0ce9526C69Df2a892";
const MARKET_ADDRESS_MAINNET = "0x12019b5bE762815DeFf88CD12c3D9c8D900Ed41d";

const LOCAL__VACLIENT__CLIENT_ID =
	"GPkFuKEQwJu6mKfiAwfdW72cqWGnVvnRvz9bhqdpQ9NC";
const SERVER__VACLIENT__CLIENT_ID =
	"HAciiNzLg1Cs6YCxQbHTRcT1pv3iXKbzeWzCBcEZoWJZ";
const PREVIEW__VACLIENT__CLIENT_ID =
	"8w1WiyPiVjZVEPNwFm8JMu2HQ6UMKpzJkogMkp2vPCab";
const PREVIEW__VACLIENT__CLIENT_ID__MAINNET =
	"HsxA5nbZQ7qA4HKwGYadJAtQkKdpzvEY2pMdUVQutiSG";
const BETA__VACLIENT__CLIENT_ID__MAINNET =
	"GNDbvjvF1LVk9r3F5j5jbfi2zav2HCFVGWpPsrmGBPeZ";
const PREVIEW_LOCAL__VACLIENT__CLIENT_ID__MAINNET =
	"3imq1SRBUuCKCPqAe5Qq1ZRKMVepZpCwJFr3bZTABiF7"; // http://localhost:3000/authorization
// For http://80.89.239.207:3000 MAINNET = H1RRH4D7rXrv67G7E7ThEeGErd41vS6bVF1ydVyY7hpF

const LOCAL__VACLIENT_ATTACH_WALLET__CLIENT_ID =
	"EGrYH9QyVeTdHnvA4G8ihoXoH6eAnHo1gs9BCBFXAV6C";
const SERVER__VACLIENT_ATTACH_WALLET__CLIENT_ID =
	"F9THbhGDR3zz1D5w3uyRjhJHrPoHGZSfzg7eYiNEcmJ6";

const LOCAL__VACLIENT_ATTACH_WALLET__REDIRECT_URL =
	"http://localhost:3000/registration/2";
const SERVER__VACLIENT_ATTACH_WALLET__REDIRECT_URL =
	"https://dev.pixudi.com/registration/2";

const LOCAL__VACLIENT__REDIRECT_URL = "http://localhost:3000/authorization";
const SERVER__VACLIENT__REDIRECT_URL = "https://dev.pixudi.com/authorization";
const PREVIEW__VACLIENT__REDIRECT_URL =
	"https://preview.pixudi.com/authorization";
const BETA__VACLIENT__REDIRECT_URL = "https://beta.pixudi.com/authorization";
//For http://80.89.239.207:3000 MAINNET = http://80.89.239.207:3000/authorization

const VACLIENT__NETWORK_MAINNET = "https://api.velas.com/rpc";
const VACLIENT__NETWORK_TESTNET = "https://api.testnet.velas.com";

const VACLIENT__ACCOUNT_PROVIDER_HOST__MAINNET = "account.velas.com";
const VACLIENT__ACCOUNT_PROVIDER_HOST__TESTNET = "account.testnet.velas.com";

if (process.env.REACT_APP_MODE === "local") {
	CONTAINER_SERVER_DOMAIN = LOCAL_SERVER_DOMAIN;
	CONTAINER_SERVER_SOCKET_DOMAIN = LOCAL_SERVER_SOCKET_DOMAIN;

	// PROFILE
	CONTAINER_RPC_VELAS = RPC_VELAS_TESTNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_TESTNET;
	CONTAINER_RPC_AVAX = RPC_AVAX_TESTNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_TESTNET;
	CONTAINER_RPC_NEO = RPC_NEO_TESTNET;

	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS =
		CHRISTMAS_CHARACTER_CONTRACT_VELAS_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR =
		CHRISTMAS_CHARACTER_CONTRACT_NEAR_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS =
		CHRISTMAS_CHARACTER_CONTRACT_OASIS_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX =
		CHRISTMAS_CHARACTER_CONTRACT_AVAX_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE =
		CHRISTMAS_CHARACTER_CONTRACT_SKALE_TESTNET;

	//BETS
	CONTAINER_ADDRESS_BETS = ADDRESS_BETS_TESTNET;
	CONTAINER_CHAIN_OASIS_BETS = CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//DEPOSIT
	CONTAINER_ADDRESS_DEPOSIT = ADDRESS_DEPOSIT_TESTNET;
	CONTAINER_CHAIN_OASIS_DEPOSIT =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//VELAS
	CONTAINER_VACLIENT__CLIENT_ID = LOCAL__VACLIENT__CLIENT_ID;
	CONTAINER_VACLIENT__REDIRECT_URL = LOCAL__VACLIENT__REDIRECT_URL;
	CONTAINER_VACLIENT__NETWORK = VACLIENT__NETWORK_MAINNET;
	CONTAINER_VACLIENT__ACCOUNT_PROVIDER_HOST =
		VACLIENT__ACCOUNT_PROVIDER_HOST__MAINNET;

	//CONNECTOR
	CONTAINER_SERVER_SOCKET_DOMAIN_CONNECTOR =
		LOCAL_SERVER_SOCKET_DOMAIN_CONNECTOR;

	//NEAR
	CONTAINER_NEAR_NETWORK = NEAR_NETWORK_TESTNET;
	CONTAINER_SELECTOR_NETWORK = SELECTOR_NETWORK_TESTNET;

	logger.enableLogger();
}

if (process.env.REACT_APP_MODE === "dev") {
	CONTAINER_SERVER_DOMAIN = DEV2ND_SERVER_DOMAIN;
	CONTAINER_SERVER_SOCKET_DOMAIN = DEV2ND_SERVER_SOCKET_DOMAIN;

	// PROFILE
	CONTAINER_RPC_VELAS = RPC_VELAS_TESTNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_TESTNET;
	CONTAINER_RPC_AVAX = RPC_AVAX_TESTNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_TESTNET;
	CONTAINER_RPC_NEO = RPC_NEO_TESTNET;

	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS =
		CHRISTMAS_CHARACTER_CONTRACT_VELAS_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR =
		CHRISTMAS_CHARACTER_CONTRACT_NEAR_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS =
		CHRISTMAS_CHARACTER_CONTRACT_OASIS_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX =
		CHRISTMAS_CHARACTER_CONTRACT_AVAX_TESTNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE =
		CHRISTMAS_CHARACTER_CONTRACT_SKALE_TESTNET;

	//BETS
	CONTAINER_ADDRESS_BETS = ADDRESS_BETS_TESTNET;
	CONTAINER_CHAIN_OASIS_BETS = CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//DEPOSIT
	CONTAINER_ADDRESS_DEPOSIT = ADDRESS_DEPOSIT_TESTNET;
	CONTAINER_CHAIN_OASIS_DEPOSIT =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//VELAS
	CONTAINER_VACLIENT__CLIENT_ID = SERVER__VACLIENT__CLIENT_ID;
	CONTAINER_VACLIENT__REDIRECT_URL = SERVER__VACLIENT__REDIRECT_URL;
	CONTAINER_VACLIENT__NETWORK = VACLIENT__NETWORK_MAINNET;
	CONTAINER_VACLIENT__ACCOUNT_PROVIDER_HOST =
		VACLIENT__ACCOUNT_PROVIDER_HOST__MAINNET;

	//CONNECTOR
	CONTAINER_SERVER_SOCKET_DOMAIN_CONNECTOR = DEV_SERVER_SOCKET_DOMAIN_CONNECTOR;

	//NEAR
	CONTAINER_NEAR_NETWORK = NEAR_NETWORK_TESTNET;
	CONTAINER_SELECTOR_NETWORK = SELECTOR_NETWORK_TESTNET;

	logger.enableLogger();
}
if (process.env.REACT_APP_MODE === "prod") {
	CONTAINER_SERVER_DOMAIN = MAIN_SERVER_DOMAIN;
	CONTAINER_SERVER_SOCKET_DOMAIN = MAIN_SERVER_SOCKET_DOMAIN;

	// PROFILE
	CONTAINER_RPC_VELAS = RPC_VELAS_MAINNET;
	CONTAINER_RPC_OASIS = RPC_OASIS_MAINNETT;
	CONTAINER_RPC_AVAX = RPC_AVAX_MAINNET;
	CONTAINER_RPC_SKALE = RPC_SKALE_MAINNET;
	CONTAINER_RPC_NEO = RPC_NEO_MAINNET;

	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS =
		CHRISTMAS_CHARACTER_CONTRACT_VELAS_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR =
		CHRISTMAS_CHARACTER_CONTRACT_NEAR_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS =
		CHRISTMAS_CHARACTER_CONTRACT_OASIS_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX =
		CHRISTMAS_CHARACTER_CONTRACT_AVAX_MAINNET;
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE =
		CHRISTMAS_CHARACTER_CONTRACT_SKALE_MAINNET;

	//BETS
	CONTAINER_ADDRESS_BETS = ADDRESS_BETS_MAINNET;
	CONTAINER_CHAIN_OASIS_BETS = CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//DEPOSIT
	CONTAINER_ADDRESS_DEPOSIT = ADDRESS_DEPOSIT_MAINNET;
	CONTAINER_CHAIN_OASIS_DEPOSIT =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET;

	//VELAS
	CONTAINER_VACLIENT__CLIENT_ID = BETA__VACLIENT__CLIENT_ID__MAINNET;
	CONTAINER_VACLIENT__REDIRECT_URL = BETA__VACLIENT__REDIRECT_URL;
	CONTAINER_VACLIENT__NETWORK = VACLIENT__NETWORK_MAINNET;
	CONTAINER_VACLIENT__ACCOUNT_PROVIDER_HOST =
		VACLIENT__ACCOUNT_PROVIDER_HOST__MAINNET;

	//CONNECTOR
	CONTAINER_SERVER_SOCKET_DOMAIN_CONNECTOR =
		MAIN_SERVER_SOCKET_DOMAIN_CONNECTOR;

	//NEAR
	CONTAINER_NEAR_NETWORK = NEAR_NETWORK_MAINNET;
	CONTAINER_SELECTOR_NETWORK = SELECTOR_NETWORK_MAINNET;

	logger.disableLogger();
}

export const VACLIENT__CLIENT_ID = CONTAINER_VACLIENT__CLIENT_ID;
export const VACLIENT__REDIRECT_URL = CONTAINER_VACLIENT__REDIRECT_URL;
export const VACLIENT__NETWORK = CONTAINER_VACLIENT__NETWORK;
export const VACLIENT__ACCOUNT_PROVIDER_HOST =
	CONTAINER_VACLIENT__ACCOUNT_PROVIDER_HOST;
export const VACLIENT_ATTACH_WALLET__CLIENT_ID =
	LOCAL__VACLIENT_ATTACH_WALLET__CLIENT_ID;
export const VACLIENT_ATTACH_WALLET__REDIRECT_URL =
	LOCAL__VACLIENT_ATTACH_WALLET__REDIRECT_URL;
export const MARKET_ADDRESS = MARKET_ADDRESS_MAINNET;
export const MARKET_ADDRESS_CR = "0x81A18FBe2e4626E41c2Bdb0846714d623Da18395";
export const MARKET_ADDRESS_BOOSTER =
	"0xc9EbAABf2f20617910e5A03B2b0546419C884341";

export const SERVER_DOMAIN = CONTAINER_SERVER_DOMAIN;
export const SERVER_SOCKET_DOMAIN = CONTAINER_SERVER_SOCKET_DOMAIN;

export const cellSize = 112;
export const timeCloseModal = 3000;

export const TIME_TO_DISPLAY_RESULT_ROUND_IN_DUEL: number = 10000;

export const TIME_OF_ONE_MOVER_PER_CELL = 1500;
export const MODAL_WINDOW_OPENING_DELAY = 1000;
export const MODAL_WINDOW_OPENING_DELAY_DICE_ROLL = 3000;
export const DELAY_DISAPPEARANCE_OF_DICE = 3000;
export const DELAY_BEFORE_NEXT_MOVE = 5000;

// BETS
export const ADDRESS_CONTRACT_BETS = CONTAINER_ADDRESS_BETS;
export const CHAIN_OASIS_BETS = CONTAINER_CHAIN_OASIS_BETS;

//DEPOSIT
export const DEPOSIT_CONTRACT = CONTAINER_ADDRESS_DEPOSIT;
export const CHAIN_OASIS_DEPOSIT = CONTAINER_CHAIN_OASIS_DEPOSIT;

// PROFILE
export const RPC_VELAS = CONTAINER_RPC_VELAS;
export const RPC_OASIS = CONTAINER_RPC_OASIS;
export const RPC_AVAX = CONTAINER_RPC_AVAX;
export const RPC_SKALE = CONTAINER_RPC_SKALE;
export const RPC_NEO = CONTAINER_RPC_NEO;
export const CHRISTMAS_CHARACTER_CONTRACT_VELAS =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_VELAS;
export const CHRISTMAS_CHARACTER_CONTRACT_NEAR =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_NEAR;
export const CHRISTMAS_CHARACTER_CONTRACT_OASIS =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_OASIS;
export const CHRISTMAS_CHARACTER_CONTRACT_AVAX =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_AVAX;
export const CHRISTMAS_CHARACTER_CONTRACT_SKALE =
	CONTAINER_CHRISTMAS_CHARACTER_CONTRACT_SKALE;

export const RPC_CHARACTERS_VELAS = "https://evmexplorer.testnet.velas.com/rpc";
export const RPC_CHARACTERS_AVAX =
	"https://ava-testnet.public.blastapi.io/ext/bc/C/rpc";
export const RPC_CHARACTERS_OASIS = "https://testnet.sapphire.oasis.dev/";
export const RPC_CHARACTERS_SKALE =
	"https://staging-v3.skalenodes.com/v1/staging-utter-unripe-menkar";
export const CONTRACT_CHARACTERS_VELAS =
	"0x8A9d29d315a87F57bB8764BC2bD7669b2C55f1f0";
export const CONTRACT_CHARACTERS_NEAR = "nft1.pixudi2022.testnet";
export const CONTRACT_CHARACTERS_AVAX =
	"0x0C72629E750b1552c2290D06EACA8a5c3d24f8cB";
export const CONTRACT_CHARACTERS_OASIS =
	"0xD8499A7fb6E3aA2747030554cE340B4257FEe63e";
export const CONTRACT_CHARACTERS_SKALE =
	"0x48eA43897FC733744eE75a68492aF031747a8Ce9";

//CONNECTOR
export const SERVER_SOCKET_DOMAIN_CONNECTOR =
	CONTAINER_SERVER_SOCKET_DOMAIN_CONNECTOR;

//NEAR
export const NEAR_NETWORK = CONTAINER_NEAR_NETWORK;
export const SELECTOR_NETWORK = CONTAINER_SELECTOR_NETWORK;

// Game create options
export const NUMBER_OF_TURNS = [5, 7, 10, 15, 20, 25];
export const TIME_PER_ROLL = 2;
export const TIME_FOR_TURN = 120;

//# For the main network
export const VACLIENT__SPONSOR_BACKEND_ADDRESS = "https://sponsor.pixudi.com";
export const VACLIENT__SPONSOR_BACKEND_PUBKEY =
	"HyuUXBZz8QnD9TrLG1YkM8eydrcWcDocDTr1D9krBHwF";

let CONTAINER_ETH_NETWORK_ID = 0;
let CONTAINER_BNB_NETWORK_ID = 0;
let CONTAINER_OASIS_NETWORK_ID = 0;
let CONTAINER_SKALE_NETWORK_ID = 0;
let CONTAINER_VELAS_NETWORK_ID = 0;
let CONTAINER_AVAX_NETWORK_ID = 0;

if (process.env.REACT_APP_MODE_FOR_PAYMENT === "local") {
	CONTAINER_ETH_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_TESTNET.id;
	CONTAINER_BNB_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_TESTNET.id;
	CONTAINER_OASIS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET.id;
	CONTAINER_SKALE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_TESTNET.id;
	CONTAINER_VELAS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_TESTNET.id;
	CONTAINER_AVAX_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_TESTNET.id;
}

if (process.env.REACT_APP_MODE_FOR_PAYMENT === "dev") {
	CONTAINER_ETH_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_TESTNET.id;
	CONTAINER_BNB_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_TESTNET.id;
	CONTAINER_OASIS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET.id;
	CONTAINER_SKALE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET.id;
	CONTAINER_VELAS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_TESTNET.id;
	CONTAINER_AVAX_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_TESTNET.id;
}

if (process.env.REACT_APP_MODE_FOR_PAYMENT === "prod") {
	CONTAINER_ETH_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET.id;
	CONTAINER_BNB_NETWORK_ID = CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET.id;
	CONTAINER_OASIS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET.id;
	CONTAINER_SKALE_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET.id;
	CONTAINER_VELAS_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET.id;
	CONTAINER_AVAX_NETWORK_ID =
		CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET.id;
}

export const METAMASK_NETWORK_IDS = {
	ETH: CONTAINER_ETH_NETWORK_ID,
	BNB: CONTAINER_BNB_NETWORK_ID,
	OASIS: CONTAINER_OASIS_NETWORK_ID,
	SKALE: CONTAINER_SKALE_NETWORK_ID,
	VELAS: CONTAINER_VELAS_NETWORK_ID,
	AVAX: CONTAINER_AVAX_NETWORK_ID,
};

// SETTINGS CHAIN FOR METAMASK AUTHORIZATION AND REGISTRATION
const CHAIN_SETTINGS_METAMASK_PROD = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_MAINNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_MAINNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET,
};
const CHAIN_SETTINGS_METAMASK_LOCAL = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_MAINNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_MAINNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET,
};
const CHAIN_SETTINGS_METAMASK_DEV = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_TESTNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_TESTNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_TESTNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_TESTNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_TESTNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_TESTNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_TESTNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_TESTNET,
};

const CHAIN_SETTINGS_METAMASK_BETA = {
	VELAS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.VELAS_MAINNET,
	ETH: CHAIN_SETTINGS_MAINNET_AND_TESTNET.ETH_MAINNET,
	BNB: CHAIN_SETTINGS_MAINNET_AND_TESTNET.BNB_MAINNET,
	AVAX: CHAIN_SETTINGS_MAINNET_AND_TESTNET.AVAX_MAINNET,
	GLMR: CHAIN_SETTINGS_MAINNET_AND_TESTNET.GLMR_MAINNET,
	OASIS: CHAIN_SETTINGS_MAINNET_AND_TESTNET.OASIS_MAINNET,
	DOGE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.DOGE_MAINNET,
	SKALE: CHAIN_SETTINGS_MAINNET_AND_TESTNET.SKALE_MAINNET,
};

export const CHAIN_SETTINGS_METAMASK_AUTH =
	process.env.REACT_APP_MODE === "prod"
		? CHAIN_SETTINGS_METAMASK_PROD
		: process.env.REACT_APP_MODE === "dev"
		? CHAIN_SETTINGS_METAMASK_DEV
		: process.env.REACT_APP_MODE === "beta"
		? CHAIN_SETTINGS_METAMASK_BETA
		: CHAIN_SETTINGS_METAMASK_LOCAL;
