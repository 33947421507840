import { toast } from "react-toastify";
import { SuccessfulPaymentNotification } from "../ui/modals/SuccessfulPaymentNotification/SuccessfulPaymentNotification";
import React from "react";
import { LoadingPayment } from "../ui/modals/LoadingPayment/LoadingPayment";
import { convertChainForFront, products, TProduct } from "../types/payment";
import { fetchCheckPayment, fetchGetHistoryById } from "../api/payment";
import { modal } from "src/modules/common/general/ui/Modal";
import { IntlShape } from "react-intl";
import { objectInLocalStorage } from "../../common/general/types/common";

export const checkPayment = async (
	history_id: number,
	now: number,
	intl: IntlShape,
	timeIsUp?: boolean
): Promise<any> => {
	localStorage.removeItem(objectInLocalStorage.paymentInfo);
	console.log("check payment");

	// TODO: If 5 minutes have passed, the request is superfluous
	if (!timeIsUp) {
		await fetchCheckPayment(history_id, now);
	}

	toast.dismiss();
	modal.open(<LoadingPayment />, { abilityHide: true });
	toast.loading(
		intl.formatMessage({ id: "Processing transactions in the blockchain..." })
	);
	let timerId = setInterval(async () => {
		const res: any = (await fetchGetHistoryById(history_id)).data;
		if (res.result) {
			toast.dismiss();
			toast.success(intl.formatMessage({ id: "Your operation is done!" }));
			clearInterval(timerId);
			clearTimeout(timerTimeout);
			modal.open(
				<SuccessfulPaymentNotification
					typePack={res.type}
					numberOfProducts={res.count}
					chainMint={convertChainForFront.get(res.mintOn)!}
				/>,
				{ className: "none", abilityHide: true }
			);
		}
		if (Number(res.inUseEnd) > 0 && !res.result) {
			modal.close();
			toast.dismiss();
			toast.error(intl.formatMessage({ id: "Payment failed, try again" }));
			toast.error(
				intl.formatMessage({
					id: "if you transferred but the payment did not go through, please contact us",
				})
			);
			clearInterval(timerId);
			clearTimeout(timerTimeout);
		}
	}, 15000);

	const timerTimeout = setTimeout(() => {
		toast.dismiss();
		toast.error(intl.formatMessage({ id: "Something went wrong" }));
		modal.close();
		clearInterval(timerId);
	}, 300000);
};

export const getInfoPack = (pack: TProduct) => {
	return products.find((item: { type: any }) => item.type === pack)!;
};
