import React, { useContext } from "react";
import styles from "src/modules/common/general/ui/DropdownMenuLanguages/DropdownMenuLanguages.module.scss";
import { Context } from "src/index";

interface IlevelUpModal {
	stateModal: boolean;
}

export const DropdownMenuLanguages: React.FC<IlevelUpModal> = ({
	stateModal,
}) => {
	const { languages } = useContext(Context);
	const language: string[][] = [
		["English", "en"],
		["한국", "ko"],
		["日本語", "ja"],
		["Français", "fr"],
		["Español", "es"],
		["Indonesia", "id"],
		["Italiano", "it"],
		["tiếng Việt", "vi"],
		["中文", "ch"],
		["Deutsch", "de"],
	];
	if (stateModal) {
		return (
			<div className={styles.Menu}>
				<div className={styles.WrapNameLanguage}>
					{language.map((lang) => {
						return (
							<div
								className={styles.nameLanguage}
								onClick={() => {
									languages.changeLanguage(lang[1]);
								}}
							>
								{lang[0]}
							</div>
						);
					})}
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};
