import { makeAutoObservable } from "mobx";

export default class Language {
	language: string = "en";

	constructor() {
		makeAutoObservable(this);
	}

	changeLanguage(languages: string) {
		this.language = languages;
		console.log(this.language);
	}
}
