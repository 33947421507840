import React from "react";

export const SvgLogoTelegramGrad: React.FC = () => {
	return (
		<svg
			width="29"
			height="24"
			viewBox="0 0 29 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.77792 10.4521L19.0522 3.32846C20.7574 2.58642 26.5403 0.21188 26.5403 0.21188C26.5403 0.21188 29.2092 -0.82698 28.9868 1.69597C28.9127 2.73483 28.3196 6.37084 27.7265 10.3037L25.873 21.9537C25.873 21.9537 25.7247 23.6604 24.4644 23.9573C23.204 24.2541 21.1281 22.9184 20.7574 22.6216C20.4609 22.399 15.197 19.0598 13.2694 17.4273C12.7504 16.982 12.1573 16.0916 13.3436 15.0527C16.0126 12.604 19.2005 9.56162 21.1281 7.63231C22.0178 6.74186 22.9075 4.66414 19.2005 7.18708L8.74696 14.2365C8.74696 14.2365 7.56074 14.9785 5.33657 14.3107C3.11241 13.6429 0.517558 12.7524 0.517558 12.7524C0.517558 12.7524 -1.26177 11.6393 1.77792 10.4521Z"
				fill="url(#paint0_linear_768_767)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_768_767"
					x1="14.5924"
					y1="-3.52941"
					x2="14.5922"
					y2="29.6471"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EBB16D" />
					<stop offset="1" stopColor="#E7E4E2" />
				</linearGradient>
			</defs>
		</svg>
	);
};
