import React from "react";
import styles from "src/modules/payment/ui/modals/LoadingPayment/LoadingPayment.module.scss";
import { useIntl } from "react-intl";

export const LoadingPayment: React.FC<any> = () => {
	const intl = useIntl();
	return (
		<div className={styles.modal}>
			<div className={styles.title}>
				{intl.formatMessage({ id: "Almost there..." })}
			</div>
			<div className={styles.loader}></div>
			<div className={styles.subTitle}>
				{intl.formatMessage({
					id: "Please do not close the page. Your payment is being processed.",
				})}
			</div>
		</div>
	);
};
