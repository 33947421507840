import ko from "./ko.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import id from "./id.json";
import it from "./it.json";
import ja from "./ja.json";
import vi from "./vi.json";
import de from "./de.json";
import ch from "./zh-CN.json";

export default {
	ko,
	en,
	es,
	fr,
	id,
	it,
	ja,
	vi,
	de,
	ch,
} as Record<string, any>;
