import React, { useLayoutEffect } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

const ForNearSelector: React.FC<{ params: any }> = ({ params }) => {
	let i: any;

	useLayoutEffect(() => {
		if (!i) {
			const res = queryString.parse(params);
			if (res.account_id && res.all_keys && !res.par1) {
				window.location.href =
					`${window.location.protocol}//${window.location.host}` +
					params +
					"&par1=12";
			}
			i = 2;
		}
	}, []);

	return <></>;
};

export default ForNearSelector;
