import React, { lazy, useState } from "react";
import styles from "src/modules/mainPage/ui/sections/Parchments/Parchments.module.scss";
import parchment1 from "src/modules/mainPage/assets/img/png/Parchment1.png";
import parchment2 from "src/modules/mainPage/assets/img/png/Parchment2.png";
import parchment3 from "src/modules/mainPage/assets/img/png/Parchment3.png";
import parchment4 from "src/modules/mainPage/assets/img/png/Parchment4.png";

import tablet1 from "src/modules/mainPage/assets/img/png/Tablet1.png";
import tablet2 from "src/modules/mainPage/assets/img/png/Tablet2.png";
import tablet3 from "src/modules/mainPage/assets/img/png/Tablet3.png";
import TabletMobile from "src/modules/mainPage/assets/img/png/TabletMobile.png";

import Board from "src/modules/mainPage/assets/img/png/Board.png";
import Characters from "src/modules/mainPage/assets/img/png/Characters.png";
import GameItems from "src/modules/mainPage/assets/img/png/GameItems.png";
import NFTPets from "src/modules/mainPage/assets/img/png/NFTPets.png";

import PieceMap from "src/modules/mainPage/assets/img/sections/parchments/png/PieceMap.png";
import Dragon from "src/modules/mainPage/assets/img/sections/parchments/png/DragonBig.png";
import Items from "src/modules/mainPage/assets/img/sections/parchments/png/Items.png";
import Avatars from "src/modules/mainPage/assets/img/sections/parchments/png/Avatars.png";

import flare from "src/modules/mainPage/assets/img/flares/png/Flare1.png";

import cl from "classnames";
import { ButtonJoin } from "src/modules/common/general/ui/buttons/ButtonJoin/ButtonJoin";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useIntl } from "react-intl";

const WrapForButtonBuy = lazy(
	() =>
		import(
			/* webpackChunkName: "WrapForButtonBuy" */ "src/modules/payment/ui/WrapForButtonBuy/WrapForButtonBuy"
		)
);

export const Parchments: React.FC = () => {
	const intl = useIntl();

	const case1 = (
		<div>
			<div className={styles.selectiveContent}>
				<div className={styles.selectiveTitle}>
					{intl.formatMessage({
						id: "with unique landscapes, mechanics and adventures",
					})}
				</div>
				<div className={styles.text}>
					{intl.formatMessage({
						id: "Explore the map, find hidden treasures, fall into traps and meet all kinds of mysterious creatures! How many boards can you add in your collection?",
					})}
				</div>
				<div className={styles.text}>
					{intl.formatMessage({
						id: "Everything is not what it seems. Don’t trust anyone and be careful with the unknown.",
					})}
				</div>
			</div>
			<div className={styles.btn}>
				<WrapForButtonBuy product={2}>
					<ButtonJoin>
						{intl.formatMessage({ id: "Buy Packs" })}
					</ButtonJoin>
				</WrapForButtonBuy>
			</div>
			<div className={cl(styles.imgCase, styles.pieceMap)}>
				<LazyLoadComponent>
					<img loading="lazy" src={PieceMap} alt="" />
				</LazyLoadComponent>
			</div>
		</div>
	);
	const case2 = (
		<div>
			<div className={styles.selectiveContent}>
				<div className={styles.selectiveTitle}>
					{intl.formatMessage({
						id: "which will help you upgrade your character and win the battles",
					})}
				</div>
				<div className={styles.inProgress}>
					{intl.formatMessage({ id: "In Progress" })}
				</div>
				<div className={styles.text}>
					{intl.formatMessage({
						id: "Raise your pet and grow it into a beautiful powerful creature. Improve their stats, keep them healthy and happy, buy supplies and breed them to produce new species! Pets can also be traded on the marketplace.",
					})}{" "}
				</div>
			</div>
			<div className={styles.btn}>
				<WrapForButtonBuy product={2}>
					<ButtonJoin>
						{intl.formatMessage({ id: "Buy Packs" })}
					</ButtonJoin>
				</WrapForButtonBuy>
			</div>
			<div className={cl(styles.imgCase, styles.dragon)}>
				<LazyLoadComponent>
					<img loading="lazy" src={Dragon} alt="" />
				</LazyLoadComponent>
			</div>
		</div>
	);
	const case3 = (
		<>
			<div className={styles.selectiveContent}>
				<div className={styles.selectiveTitle} style={{ maxWidth: 650 }}>
					{intl.formatMessage({
						id: "with various effects. Different items are obtained in different ways",
					})}
				</div>
				<div className={styles.text}>
					{intl.formatMessage({
						id: "The item list is constantly expanding. You can find all kinds of boosts in Mystery Packs, during the game, on the market, in mini games and many other ways. But be careful: magic items can hold ancient curses. Some of them will give you a boost while others will hold you back and benefit your opponents.",
					})}
				</div>
				{/* <div className={styles.text}>
    Everything is not what it seems. Don’t trust anyone and be careful with the unknown.
  </div> */}
			</div>
			<div className={styles.btn}>
				<WrapForButtonBuy product={2}>
					<ButtonJoin>
						{intl.formatMessage({ id: "Buy Packs" })}
					</ButtonJoin>
				</WrapForButtonBuy>
			</div>
			<div className={cl(styles.imgCase, styles.items)}>
				<LazyLoadComponent>
					<img loading="lazy" src={Items} alt="" />
				</LazyLoadComponent>
			</div>
		</>
	);
	const case4 = (
		<>
			<div className={styles.selectiveContent}>
				<div className={styles.selectiveTitle}>
					{intl.formatMessage({
						id: "with AI-generated looks and stats. Each one is unique",
					})}
				</div>
				<div className={styles.text}>
					{intl.formatMessage({
						id: "When you open your Mystery Pack, you can find a character inside. You can use them as a game character and profile avatar.",
					})}
				</div>
				<div className={styles.text}>
					{intl.formatMessage({
						id: "They are generated with unique stats which you can later improve with experience points, items and pets.",
					})}
				</div>
			</div>
			<div className={styles.btn}>
				<WrapForButtonBuy product={2}>
					<ButtonJoin>
						{intl.formatMessage({ id: "Buy Packs" })}
					</ButtonJoin>
				</WrapForButtonBuy>
			</div>
			<div className={cl(styles.imgCase, styles.avatars)}>
				<LazyLoadComponent>
					<img loading="lazy" src={Avatars} alt="" />
				</LazyLoadComponent>
			</div>
		</>
	);

	const [tab, setTab] = useState<
		"Boards" | "Characters" | "Game Items" | "NFT Pets" | "None"
	>("None");
	const styleWidthProgressBar = () => {
		switch (tab) {
			case "Boards":
				return "25%";
			case "Characters":
				return "50%";
			case "Game Items":
				return "75%";
			case "NFT Pets":
				return "100%";
			default:
				return "";
		}
	};

	// const navigate = useNavigate();
	return (
		<div className={styles.content} id="Parchments">
			<img className={styles.flare} src={flare} alt="" />
			<div className={styles.title}>
				<div className={styles.titleSection}>
					{intl.formatMessage({ id: "More than just a board game" })}
				</div>
			</div>
			<div className={styles.parchmentsWrapper}>
				<div className={styles.parchments}>
					<div className={styles.parchment}>
						<img
							loading="lazy"
							className={styles.parchmentImg}
							src={parchment1}
							alt=""
							width={528}
							height={506}
						/>
						<div className={styles.parchmentContent}>
							<div className={styles.parchmentTitle}>
								{intl.formatMessage({ id: "Boards" })}
							</div>
							<div className={cl(styles.parchmentImage, styles.Boards)}>
								<img loading="lazy" src={Board} alt="" />
							</div>
						</div>
					</div>
					<div className={styles.parchment}>
						<img
							loading="lazy"
							className={styles.parchmentImg}
							src={parchment2}
							alt=""
							width={528}
							height={506}
						/>
						<div className={styles.parchmentContent}>
							<div className={styles.parchmentTitle}>
								{intl.formatMessage({ id: "Characters" })}
							</div>
							<div className={cl(styles.parchmentImage, styles.Characters)}>
								<img loading="lazy" src={Characters} alt="" />
							</div>
						</div>
					</div>
					<div className={styles.parchment}>
						<img
							loading="lazy"
							className={styles.parchmentImg}
							src={parchment3}
							alt=""
							width={528}
							height={506}
						/>
						<div className={styles.parchmentContent}>
							<div className={styles.parchmentTitle}>
								{intl.formatMessage({ id: "Game Items" })}
							</div>
							<div className={cl(styles.parchmentImage, styles.GameItems)}>
								<img loading="lazy" src={GameItems} alt="" />
							</div>
						</div>
					</div>
					<div className={styles.parchment}>
						<img
							loading="lazy"
							className={styles.parchmentImg}
							src={parchment4}
							alt=""
							width={528}
							height={506}
						/>
						<div className={styles.parchmentContent}>
							<div className={cl(styles.parchmentTitle, styles.NFTPetsTitle)}>
								{intl.formatMessage({ id: "NFT Pets" })}
							</div>
							<div className={cl(styles.parchmentImage, styles.NFTPets)}>
								<img loading="lazy" src={NFTPets} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.tablets}>
				<div
					className={styles.tablet}
					onClick={() => {
						setTab("Boards");
					}}
				>
					<img loading="lazy" src={tablet1} alt="" />
					<div className={styles.title}>
						{intl.formatMessage({ id: "Boards" })}
					</div>
				</div>
				<div
					className={styles.tablet}
					onClick={() => {
						setTab("Characters");
					}}
				>
					<img loading="lazy" src={tablet2} alt="" />
					<div className={styles.title}>
						{intl.formatMessage({ id: "Characters" })}
					</div>
				</div>
				<div
					className={styles.tablet}
					onClick={() => {
						setTab("Game Items");
					}}
				>
					<img loading="lazy" src={tablet3} alt="" />
					<div className={styles.title}>
						{intl.formatMessage({ id: "Game Items" })}
					</div>
				</div>
				<div
					className={styles.tablet}
					onClick={() => {
						setTab("NFT Pets");
					}}
				>
					<img loading="lazy" src={tablet3} alt="" />
					<div className={styles.title}>
						{intl.formatMessage({ id: "NFT Pets" })}
					</div>
				</div>
				<div className={styles.progressBar}>
					<div
						className={cl(styles.progressBarActive, {
							[styles.progressAuto]: tab === "None",
						})}
						style={{ width: styleWidthProgressBar() }}
					></div>
				</div>
			</div>
			<div className={styles.cases}>
				<div
					className={cl(
						styles.case1,
						{ [styles.active]: tab === "Boards" },
						{ [styles.auto]: tab === "None" }
					)}
				>
					{case1}
				</div>
				<div
					className={cl(
						styles.case2,
						{ [styles.active]: tab === "Characters" },
						{ [styles.auto]: tab === "None" }
					)}
				>
					{case4}
				</div>
				<div
					className={cl(
						styles.case3,
						{ [styles.active]: tab === "Game Items" },
						{ [styles.auto]: tab === "None" }
					)}
				>
					{case3}
				</div>
				<div
					className={cl(
						styles.case4,
						{ [styles.active]: tab === "NFT Pets" },
						{ [styles.auto]: tab === "None" }
					)}
				>
					{case2}
				</div>
			</div>

			<div className={styles.casesMobile}>
				<div className={styles.tabletMobile}>
					<img loading="lazy" src={TabletMobile} alt="" />
					<div className={styles.title}>
						{intl.formatMessage({ id: "Boards" })}
					</div>
				</div>
				<div className={cl(styles.case1Mobile)}>{case1}</div>
				<div className={styles.tabletMobile}>
					<img loading="lazy" src={TabletMobile} alt="" />
					<div className={styles.title}>
						{intl.formatMessage({ id: "Characters" })}
					</div>
				</div>
				<div className={cl(styles.case2Mobile)}>{case4}</div>
				<div className={styles.tabletMobile}>
					<img loading="lazy" src={TabletMobile} alt="" />
					<div className={styles.title}>
						{intl.formatMessage({ id: "Game Items" })}
					</div>
				</div>
				<div className={cl(styles.case3Mobile)}>{case3}</div>
				<div className={styles.tabletMobile}>
					<img loading="lazy" src={TabletMobile} alt="" />
					<div className={styles.title}>
						{intl.formatMessage({ id: "NFT Pets" })}
					</div>
				</div>
				<div className={cl(styles.case4Mobile)}>{case2}</div>
			</div>
		</div>
	);
};
