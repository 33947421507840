import React from "react";
import styles from "src/modules/common/general/ui/SocialNetwork/SocialNetwork.module.scss";
import { SvgLogoDiscord } from "src/modules/common/general/assets/img/common/svgComponents/SvgLogoDiscord";
import { SvgLogoIn } from "src/modules/common/general/assets/img/common/svgComponents/SvgLogoIn";
import { SvgLogoTelegram } from "src/modules/common/general/assets/img/common/svgComponents/SvgLogoTelegram";
import { SvgLogoTwitter } from "src/modules/common/general/assets/img/common/svgComponents/SvgLogoTwitter";
import { SvgLogoMail } from "src/modules/common/general/assets/img/common/svgComponents/SvgLogoMail";

export const SocialNetwork: React.FC = () => {
	return (
		<div className={styles.icons}>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("mailto:welcome@pixudi.com?subject=Pixudi", "_blank");
				}}
			>
				<SvgLogoMail />
			</div>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("https://t.me/pixudi", "_blank");
				}}
			>
				{<SvgLogoTelegram />}
			</div>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("https://twitter.com/thepixudi", "_blank");
				}}
			>
				{<SvgLogoTwitter />}
			</div>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("https://discord.gg/W4QGHh9FRX", "_blank");
				}}
			>
				{<SvgLogoDiscord />}
			</div>
			<div
				className={styles.icon}
				onClick={() => {
					window.open("https://www.linkedin.com/company/pixudi/", "_blank");
				}}
			>
				{<SvgLogoIn />}
			</div>
		</div>
	);
};
