import styles from "./Partners.module.scss";
import near from "src/modules/mainPage/assets/img/svg/partners/near.png";
import oasis from "src/modules/mainPage/assets/img/svg/partners/oasis.svg";
import neo from "src/modules/mainPage/assets/img/svg/partners/neo.svg";
import skale from "src/modules/mainPage/assets/img/svg/partners/skale.svg";
import velas from "src/modules/mainPage/assets/img/svg/partners/velas.svg";
import avalanche from "src/modules/mainPage/assets/img/svg/partners/avalanche.svg";
import { useIntl } from "react-intl";

export const Partners: React.FC = () => {
	const intl = useIntl();

	return (
		<div className={styles.content}>
			<div className={styles.titleBlock}>
				<div>{intl.formatMessage({ id: "Partners" })}</div>
			</div>
			<div className={styles.partners}>
				<div className={styles.row}>
					<img src={near} alt="" />
					<img src={oasis} alt="" />
					<img src={neo} alt="" />
				</div>
				<div className={styles.row}>
					<img src={skale} alt="" />
					<img src={avalanche} alt="" />
					<img src={velas} alt="" />
				</div>
			</div>
		</div>
	);
};
