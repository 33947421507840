import React, { useEffect, useState } from "react";
import {
	connect,
	ConnectConfig,
	keyStores,
	Near,
	WalletConnection,
} from "near-api-js";

import { ENearEnvironment } from "src/modules/common/general/utils/crypto/near/near.environment";
import { getConfig } from "src/modules/common/general/utils/crypto/near/near.config";

interface NearContextType {
	near?: Near;
	wallet?: WalletConnection;
}

export const NearContext = React.createContext<NearContextType>({});

type NearProviderProps = {
	environment?: ENearEnvironment;
	children?: React.ReactNode;
};

/**
 * `NearProvider` sets up `Near` and a `WalletConnection`. These values are accessible
 * using the provided hooks: `useNear` and `useNearWallet`.
 *
 * @example
 * ```js
 * <NearProvider environment={ENearEnvironment.MainNet}>
 *  <App />
 * </NearProvider>
 * ```
 */
export const NearProvider: React.FC<NearProviderProps> = ({
	environment = ENearEnvironment.MAIN_NET,
	children,
	...props
}) => {
	const config: ConnectConfig = {
		...getConfig(environment),
		deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() },
		...props,
	};

	const [near, setNear] = useState<Near>();
	const [wallet, setWallet] = useState<WalletConnection>();

	useEffect(() => {
		async function setup(): Promise<void> {
			const near = await connect(config);
			const wallet = new WalletConnection(near, "near");

			setNear(near);
			setWallet(wallet);
		}

		setup().catch((err) => {
			console.error(err);
		});
	}, []);

	return (
		<NearContext.Provider value={{ near, wallet }}>
			{near !== undefined && wallet !== undefined ? children : null}
		</NearContext.Provider>
	);
};
