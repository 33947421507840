import React from "react";
import styles from "src/modules/common/general/ui/PreFooter/PreFooter.module.scss";
import station from "src/modules/mainPage/assets/img/png/Station.png";
import sparks from "src/modules/mainPage/assets/img/png/Sparks.png";
import cl from "classnames";

export const PreFooter: React.FC = () => {
	// const navigate = useNavigate();
	return (
		<div className={styles.preFooter}>
			<img loading="lazy" src={sparks} className={styles.sparks} alt="" />
			<div className={cl(styles.preFooterContainer, styles.container)}>
				<img loading="lazy" src={station} alt="" />
			</div>
		</div>
	);
};
