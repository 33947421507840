import React from "react";
import styles from "./WhitelistBody.module.scss";
import { useIntl } from "react-intl";
import point from "../../../assets/img/png/point.png";

const WhitelistBody = () => {
	const intl = useIntl();

	return (
		<div className={styles.packDescription}>
			<div className={styles.wrapList}>
				<div className={styles.li}>
					<img alt={""} src={point} />
					<div className={styles.text}>
						{intl.formatMessage({
							id: "This Whitelist Card is a unique generative NFT. You never know what yours looks like until you mint it!",
						})}
					</div>
				</div>
				<div className={styles.li}>
					<img alt={""} src={point} />
					<div className={styles.text}>
						{intl.formatMessage({
							id: "Can be exchanged for any Mystery Pack and 2 days before official drop!",
						})}
					</div>
				</div>
				<div className={styles.li}>
					<img alt={""} src={point} />
					<div className={styles.text}>
						{intl.formatMessage({
							id: "50% discount - with this card just for $6.39 you can exchange it for Common Mystery Pack or buy Rare and Legendary packs for $24.95 and $49.95 instead of $49.99 and $99.99",
						})}
					</div>
				</div>
				<div className={styles.li}>
					<img alt={""} src={point} />
					<div className={styles.text}>
						{intl.formatMessage({
							id: "You can keep it in your collection instead of exchanging and sell later on the marketplace",
						})}
					</div>
				</div>
				<div className={styles.li}>
					<img alt={""} src={point} />
					<div className={styles.text}>
						{intl.formatMessage({
							id: "When you unpack your Mystery Pack, you will find inside 1 game board and 2 random items, such as generative characters, pets, power ups, etc.",
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhitelistBody;
