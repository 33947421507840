import React, { lazy, Suspense, useContext, useEffect } from "react";
import styles from "src/modules/payment/ui/modals/ConfirmWalletForMint/ConfirmWalletForMint.module.scss";
import ChooseAuthorizationMethod from "src/modules/payment/ui/modals/ChooseAuthorizationMethod/ChooseAuthorizationMethod";
import cross from "src/modules/payment/assets/img/svg/cross.svg";
import { useIntl } from "react-intl";
import { Context } from "src/index";
import walletIMG from "src/modules/payment/assets/img/png/clarity_wallet-line.png";
import { PurchaseStep2 } from "src/modules/payment/ui/modals/PurchaseStep2/PurchaseStep2";
import {
	allPaymentMethods,
	blockchains,
	IPackPrices,
	IProduct,
	products,
	TBlockchainMint,
} from "src/modules/payment/types/payment";
import { getInfoPack } from "src/modules/payment/utils/paymentMarket";
import { roundingUpPrice } from "src/modules/common/general/utils/common";
import { modal } from "src/modules/common/general/ui/Modal";
import lineForPayment from "src/modules/payment/assets/img/png/lineForPayment.png";
import { ButtonModalFill } from "src/modules/common/general/ui/buttons/ButtonModalFill/ButtonModalFill";
import { ButtonModalFillPassive } from "src/modules/common/general/ui/buttons/ButtonModalFillPassive/ButtonModalFillPassive";
import { objectInLocalStorage } from "../../../../common/general/types/common";

const PurchaseMysteryPackModal = lazy(
	() =>
		import(
			/* webpackChunkName: "PurchaseMysteryPackModal" */ "src/modules/payment/ui/modals/PurchaseMysteryPackModal/PurchaseMysteryPackModal"
		)
);

interface IPurchaseStep2 {
	product: IProduct;
	numberOfProducts: number;
	chain: TBlockchainMint;
	convert: IPackPrices[];
}

export const ConfirmWalletForMint: React.FC<IPurchaseStep2> = ({
	product,
	numberOfProducts,
	chain,
	convert,
}) => {
	const { storeUser, selector } = useContext(Context);
	const intl = useIntl();
	useEffect(() => {
		localStorage.removeItem(objectInLocalStorage.addWalletSelector);
	}, []);
	console.log("ConfirmWalletForMint",	product,
		numberOfProducts,
		chain,
		convert,)

	// @ts-ignore
	return (
		<div className={styles.modal}>
			<div
				className={styles.cross}
				onClick={() => {
					modal.close();
				}}
			>
				<img src={cross} alt={""} />
			</div>
			<div className={styles.title}>
				{intl.formatMessage({ id: "Checkout" })}
			</div>
			<div className={styles.leftSide}>
				<div className={styles.titleItem}>
					{intl.formatMessage({ id: "Item" })}
				</div>
				<div className={styles.areaForIcon}>
					<img
						src={getInfoPack(product.type).smallIcon}
						alt={""}
						className={styles.iconMysteryPack}
					/>
				</div>
				<div className={styles.namePack}>
					{intl.formatMessage({ id: String(product.name) })}
					<span style={{ fontWeight: 700, marginLeft: "8px" }}>
						x{numberOfProducts}
					</span>
				</div>
				<div className={styles.wTypePack}></div>
				<div className={styles.priceOnePack}>
					$
					{
						// eslint-disable-next-line array-callback-return
						convert?.map((item) => {
							if (item.type === product.type) {
								return <>{item.price}</>;
							}
						})
					}
				</div>
				<div className={styles.packDescription}>
					{intl.formatMessage({
						id: getInfoPack(product.type).descriptionOnNextWindows,
					})}
				</div>
				<div className={styles.subTitleIconPack}>
					<img
						src={blockchains[chain]}
						className={styles.iconBlockchainPack}
						alt=""
					/>{" "}
					{intl.formatMessage({ id: "Minted on " })}
					{chain} Blockchain
				</div>
				<div className={styles.totalAmount}>
					$
					{
						// eslint-disable-next-line array-callback-return
						convert?.map((item) => {
							if (item.type === product.type) {
								return (
									<>
										{roundingUpPrice(Number(item.price) * numberOfProducts, 2)}
									</>
								);
							}
						})
					}{" "}
					{intl.formatMessage({ id: "Total" })}
				</div>
				<div
					className={styles.buttonBack}
					onClick={() => {
						modal.open(
							<Suspense>
								<PurchaseMysteryPackModal
									product={products.findIndex((el) => el.type === product.type)}
								/>
							</Suspense>,
							{
								abilityHide: true,
								className: "none",
							}
						);
					}}
				>
					{intl.formatMessage({ id: "Back" })}
				</div>
			</div>
			<div className={styles.rightSide}>
				<div className={styles.titleRightSide}>
					{intl.formatMessage({
						id: "Do you agree to mint NFT to this address?",
					})}
				</div>
				<div className={styles.wAddress}>
					<img src={walletIMG} alt={""} />
					<div className={styles.address}>
						{
							//@ts-ignore
							storeUser.currentUser[
								chain.toLowerCase() === "avalanche"
									? "avax"
									: chain.toLowerCase()
							]
						}
					</div>
				</div>
				<div className={styles.wFooter}>
					<div className={styles.addressUser}></div>
					<div className={styles.PriceInCryptoAndButtonPurchase}>
						<ButtonModalFillPassive
							className={styles.colorBg}
							onClick={async () => {
								try {
									const wallet = await (await selector)?.wallet();
									wallet?.signOut();
								} catch (er) {}

								await storeUser.logout();

								modal.open(
									<ChooseAuthorizationMethod
										product={product}
										numberOfProducts={numberOfProducts}
										chain={chain}
										convert={convert}
									/>,
									{ abilityHide: true, className: "none" }
								);
							}}
						>
							{intl.formatMessage({ id: "Change Address" })}
						</ButtonModalFillPassive>
						<img src={lineForPayment} alt={""} className={styles.line} />
						<ButtonModalFill
							onClick={() => {
								if (allPaymentMethods.get(chain)) {
									modal.open(
										<PurchaseStep2
											product={product}
											numberOfProducts={numberOfProducts}
											chain={chain}
											convert={convert}
											paymentMethods={allPaymentMethods.get(chain)!}
										/>,
										{ abilityHide: true, className: "none" }
									);
								} else {
									console.error("chain not found");
								}
							}}
						>
							{intl.formatMessage({ id: "Yes" })}
						</ButtonModalFill>
					</div>
				</div>
			</div>
		</div>
	);
};
