import React from "react";
import styles from "src/modules/mainPage/ui/sections/BlockchainIsATool/BlockchainIsATool.module.scss";
import Parchment from "src/modules/mainPage/assets/img/png/ParchmentV2.png";
import Cube from "src/modules/mainPage/assets/img/png/Cube.png";
import Eye from "src/modules/mainPage/assets/img/png/Eye.png";
import Book from "src/modules/mainPage/assets/img/png/MagicBook.png";
import cl from "classnames";
import { useIntl } from "react-intl";

export const BlockchainIsATool: React.FC = () => {
	// const navigate = useNavigate();
	const intl = useIntl();
	return (
		<div className={styles.content}>
			<div className={styles.title}>
				<div className={styles.titleSection}>
					{intl.formatMessage({ id: "blockchain is a tool, not a goal" })}
				</div>
			</div>

			<div className={styles.center}>
				<div className={styles.parchment}>
					<div className={styles.parchmentTitle}>
						{intl.formatMessage({ id: "Blockchain-based dice roll" })}
					</div>
					<div className={styles.parchmentText}>
						{intl.formatMessage({
							id: "Dice roll in Pixudi is implemented on-chain to ensure that it's randomized and can't be modified by anyone",
						})}
					</div>
					<img
						loading="lazy"
						className={styles.parchmentImgBg}
						src={Parchment}
						alt=""
					/>
					<img
						loading="lazy"
						style={{ marginLeft: "20px" }}
						className={cl(styles.parchmentImg, styles.parchmentImgCube)}
						src={Cube}
						alt=""
					/>
				</div>
				<div className={cl(styles.parchment, styles.parchmentCenter)}>
					<div className={styles.parchmentTitle}>
						{intl.formatMessage({ id: "Custom-made NFT standard" })}
					</div>
					<div className={styles.parchmentText}>
						{intl.formatMessage({
							id: "All game items are minted using our extension to  blockchain standard for improved efficiency",
						})}
					</div>
					<img
						loading="lazy"
						className={styles.parchmentImgBg}
						src={Parchment}
						alt=""
					/>
					<img
						loading="lazy"
						style={{ marginLeft: "-20px", marginBottom: "-10px" }}
						className={cl(styles.parchmentImg, styles.parchmentImgEye)}
						src={Eye}
						alt=""
					/>
				</div>
				<div className={styles.parchment}>
					<div className={styles.parchmentTitle}>
						{intl.formatMessage({ id: "Smooth WEB2 players adaptation" })}
					</div>
					<div className={styles.parchmentText}>
						{intl.formatMessage({
							id: "No upfront investment is needed to play. WEB2 users will be gradually introduced to WEB3 advantages",
						})}
					</div>
					<img
						loading="lazy"
						className={styles.parchmentImgBg}
						src={Parchment}
						alt=""
					/>
					<img
						loading="lazy"
						style={{ marginBottom: "-10px" }}
						className={cl(styles.parchmentImg, styles.parchmentImgBook)}
						src={Book}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
};
