import { Wallet, WalletSelector } from "@near-wallet-selector/core";
import { toast } from "react-toastify";
import { authSelector } from "./authSelector";

export const checkAuthInSelector = async (
	walletSelector: Wallet,
	selector: Promise<WalletSelector>
) => {
	if (!(await selector).isSignedIn()) {
		console.log("isSignedIn false");
		toast.warning("please connect " + walletSelector.id);
		authSelector(selector);
		localStorage.setItem("authSelectorInEndStep", "true");

		return null;
	}

	if ((await walletSelector.getAccounts()).length === 0) {
		toast.warning("please connect " + walletSelector.id);
		authSelector(selector);
		localStorage.setItem("authSelectorInEndStep", "true");

		return null;
	}
	return 1;
};
