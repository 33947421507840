import { IAuth } from "../../../common/general/types/common";
import { toast } from "react-toastify";
import StoreUser from "../../../common/user/store/StoreUser";


export const login = async (
	type: IAuth.AuthTypes,
	idUser: string,
	storeUser: StoreUser
) => {
		if (
			type === IAuth.AuthTypes.velas ||
			type === IAuth.AuthTypes.moonbeam ||
			type === IAuth.AuthTypes.oasis ||
			type === IAuth.AuthTypes.avax ||
			(type === IAuth.AuthTypes.doge && !idUser.includes("cosmos")) ||
			type === IAuth.AuthTypes.skale
		) {
			console.log("address", idUser);
			const res = await storeUser.loginMetaMask(type, idUser);

			switch (type) {
				case IAuth.AuthTypes.velas:
					localStorage.setItem("walletVelas", idUser);
					break;
				case IAuth.AuthTypes.avax:
					localStorage.setItem("walletAvax", idUser);
					break;
				case IAuth.AuthTypes.moonbeam:
					localStorage.setItem("walletGlmr", idUser);
					break;
				case IAuth.AuthTypes.oasis:
					localStorage.setItem("walletOasis", idUser);
					break;
				case IAuth.AuthTypes.doge:
					localStorage.setItem("walletDoge", idUser);
					break;
				case IAuth.AuthTypes.skale:
					localStorage.setItem("walletSkale", idUser);
					break;
			}

			console.log("res", res);
			if (res === true) {
			} else {
				toast.error("Something went wrong");
			}
		} else {
			console.log("address", idUser);
			const res = await storeUser.login(type, idUser);
			console.log("res", res);
			if (res === true) {
			} else {
				toast.error("Something went wrong");
			}
		}

};
