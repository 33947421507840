import { AxiosResponse } from "axios";
import {
	IGetActivePayment,
	IHistoryResult,
	IResponseGetWallet,
	TBlockchainMintForBackend,
	TBlockchainPayment,
	TCoinPayment,
	TProduct,
} from "../types/payment";
import agent from "../../../app/api/axios";

export const fetchGetWallet = (
	blockchainCoin: TBlockchainPayment,
	type: TProduct,
	coin: TCoinPayment,
	blockchainForMint: TBlockchainMintForBackend,
	count: number
): Promise<AxiosResponse<IResponseGetWallet>> => {
	return agent.get(
		`/payment/get_wallet?blockchain=${blockchainCoin}&type=${type}&coin=${coin}&mintOn=${blockchainForMint}&count=${count}`
	);
};

export const fetchCheckPayment = (
	history_id: number,
	now: number
): Promise<AxiosResponse<boolean>> => {
	return agent.post(`/payment/check_payment`, {
		history_id: history_id,
		now: now,
	});
};

export const fetchGetHistoryById = (
	history_id: number
): Promise<AxiosResponse<IHistoryResult>> => {
	return agent.get(`/payment/get_history_by_id?history_id=${history_id}`);
};

export const fetchGetActivePayment = (): Promise<
	AxiosResponse<IGetActivePayment>
> => {
	return agent.get("payment/get_history?active=1");
};

export const fetchGetPrices = async (): Promise<AxiosResponse<any>> => {
	return agent.get(`/payment/get_prices`);
};
