export interface IOption {
	icon: any;
	title: string;
	value: string;
	right: any;
	info: string;
	displayBlockchain: string;
}

interface userLeaderboard {
	avatar: string;
	nickname: string;
	rating?: string;
	monthly?: string;
	today?: string;
	weekly?: string;
}

export interface leaderboard {
	allTime: userLeaderboard[];
	monthly: userLeaderboard[];
	today: userLeaderboard[];
	weekly: userLeaderboard[];
}

export namespace IAuth {
	// export interface IAuthInfo {
	//   wallet: string;
	//   type: typeof typeAuth;
	// }
	export enum AuthTypes {
		near = "near",
		avax = "avax",
		ever = "ever",
		skale = "skale",
		velas = "velas",
		eth = "eth",
		email = "email",
		nickname = "nickname",
		google = "google",
		default = "",
		solana = "solana",
		moonbeam = "moonbeam",
		oasis = "oasis",
		ronin = "ronin",
		discord = "discord",
		doge = "doge",
		twitch = "twitch",
		tezos = "tezos",
		secret = "secret",
		neo = "neo",
	}

	export enum SelectorTypes {}

	export interface Tokens {
		accessToken: string;
		refreshToken: string;
		errorMessage: string;
	}

	// /auth/login
	export interface Login {
		accountId: string;
	}

	export type LoginResponseSuccess = Tokens;
}

export interface IBlockchain {
	id: number;
	blockchain: string;
	active: boolean;
}

export interface INews {
	id: number;
	title: string;
	text: string;
	image: string;
	createdAt: Date;
	display: boolean;
}

export interface ICollection {
	image: string;
	id: number;
	blockchain: string;
	title: string;
	address: string;
	type: string;
	createdAt: Date;
}

export interface IPack {
	title: string;
	collection: string;
	collectionId: number;
	amount: number;
	type: string;
	frequency: string;
	descripton: string;
	image: string;
	isOpen: boolean;
	price?: string;
	onSale?: boolean;
	nftId: number;
	nftIds: number[];
	blockchain: string;
	cards?: string[];
	collectionAddress: string;
	tokenId?: string;
	category?: string;
}

export interface IWhitelist {
	collectionId: string;
	type: string;
	image: string;
	isExchanged: boolean;
	nftId: number;
	tokenId?: string;
	blockchain: string;
	collectionAddress: string;
	description?: string;
	collection?: string;
	frequency?: string;
}

export const objectInLocalStorage = {
	paymentInfo: "paymentInfo",
	walletVelas: "walletVelas",
	appPassword: "appPassword",
	accessToken: "accessToken",
	typeAuthorization: "typeAuthorization",
	modalAuthType: "modalAuthType",
	addOrAuthNear: "addOrAuthNear",
	history_id: "history_id",
	refreshToken: "refreshToken",
	language: "language",
	wallet: "wallet",
	sessionVelas: "sessionVelas",
	walletAvax: "walletAvax",
	walletGlmr: "walletGlmr",
	walletOasis: "walletOasis",
	walletDoge: "walletDoge",
	walletSkale: "walletSkale",
	zoom: "zoom",
	authSelectorInEndStep: "authSelectorInEndStep",
	addWalletSelector: "addWalletSelector",
};
